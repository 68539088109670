import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { connect } from 'react-redux';

import { get, sumBy } from 'lodash'

import VehicleExpenseList from '../components/VehicleExpenseList'
import VehicleTripList from '../components/VehicleTripList'
import VehicleRefuelList from '../components/VehicleRefuelList'
import Loader from '../../others/components/Loader'

import { BackIcon } from '../../others/SvgIcons'
import { GET_VEHICLE_DETAILS, GET_VEHICLE_TRIPS } from '../data/vehicle.graphql'

import { getJsonData } from '../../others/utils'
import Urls from '../../UrlConfig'


export default ({ match, history }) => {

	/**
	 * 
	 * show vehicle details page
	 * 	- basic details
	 * 
	 * Parent:
	 * 		VehicleRoute
	 * 
	 * Renders:
	 * 		VehicleDetailsSection
	 */

	const { vehicleId } = match.params

	const { loading: loadingDetails, data: details, error: detailsError } = useQuery(
		GET_VEHICLE_DETAILS, { variables: { pk: Number(vehicleId) }, fetchPolicy: "cache-and-network" }
	)

	const { loading: loadingVehicleTrips, data: vehicleTrips, error: tripsError } = useQuery(
		GET_VEHICLE_TRIPS, { variables: { vehicleId: Number(vehicleId) }, fetchPolicy: "cache-and-network" }
	)

	const loading = loadingDetails || loadingVehicleTrips
	const error = detailsError || tripsError

	return (
		<div className='page-container'>
			<div className='page-title'>
				<BackIcon onClick={history.goBack} />
				<div className="heading">Vehicle Details</div>
			</div>

			<div className='page-content flex-wrapper'>
				<div className='flex-md secondary-bg scrollable relative'>

					{loading ?
						<div className="loading-overlay">
							<Loader />
						</div>
						:
						error ?
							<div className="loading-overlay">
								<h2>Failed to fetch data</h2>
							</div>
							:
							<VehicleDetailsSectionConnect details={details.vehicle_details} vehicleTrips={vehicleTrips} />
					}
				</div>
			</div>

		</div>
	)
}


class VehicleDetailsSection extends Component {

	/**
	 * 
	 * get refuel data in vehicle details as props
	 * fetch trip, expense api logic handle here
	 * active tab logic
	 * 
	 * Parent :
	 * 		VehicleDetailsPage
	 * 
	 * Renders :
	 * 		VehicleExpenseList
	 * 		VehicleTripList
	 * 		VehicleRefuelList
	 * 
	 */

	state = {
		activeTab: 1,
		vehicleExpenseData: {
			loading: true,
			error: false,
			error_msg: {},
			data: [],
			total_count: 0
		},
	}

	DEFAULT_LIST_COUNT = 5
	_mounted = false

	componentDidMount = () => {
		this._mounted = true
		this.fetchVehicleExpenseData()
	}

	componentWillUnmount = () => this._mounted = false

	handleTabSelect = (tab) => {
		if (this.state.activeTab === tab) return
		this.setState({ activeTab: tab })
	}

	fetchVehicleExpenseData = () => {
		const vehicleId = this.props.details.pk

		getJsonData(Urls.apiVehicleExpenses(vehicleId),
			(res) => {
				// console.log('res', res)
				if (this._mounted) {
					const total_count = sumBy(res, 'cost')

					this.setState({
						vehicleExpenseData: {
							loading: false, error: false,
							data: res, error_msg: {}, total_count
						}
					})
				}
			},
			(err) => {
				// console.log('err', err)
				if (this._mounted) {
					this.setState({
						vehicleExpenseData: {
							loading: false, error: true,
							data: [], error_msg: err.error_data,
							total_count: 0
						}
					})
				}
			}
		)
	}

	render = () => {
		const { activeTab, vehicleExpenseData } = this.state

		const { details, vehicleTrips, isSuperuser } = this.props
		// get refuel list from prop
		const refuelsList = get(details, 'refuels.edges', [])
		// count cost from list
		const totalRefuelCost = sumBy(refuelsList, 'node.cost')

		return (
			<div className="page-details-wrapper">
				<div className="basic-section flex-wrapper">
					<div className="basic-details-section flex-sm">
						<div className="table-wrapper">
							<div className="table-col image-col">

								<div className="image-wrapper z-depth-1">
									<img src={details.image} alt="" />
								</div>

							</div>
							<div className="table-col">
								<div className="content">
									<div className="heading text-white">{details.name}</div>
									<div className="title">Reg. No : {details.registration_no}</div>
									<div className="mid-text">Manufacturing Year : {details.manufacturing_year}</div>
									<div className="mid-text">Model No : {details.model_no}</div>
									<div className="mid-text">Driver : {get(details, 'driver.name', '-NA-')}</div>
								</div>
							</div>

						</div>
					</div>

					{isSuperuser &&
						<div className="page-action-section">
							<Link to={{
								pathname: Urls.getVehicleEditPage(details.pk),
								state: { ...details }
							}}
								className="action-block orange-bg-color mr-bottom z-depth-1">
								<div className="action-text">
									<i className="fa fa-pencil" aria-hidden="true"></i> Edit
								</div>
							</Link>
							<Link to={Urls.getExpenseAddPage('vehicle', details.pk)}
								className="action-block yellow-bg-color z-depth-1">
								<div className="action-text"> + Expense</div>
							</Link>
						</div>
					}
				</div>
				<div className="details-section">

					<div className="details-cards-wrapper">

						<div className={`details-card ${activeTab === 1 ? 'active' : ''}`}
							onClick={this.handleTabSelect.bind(this, 1)}>
							<div className="normal-text">This Month Expense</div>
							<div className="value">
								{vehicleExpenseData.loading ? '--' : `₹ ${vehicleExpenseData.total_count.toFixed(2)}`}
							</div>
							<div className="icon-wrapper">
								<i className="fa fa-calendar" aria-hidden="true"></i>
							</div>
						</div>

						<div className={`details-card ${activeTab === 2 ? 'active' : ''}`}
							onClick={this.handleTabSelect.bind(this, 2)}>
							<div className="normal-text">Upcoming Trips</div>
							<div className="value">
								{vehicleTrips.upcoming_vehicle_trips.length}
							</div>
							<div className="icon-wrapper">
								<i className="fa fa-calendar" aria-hidden="true"></i>
							</div>
						</div>

						<div className={`details-card ${activeTab === 3 ? 'active' : ''}`}
							onClick={this.handleTabSelect.bind(this, 3)}>
							<div className="normal-text">Last 5 Refuel</div>
							<div className="value">{totalRefuelCost.toFixed(2)}</div>
							<div className="icon-wrapper">
								<i className="fa fa-calendar" aria-hidden="true"></i>
							</div>
						</div>

					</div>

					<div className='list-section'>
						{this.renderActiveTab(refuelsList)}
					</div>

				</div>
			</div>
		)
	}

	renderActiveTab = (refuelsList) => {
		const { activeTab, vehicleExpenseData } = this.state
		const { vehicleTrips } = this.props

		switch (activeTab) {
			case 1: {
				return <VehicleExpenseList count={this.DEFAULT_LIST_COUNT} {...vehicleExpenseData} />
			}

			case 2: {
				return <VehicleTripList trips={vehicleTrips.upcoming_vehicle_trips} />
			}

			case 3: {
				return <VehicleRefuelList count={this.DEFAULT_LIST_COUNT} refuels={refuelsList} />
			}

			default: {
				return <VehicleExpenseList count={this.DEFAULT_LIST_COUNT} {...vehicleExpenseData} />
			}

		}
	}
}

const VehicleDetailsSectionConnect = connect(store => ({
	isSuperuser: store.auth.is_superuser
}))(VehicleDetailsSection)