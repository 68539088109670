import gql from 'graphql-tag';


export const GET_VEHICLE_LIST = gql`
query vehicles(
	$after: String, $before: String, $first: Int, $last: Int,
	$registrationNo: String, $modelNo: String, $name: String
) {
	vehicles(
		after: $after, before : $before, first: $first, last : $last,
		registration_no__icontains: $registrationNo, 
		model_no__icontains: $modelNo,
		name__icontains: $name
	) {
		pageInfo {hasNextPage hasPreviousPage startCursor endCursor}
		edges {
			cursor
			node {
				pk name
				image model_no
				registration_no
				manufacturing_year
				driver {
					name pk
				}
			}
		} 
	}
}
`

export const GET_VEHICLE_DETAILS = gql`
query vehicle_details($pk: Int) {
	vehicle_details (pk: $pk) {
		pk name
		image model_no
		registration_no
		manufacturing_year

		driver {
			name pk
		}
		
		refuels(first: 5){
			edges {
				node {
					id cost
					km_reading
					refuel_quantity
					timestamp
				}
			}
		}
	}
}
`

export const GET_VEHICLE_TRIPS = gql`
query upcoming_vehicle_trips($vehicleId: Int) {
	upcoming_vehicle_trips(vehicle_id: $vehicleId) {
		pk status
		from_destination to_destination
		start_time end_time
		driver { pk name }
		vehicle { pk name registration_no }
	}
}
`