import React, { Component } from 'react'

import { get } from 'lodash'

import ListLoader from '../../others/components/ListLoader';
import { formatDateTime } from '../../others/utils';


export default class VehicleRefuelList extends Component {

	/**
	 * Parent:
	 * 		VehicleDetailsPage
	 */

	render = () => {

		const { count, loading, refuels, error } = this.props

		let table;

		if (loading) {
			table = <ListLoader row={count} col={4} />
		}
		else if (error) {
			table = <div className="list-empty-wrapper error">Failed to fetch data!!</div>
		}
		else {
			if (get(refuels, 'length', 0)) {
				table = refuels.map((edges) => {
					const { node } = edges
					return (
						<div className='list-row' key={node.id}>
							<div className='list-col'>
								{formatDateTime(new Date(node.timestamp))}
							</div>
							<div className='list-col'>
								{node.km_reading}
							</div>
							<div className='list-col'>
								{node.refuel_quantity}
							</div>
							<div className='list-col'>
								{node.cost}
							</div>
						</div>
					)
				})
			}
			else {
				table = <div className="list-empty-wrapper">No Refuels Found</div>
			}
		}

		return (
			<div className='list-container fix-table z-depth-1'>
				<div className='list-row header-col'>
					<div className='list-col'>Time</div>
					<div className='list-col'>km reading</div>
					<div className='list-col'>Refuel Quantity</div>
					<div className='list-col'>Cost</div>
				</div>

				{table}
			</div>
		)
	}
}