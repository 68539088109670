import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { get } from 'lodash'
import { startOfToday } from 'date-fns';

import TripList from '../components/TripList';
import TripSearch from '../components/TripSearch';
import Pagination from '../../others/components/Pagination';
import { TimeSelector } from '../../others/components/TimeSelector'

import { GET_TRIP_LIST } from '../data/trip.graphql'
import useListWithPaginationSearch from '../../others/data/search.hook';
import Urls from "../../UrlConfig"



export default () => {

	/**
	 * 
	 * data component - list fetch 
	 * 
	 * Parent:
	 * 		TripRoute
	 * 
	 * Renders:
	 * 		TripList
	 * 		TripSearch
	 * 		Pagination
	 */

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	const { loading, data, error,
		hasNextPage, hasPrevPage, fetchNextData, fetchPrevData,
		pageNo, rowCount, changeRowCount,
		handleSearch
	} = useListWithPaginationSearch(GET_TRIP_LIST, "trips")

	const edges = get(data, 'trips.edges', [])

	const paginationProps = {
		pageNo,
		hasPrevPage, hasNextPage,
		fetchNextData, fetchPrevData,
		currentCount: rowCount,
		changeCount: changeRowCount,
	}

	return (
		<div id='trip-list' className='page-container'>

			<div className="page-title-wrapper">
				<div className='page-title'>Trips</div>

				<div className='search-wrapper'>
					<TripListTimeSelector handleSearch={handleSearch} />
					<div className="trip-search-container">
						<TripSearch handleSearch={handleSearch} />
						{isSuperuser &&
							<div className='btn-container'>
								<Link to={Urls.getTripAddPage()} className='yellow-btn'>
									<i className="fa fa-calendar-plus-o" aria-hidden="true"></i>Manage Schedules
								</Link>
							</div>
						}
					</div>
				</div>
			</div>

			<div className='page-content flex-wrapper'>
				<div className='flex-md secondary-bg scrollable relative'>
					<div className='data-sections'>
						<div className='search-result-section'>
							Showing {edges.length} results
						</div>
						<div className='list-section'>

							<TripList data={edges} count={rowCount}
								loading={loading} error={error} />

							<Pagination {...paginationProps} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export class TripListTimeSelector extends Component {

	/**
	 * render time selector
	 * refetch graphql trip data using selected date
	 * 
	 * Parent:
	 * 		TripListWrapperPage
	 */

	state = {
		currentTimeRange: 1,
		startTime: startOfToday()
	}

	handleChangeTime = (props) => {
		let newState = {
			currentTimeRange: props.currentTimeRange
		}
		// in case of custom also set date
		if (props.currentTimeRange === 3) {
			newState.startTime = props.startTime
		}
		this.setState(newState)
		// refetch gql query with updated dates
		this.props.handleSearch({
			before: undefined, after: undefined,
			last: undefined, to_destination__icontains: undefined,
			from_destination__icontains: undefined,
			startTime: props.startTime.toISOString(),
			endTime: props.endTime.toISOString()
		})
	}

	render = () => {
		const { currentTimeRange, startTime } = this.state

		return (
			<TimeSelector currentTimeRange={currentTimeRange}
				startTime={startTime} changeTimeSelection={this.handleChangeTime}
			/>
		)
	}
}
