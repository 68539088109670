import React from 'react'
import { Switch, Route } from 'react-router-dom'

import VehicleListWrapperPage from "./pages/VehicleListWrapperPage"
import VehicleAddPage from "./pages/VehicleAddPage"
import VehicleDetailsPage from "./pages/VehicleDetailsPage"
import RefuelAddPage from "./pages/RefuelAddPage"
import ExpensePage from "../expense/pages/ExpensePage"

import PageNotFound from "../others/pages/PageNotFound"

import Urls from "../UrlConfig"

export default () => {

	/**
	 * Parent:
	 * 		App
	 */
	
	return (
		<Switch>
			<Route exact path={Urls.getVehiclePage()} component={VehicleListWrapperPage} />
			<Route path={Urls.getVehicleAddPage()} component={VehicleAddPage} />
			<Route path={Urls.getVehicleEditPage()} component={VehicleAddPage} />
			<Route path={Urls.getVehicleDetails()} component={VehicleDetailsPage} />
			<Route path={Urls.getExpenseAddPage()} component={ExpensePage} />
			<Route path={Urls.getRefuelAddPage()} component={RefuelAddPage} />
			<Route path="*" component={PageNotFound} />
		</Switch>
	)
}
