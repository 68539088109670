import React from 'react'

import { select } from "d3-selection";
import { axisBottom, axisLeft } from 'd3-axis';

import PropTypes from 'prop-types';

export default class Axes extends React.Component {

    /**
     * Parent:
     *      AreaChart
     *      BarChart
     *      ScatterPlot
     */

    static defaultProps = {
        xGrid : false,
        yGrid : true,
        xTickCount : undefined,
        yTickCount : undefined,
        drawX : true,
        drawY : true,
        xLabel : 'Time',
    }

    componentDidMount = () => {
        this.drawAxes(this.props)
    }

    componentWillUpdate= nextProps => {
        this.drawAxes(nextProps)
    }

    drawAxes = ({drawX, drawY, xScale, yScale, xTickCount, yTickCount}) => {
        if(drawX) {
            // setup scales
            let xAxes = axisBottom(xScale)
            // update tick count if required
            if (xTickCount) xAxes.ticks(xTickCount);
            // draw X axis and update attribute to rotate text
            select(this.refs.xAxes).call(xAxes)
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-65)");
        }

        if(drawY) {
            let yAxes = axisLeft(yScale)
            if (yTickCount) yAxes.ticks(yTickCount);
            select(this.refs.yAxes).call(yAxes)
        }
    }

    drawXGrid = () => {
        const { xScale, height, xGrid, xTickCount } = this.props
        if(xGrid) {
            const xTicks = xScale.ticks(xTickCount)

            return (
                <g className="grid">
                    {xTicks.map( tick => {
                        return <line key={tick} y2={height} transform={`translate(${xScale(tick)}, 0)`} stroke={'lightgray'} />
                    })}
                </g>
            )
        }
    }

    drawYGrid = () => {
        const { yScale, width, yGrid, yTickCount } = this.props

        if(yGrid) {
            const yTicks = yScale.ticks(yTickCount)

            return (
                <g className="grid">
                    {yTicks.map( tick => {
                        return <line key={tick} x2={width} transform={`translate(0 , ${yScale(tick)})`} stroke={'lightgray'} />
                    })}
                </g>
            )
        }
    }

    render = () => {
        const { width, height, drawX, xLabel } = this.props

        return (
            <g>
                <g className='axes'>
                    <g transform={`translate(0, ${height})`} ref='xAxes' />
                    <g transform={'translate(0,0)'} ref='yAxes' />
                </g>
                {this.drawXGrid()}
                {this.drawYGrid()}

                {/* {drawX &&
                    <g transform={`translate(${width/2}, ${height + 40})`}><text>{xLabel}</text></g>
                } */}
            </g>
        )
    }
}

Axes.propTypes = {
    drawX : PropTypes.bool,
    drawY: PropTypes.bool,
    xScale : PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    xLabel : PropTypes.string,
    // usefull to calculate where to put each axis
    // used to calculate grid line lengths
    height : PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    // grid control
    xGrid : PropTypes.bool,
    yGrid : PropTypes.bool,
    // axes tick count and grid line no control
    xTickCount : PropTypes.number,
    yTickCount : PropTypes.number,
}