import React from 'react';

export default class Loader extends React.Component {

	render = () => {
		return(
			<div className='loading'>
				<div className='loading-anim'>
					<div className='border out'></div>
					<div className='border in'></div>
					<div className='border mid'></div>
					<div className='circle'>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
						<span className='dot'></span>
					</div>
				</div>
			</div>
		);
	}
}