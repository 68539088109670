import React from 'react'

import { format } from 'date-fns'

import BarChart from '../charts/BarChart'
import { size } from 'lodash'


export default class BarChartWrapper extends React.Component {

	state = {
		zoom: false
	}

	handleZoomIn = (params) => {
        
    }

    handleZoomOut = () => {
        
    }

	render = () => {
		const { data } = this.props
		if(size(data) < 1) {
			return <div className="reports-chart-no-data">No data</div>
		}
		const x_label = 'day', y_label = 'amount';

		const chartProps = {
			width : 700, height : 280,
			margin : { top: 50, bottom: 58, right: 50, left: 50 },
			onZoomIn: this.handleZoomIn,
            onZoomOut: this.handleZoomOut,
			zoom: this.state.zoom,
			
			x_scale: 'band',
			x_label , y_label,
			data : this.props.data.map((d) => {
                return {
                    [x_label]: format(new Date(d[x_label]), 'do MMM'),
                    [y_label]: d[y_label]
                }
            }),
		}

		return (
			<div className="reports-chart-wrapper">
				<BarChart {...chartProps} />
			</div>
		)
	}
}