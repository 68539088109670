import { get } from "lodash";
import client from "../../apollo_client";

const INIT_AUTH = {
    fetched: false,
    fetching: false,
    error: false,
    logged: false,
    is_superuser: false,
    is_staff: false,
    token: '',
    user: {}
}

export const authReducer = (state = INIT_AUTH, action) => {

    switch (action.type) {
        case "USER_LOGGING_IN": {
            return {
                ...state,
                fetched: false,
                fetching: true,
                error: false,
                logged: false,
                is_superuser: false,
                is_staff: false,
                token: null
            }
        }

        case "USER_LOGIN_SUCCESS": {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: false,
                logged: true,
                is_superuser: get(action, 'payload.user.is_superuser', false),
                is_staff: get(action, 'payload.user.is_staff', false),
                token: action.payload.token,
                user: action.payload.user
            }
        }

        case "USER_CHECKAUTH_SUCCESS": {
            return {
                ...state,
                ...action.payload,
            }
        }

        case "USER_LOGIN_FAILED": {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: true,
                error_obj: action.payload
            }
        }

        case "USER_LOGOUT": {
            client.resetStore()
            return INIT_AUTH
        }

        default: {
            return state;
        }
    }
}