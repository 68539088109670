import React, { Component } from 'react'
import { connect } from 'react-redux'

import ErrorBlock from '../../others/components/ErrorBlock'
import { postFormData } from '../../others/utils'

import Urls from '../../UrlConfig'
import { addNotification } from '../../others/data/appState.actions'

class ChangePassword extends Component {

  state = {
    loading: false,
    errors: {}
  }

  onFormSubmit = (event) => {
    if (this.state.loading) return

    event.preventDefault()

    let form = document.getElementById("change-password")
    const data = new FormData(form)

    this.setState({ loading: true, errors: {} })
    postFormData(Urls.apiChangePassword(),
      //data
      data,
      // callback
      (res) => {
        this.props.addNotification({
          type: 'success',
          icon: 'fa-check',
          title: 'Change Password',
          text: 'Password changed successfully',
        })
        this.setState({ loading: false, errors: {} })
        form.reset()
      },
      // errback
      (err) => {
        this.props.addNotification({
          type: 'error',
          icon: 'fa-ban',
          title: 'Error',
          text: 'Input Error',
        })
        this.setState({ loading: false, errors: err.error_data })
      },
    )

  }

  render = () => {
    const { loading, errors } = this.state

    return (
      <form id="change-password">
        <div className="title">Change Password</div>

        <div className='form-section'>
          <div className='form-field'>
            <div className='form-label'>
              <div className='label'>Current Password</div>
              <ErrorBlock field="current_password"
                className="error"
                error={errors} />
            </div>
            <input type="password"
              className="textbox"
              placeholder='Enter current password'
              name="current_password"
            />
          </div>

          <div className='form-field'>
            <div className='form-label'>
              <div className='label'>New Password</div>
              <ErrorBlock field="new_password"
                className="error"
                error={errors} />
            </div>
            <input type="password"
              className="textbox"
              placeholder='Enter new password'
              name="new_password"
            />
          </div>

          <div className='form-field'>
            <div className='form-label'>
              <div className='label'>Confirm Password</div>
              <ErrorBlock field="confirm_password"
                className="error"
                error={errors} />
            </div>
            <input type="password"
              className="textbox"
              placeholder='Confirm new password'
              name="confirm_password"
            />
          </div>

          <ErrorBlock
            className="error-block"
            field="__all__"
            error={errors} />

          <div className='action-wrapper'>
            {loading ?
              <div className='disable-btn'>Loading...</div>
              :
              <>
                <button type="submit" className="prime-submit-btn"></button>
                <div className='yellow-btn'
                  onClick={this.onFormSubmit}>Submit</div>
              </>
            }
          </div>
        </div>

      </form>
    )
  }
}

export default connect(null, { addNotification })(ChangePassword)