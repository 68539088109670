import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import { get } from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'

import { BackIcon } from '../../others/SvgIcons'

import { postFormData } from '../../others/utils'
import { addNotification } from '../../others/data/appState.actions'
import Urls from '../../UrlConfig'


/**
 * Scss:
 * 		global-styles / _layout
 * 		global-styles / forms / _form
 * 		global-styles / others / _loadingButton
 */
class DriverResetPass extends Component {

	state = {
		errors: {},
		loading: false
	}

	onFormSubmit = (event) => {

		event.preventDefault()
		let form = document.getElementById('reset-driver-pass');
		let formData = new FormData(form);

		// check validation in case of add
		let has_errors = false, errors = {}
		if (!formData.get('new_password')) {
			errors.new_password = ['This field is required.']
			has_errors = true
		}
		if (formData.get('new_password') !== formData.get('confirm_password')) {
			errors.confirm_password = ['New password and confirm password do not match, Please type again']
			has_errors = true
		}
		if (has_errors) {
			this.setState({ errors })
			this.props.dispatch(addNotification(
				{
					type: 'error',
					icon: 'fa-ban',
					title: 'Error',
					text: 'Input Error',
				}
			))
			return
		}
		else {
			this.setState({ loading: true, errors: {} })
		}

		formData.append('driver_id', get(this.props, 'location.state.pk'))
		postFormData(Urls.apiDriverResetPassword(),
			formData,
			(res) => {
				this.props.history.goBack()
				this.props.dispatch(addNotification(
					{
						type: 'warning',
						icon: 'fa-pencil',
						title: 'Driver Password',
						text: `${get(this.props, 'location.state.name')}'s password reset successfully`,
					}
				))
			},
			(err) => {
				this.setState({ loading: false, errors: err.error_data })
				this.props.dispatch(addNotification(
					{
						type: 'error',
						icon: 'fa-ban',
						title: 'Error',
						text: 'Input Error',
					}
				))
			}
		)
	}


	render = () => {
		const { history, isSuperuser } = this.props
		const { errors, loading } = this.state

		if(!isSuperuser) {
			return <Redirect to={Urls.getUnauthorized()}/>
		}

		return (
			<form id="reset-driver-pass" className='page-container'>
				<div className='page-title'>
					<BackIcon onClick={history.goBack} />
					<div className="heading">Reset Password</div>
				</div>

				<div className='page-content flex-wrapper'>
					<div className='flex-md secondary-bg scrollable relative'>

						<div className='form-section z-depth-2'>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>New Password for {get(this.props, 'location.state.name')}</div>
									<ErrorBlock field="new_password"
										className="error"
										error={errors} />
								</div>
								<input type="password"
									className="textbox"
									placeholder='Enter new password'
									name="new_password"
								/>
							</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Confirm Password</div>
									<ErrorBlock field="confirm_password"
										className="error"
										error={errors} />
								</div>
								<input type="password"
									className="textbox"
									placeholder='Confirm new password'
									name="confirm_password"
								/>
							</div>

							<ErrorBlock
								className="error-block"
								field="__all__"
								error={errors} />

							<div className='form-actions multi-action'>
								<div className='btn secondary-dark-btn' onClick={history.goBack}>Cancel</div>
								{loading ?
									<div className='btn disable-btn'>Loading...</div>
									:
									<div className='btn yellow-btn'
										onClick={this.onFormSubmit}>Reset Password</div>
								}
							</div>

						</div>

					</div>
				</div>

			</form>
		)
	}
}


export default connect(store => ({
	isSuperuser: store.auth.is_superuser
}))(DriverResetPass)