import { bindActionCreators } from 'redux'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useMemo } from 'react'


/**
 * Wrapper to make action call ez to use
 * 
 * Example Usage :
 * const do_smt = useAction(() => ({type : "ACTION", payload : "DATA"}))
 * // in JSX <div onClick={do_smt}>Do Somthing</div>
 * 
 * @param {function / Array of functions} actions function that will return { type, payload}
 * @param {Array} deps closure variables
 */
export function useActions(actions, deps) {
    const dispatch = useDispatch()
    return useMemo(
        () => {
            if (Array.isArray(actions)) {
                return actions.map(a => bindActionCreators(a, dispatch))
            }
            return bindActionCreators(actions, dispatch)
        },
        deps ? [dispatch, ...deps] : [dispatch]
    )
}


export function useShallowEqualSelector(selector) {
    return useSelector(selector, shallowEqual)
}