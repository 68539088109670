import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

import {get} from 'lodash'

import Pagination from "../../others/components/Pagination";
import DriverList from '../components/DriverList';
import DriverSearch from '../components/DriverSearch'

import useListWithPaginationSearch from '../../others/data/search.hook';
import { GET_DRIVERS_LIST } from '../data/driver.graphql'
import Urls from "../../UrlConfig"



export default () => {

	/**
	 * 
	 * data component - list fetch 
	 * 
	 * Parent
	 * 		DriverRoute
	 * 
	 * Renders
	 * 		DriverList
	 * 		DriverSearch
	 * 		Pagination
	 */

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	const {loading, data, error,
		hasNextPage, hasPrevPage, fetchNextData, fetchPrevData,
		pageNo, rowCount, changeRowCount,
		handleSearch
	} = useListWithPaginationSearch(GET_DRIVERS_LIST, "drivers")

	const edges = get(data ,'drivers.edges', [] )
	
	const paginationProps = {
		pageNo,
		hasPrevPage, hasNextPage,
		fetchNextData, fetchPrevData,
		currentCount:  rowCount,
		changeCount: changeRowCount,
	}


	return (
		<div id='driver-list' className='page-container'>

			<div className="page-title-wrapper">
				<div className='page-title'>Driver</div>
				
				<div className='search-wrapper'>
					<DriverSearch handleSearch={handleSearch} />
					{isSuperuser &&
						<div className='btn-container'>
							<Link to={Urls.getDriverAddPage()} className='yellow-btn'>
								<i className="fa fa-plus" aria-hidden="true"></i>Add Driver
							</Link>
						</div>
					}
				</div>
			</div>

			<div className='page-content flex-wrapper'>
				<div className='flex-md secondary-bg scrollable relative'>
					<div className='data-sections'>
						<div className='search-result-section'>
							Showing {edges.length} results
						</div>
						<div className='list-section'>
							<DriverList data={edges} loading={loading} 
								count={rowCount} error={error}
							/>
							
							<Pagination {...paginationProps} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}