import React from 'react'

export const Dashboard = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
	</svg>
)

export const Vehicle = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
	</svg>
)

export const Driver = () => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 511.79 511.79" style={{enableBackground: "new 0 0 511.79 511.79"}} xmlSpace="preserve" >
		<g>
			<path d="M255.895,387.981c-10.876,0-19.724,8.848-19.724,19.723c0,10.876,8.848,19.724,19.724,19.724s19.724-8.848,19.724-19.724   C275.619,396.829,266.771,387.981,255.895,387.981z" />
			<path d="M255.895,302.55c-28.204,0-53.844,11.167-72.746,29.306l25.372,7.259l-17.458,53.589h17.426   c6.377-20.108,25.215-34.723,47.406-34.723s41.029,14.615,47.406,34.723h17.426l-17.458-53.589l25.381-7.262   C309.747,313.714,284.101,302.55,255.895,302.55z" />
			<path d="M208.489,422.704H181.29l-7.792,23.919l-16.308-2.672c13.134,35.655,45.057,62.296,83.705,67.84v-56.68   C225.522,450.235,213.364,438.077,208.489,422.704z" />
			<path d="M436.834,324.073l-42.181-104.327c-6.124-15.146-20.638-24.934-36.977-24.934H154.114   c-16.339,0-30.853,9.787-36.977,24.934L74.957,324.073c-5.457,13.499-1.646,28.875,9.482,38.263l57.81,48.766l10.698,1.754   l17.414-53.455l-15.561-4.451l-18.645,0.517l-0.832-29.988L149,325.098c24.742-31.944,63.455-52.548,106.895-52.548   c43.436,0,82.135,20.609,106.877,52.548l13.694,0.38l-0.832,29.988l-18.645-0.517l-15.561,4.451l17.414,53.455l10.698-1.754   l57.81-48.766C438.48,352.948,442.291,337.572,436.834,324.073z" />
			<path d="M330.5,422.704h-27.199c-4.875,15.373-17.033,27.531-32.406,32.406v56.679c38.646-5.544,70.568-32.184,83.701-67.838   l-16.304,2.672L330.5,422.704z" />
			<circle cx="255.895" cy="89.906" r="89.906" />
		</g>
	</svg>
)

export const Trip = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M12 7C6.48 7 2 9.24 2 12c0 2.24 2.94 4.13 7 4.77V20l4-4-4-4v2.73c-3.15-.56-5-1.9-5-2.73 0-1.06 3.04-3 8-3s8 1.94 8 3c0 .73-1.46 1.89-4 2.53v2.05c3.53-.77 6-2.53 6-4.58 0-2.76-4.48-5-10-5z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

export const Report = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" />
	</svg>
)

export const Logout = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
	</svg>
)

export const AddDriver = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
	</svg>
)


export const CloseIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
	</svg>
)

export const AddIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
)

export const ProfileIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
	</svg>
)

export const BackIcon = ({ onClick = () => { } }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
		className="back-icon" onClick={onClick}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
	</svg>
)

export const DefaultDriver = () => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
		width="512" height="512" x="0" y="0" viewBox="0 0 300.001 300.001"
		className="default-driver-icon" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve">
		<g xmlns="http://www.w3.org/2000/svg">
			<g>
				<g>
					<path d="M111.29,87.097h-6.561c9.89-7.989,16.239-20.197,16.239-33.871V36.019c5.758-3.358,9.677-9.532,9.677-16.665     C130.646,8.681,121.966,0,111.29,0H43.548C32.875,0,24.195,8.681,24.195,19.355c0,7.132,3.919,13.306,9.677,16.665v17.206     c0,13.674,6.348,25.882,16.239,33.871H43.55c-24.01,0-43.549,19.538-43.549,43.548v48.387c0,16.011,13.021,29.032,29.032,29.032     V300h96.774v-91.935c16.011,0,29.032-13.021,29.032-29.032v-48.387C154.839,106.635,135.3,87.097,111.29,87.097z M33.872,19.354     c0-5.337,4.34-9.677,9.677-9.677h67.742c5.337,0,9.677,4.34,9.677,9.677s-4.34,9.677-9.677,9.677H43.548     C38.212,29.031,33.872,24.691,33.872,19.354z M108.842,38.71c-3.711,5.894-10.229,9.677-17.502,9.677H63.498     c-7.273,0-13.79-3.784-17.502-9.677H108.842z M43.548,53.226v-2.734c5.39,4.718,12.368,7.573,19.95,7.573h27.842     c7.582,0,14.56-2.855,19.95-7.573v2.734c0,18.677-15.194,33.871-33.871,33.871S43.548,71.903,43.548,53.226z M99.305,96.774     l-4.804,12.02l-4.645-3.097l8.922-8.923H99.305z M80.256,101.613h-5.671l-4.839-4.839h15.349L80.256,101.613z M82.089,162.682     l-4.669,4.669l-4.669-4.669l4.282-51.392h0.774L82.089,162.682z M64.988,105.701l-4.645,3.097l-4.81-12.024h0.528L64.988,105.701     z M29.034,198.387c-7.133,0-13.307-3.919-16.665-9.677h16.665V198.387z M72.581,290.323H38.711v-9.677h33.871V290.323z      M116.13,290.323H82.258v-9.677h33.871V290.323z M116.13,270.968H82.258v-53.226h-9.677v53.226H38.711v-72.581h77.419V270.968z      M125.807,198.387v-9.677h16.665C139.114,194.468,132.94,198.387,125.807,198.387z M145.162,179.032h-19.355v-58.065h-9.677     v67.742H38.711v-67.742h-9.677v58.065H9.678v-48.387c0-18.677,15.194-33.871,33.871-33.871h1.563l10.679,26.69l11.139-7.427     l-4.195,50.313l14.685,14.686l14.685-14.685l-4.195-50.313l11.139,7.427l10.679-26.69h1.563     c18.677,0,33.871,15.194,33.871,33.871V179.032z" fill="#d0d0d0" />
					<path d="M247.37,0c-11.124,0-20.773,7.539-23.473,18.324l-12.353,49.418h-8.318V53.226h-29.032     c-10.674,0-19.355,8.681-19.355,19.355s8.681,19.355,19.355,19.355h23.052l7.335,3.668l-0.61,2.439     c-23.647,9.518-39.455,32.748-39.455,58.326v78.16c0,11.54,8.216,21.314,19.355,23.603v27.353     c0,8.003,6.513,14.516,14.516,14.516h38.71c8.003,0,14.516-6.513,14.516-14.516V261.29H300v-19.355v-38.71v-96.774v-9.677V0     H247.37z M193.549,82.258h-19.355c-5.337,0-9.677-4.34-9.677-9.677s4.34-9.677,9.677-9.677h19.355V82.258z M203.227,84.106     v-6.687h5.898l-2.144,8.565L203.227,84.106z M203.226,290.323h-4.839c-2.665,0-4.838-2.173-4.838-4.839v-26.095     c3.218,0.329,6.445,0.61,9.677,0.852V290.323z M237.097,290.323h-4.839v-4.839h-9.677v4.839h-9.677v-29.487     c3.227,0.155,6.45,0.271,9.677,0.339v14.632h9.677V261.29h9.677v24.194h0.001C241.936,288.15,239.763,290.323,237.097,290.323z      M290.323,232.258h-77.419c-5.337,0-9.677-4.34-9.677-9.677c0-5.337,4.34-9.677,9.677-9.677h77.419V232.258z M290.323,203.226     h-77.419c-10.674,0-19.355,8.681-19.355,19.355s8.681,19.355,19.355,19.355h77.419v9.677h-60.339     c-14.356,0-28.848-0.905-43.074-2.681c-7.248-0.905-12.716-7.098-12.716-14.405v-78.16c0-22.065,13.882-42.092,34.539-49.839     l0.213-0.077h81.377V203.226z M290.323,96.774h-76.06l19.026-76.103c1.616-6.469,7.408-10.994,14.081-10.994h42.953V96.774z" fill="#d0d0d0" />
					<path d="M188.711,150c0,16.011,13.021,29.032,29.032,29.032s29.032-13.021,29.032-29.032s-13.021-29.032-29.032-29.032     S188.711,133.989,188.711,150z M217.743,130.645c10.674,0,19.355,8.681,19.355,19.355s-8.681,19.355-19.355,19.355     s-19.355-8.681-19.355-19.355S207.068,130.645,217.743,130.645z" fill="#d0d0d0" />
				</g>
			</g>
		</g>
	</svg>
)

export const ClientUsersIcon = () => (
	<svg className="client-user-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z" />
	</svg>
)