import React, { Component } from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import { TimePickerInput } from 'react-datetime-range-super-picker'

import { get, pick } from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'

import { updateAfterSuccess, updateFormField, deleteSchedule } from '../data/schedulePage.actions'
import { getDateFromTime, getJsonData, postJsonData } from '../../others/utils'
import { addNotification } from '../../others/data/appState.actions'
import Urls from '../../UrlConfig'


class ScheduleForm extends Component {

    /**
     * render individual form for schedule
     * add/ edit data to server
     * 
     * Parent:
     *      TripAddPage / ScheduleFormsList
     */

    state = {
        errors: {},
        loading: false,
        deleting: false
    }

    submit = (e) => {
        if (e) e.preventDefault()

        if (this.props.details.vehicle === -1) {
            this.setState({ errors: { "vehicle": ["Please Select vehicle "] } })
            this.props.dispatch(addNotification(
                {
                    type: 'error',
                    icon: 'fa-ban',
                    title: 'Required !!',
                    text: 'Please Select vehicle.',
                }
            ))
            return
        }

        let data = pick(this.props.details, ['driver', 'vehicle', 'from_destination', 'to_destination', 'material_description'])
        data.start_time = getDateFromTime(this.props.startDate, this.props.details.picker_start_time)
        data.end_time = getDateFromTime(this.props.startDate, this.props.details.picker_end_time)

        // if id exist, form will edit otherwise create new schedule
        if (this.props.isEdit) {
            data.id = this.props.details.id
        }

        this.setState({ loading: true, errors: {} })
        postJsonData(Urls.apiTripAdd(),
            data,
            (res) => {
                // save id in state for edit
                this.setState({ loading: false })
                const isEdit = this.props.isEdit
                const findId = isEdit ? this.props.details.id : this.props.details.formId
                this.props.dispatch(updateAfterSuccess({ res, isEdit, findId }))

                this.props.dispatch(addNotification(
					{
                        key: res.id,
						type: isEdit ? 'warning': 'success',
						icon: isEdit ? 'fa-pencil' : 'fa-check',
						title: `Trip ${isEdit ? "Updated" : "Added"}`,
						text: `From ${res.from_destination} to ${res.to_destination} ${isEdit ? "Updated" : "Added"} successfully`,
					}
				))
            },
            (err) => {
                this.props.dispatch(addNotification(
					{
                        key: +new Date(),
						type: 'error',
						icon: 'fa-ban',
						title: 'Error',
						text: 'Input Error',
					}
				))
                this.setState({ loading: false, errors: err.error_data })
            }
        )
    }

    delete_trip = (e) => {
        if (e) e.preventDefault()
        const isEdit = this.props.isEdit
        // here findId can be pk ( edit ) or custom created ( add ) id
        const findId = isEdit ? this.props.details.id : this.props.details.formId

        if (isEdit) {
            this.setState({ deleting: true })
            getJsonData(Urls.apiTripDelete(findId),
                (res) => {
                    this.props.dispatch(deleteSchedule({ isEdit, findId }))
                    this.props.dispatch(addNotification(
                        {
                            key: +new Date(),
                            type: 'warning',
                            icon: 'fa-pencil',
                            title: 'Trip Delete',
                            text: 'Trip Deleted Successfully.',
                        }
                    ))
                },
                (err) => {
                    this.props.dispatch(addNotification(
                        {
                            key: +new Date(),
                            type: 'error',
                            icon: 'fa-ban',
                            title: 'Error',
                            text: 'Input Error',
                        }
                    ))
                    this.setState({ deleting: false, errors: err.error_data })
                }
            )
        }
        else {
            this.props.dispatch(deleteSchedule({ isEdit, findId }))
        }
    }
    // time handlers
    onStartTimeUpdate = ({ time }) => {
        const isEdit = this.props.isEdit
        const findId = isEdit ? this.props.details.id : this.props.details.formId
        this.props.dispatch(updateFormField({
            isEdit, findId,
            data: { picker_start_time: { hour24: time.hour24, minute: time.minute } },
        }))
    }

    onEndTimeUpdate = ({ time }) => {
        const isEdit = this.props.isEdit
        const findId = isEdit ? this.props.details.id : this.props.details.formId
        this.props.dispatch(updateFormField({
            isEdit, findId,
            data: { picker_end_time: { hour24: time.hour24, minute: time.minute } },
        }))
    }

    // driver handler
    onDriverSelect = (edge) => {
        const isEdit = this.props.isEdit
        const findId = isEdit ? this.props.details.id : this.props.details.formId
        this.props.dispatch(updateFormField({
            isEdit, findId,
            data: { driver: edge.node.pk }
        }))
    }

    // vehicle handler
    onVehicleSelect = (edge) => {
        // select vehicle and its default driver if exist
        const isEdit = this.props.isEdit
        const findId = isEdit ? this.props.details.id : this.props.details.formId
        this.props.dispatch(updateFormField({
            isEdit, findId,
            data: {
                vehicle: edge.node.pk,
                driver: get(edge, 'node.driver.pk', null)
            },
        }))
        this.setState({ errors: {} })
    }

    // input handler
    onTextChange = (e) => {
        const isEdit = this.props.isEdit
        const findId = isEdit ? this.props.details.id : this.props.details.formId
        this.props.dispatch(updateFormField({
            isEdit, findId,
            data: { [e.target.name]: e.target.value }
        }))
    }

    render = () => {
        const { errors, loading, deleting } = this.state

        const { driverList, vehicleList, details, isEdit } = this.props
        const { from_destination, to_destination,
            picker_start_time, picker_end_time,
            vehicle, driver, material_description } = details

        return (
            <div className='form-section z-depth-2'>

                <div className='form-column'>
                    <div className='form-column-field'>

                        <div>
                            <div className='form-column-field'>
                                <div className='form-field'>
                                    <div className='form-label'>
                                        <div className='label'>From Destination</div>
                                        <ErrorBlock field="from_destination"
                                            className="error"
                                            error={errors} />
                                    </div>
                                    <input type="text"
                                        className="textbox"
                                        placeholder='Enter From Destionation'
                                        name="from_destination"
                                        value={from_destination}
                                        onChange={this.onTextChange}
                                    />
                                </div>
                            </div>
                            <div className='form-column-field'>
                                <div className='form-field'>
                                    <div className='form-label'>
                                        <div className='label'>To Destination</div>
                                        <ErrorBlock field="to_destination"
                                            className="error"
                                            error={errors} />
                                    </div>
                                    <input type="text"
                                        className="textbox"
                                        placeholder='Enter To Destionation'
                                        name="to_destination"
                                        value={to_destination}
                                        onChange={this.onTextChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='form-column-field'>
                                <div className='form-field'>
                                    <div className='form-label'>
                                        <div className='label'>Start Time</div>
                                    </div>
                                    <TimePickerInput className="super-picker-wrapper"
                                        popupClassName="super-picker-popup"
                                        time={picker_start_time}
                                        onTimeUpdate={this.onStartTimeUpdate}
                                    />
                                </div>
                            </div>
                            <div className='form-column-field'>
                                <div className='form-field'>
                                    <div className='form-label'>
                                        <div className='label'>End Time</div>
                                    </div>
                                    <TimePickerInput className="super-picker-wrapper"
                                        popupClassName="super-picker-popup"
                                        time={picker_end_time}
                                        onTimeUpdate={this.onEndTimeUpdate}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='form-column-field'>
                        <div>
                            <div className='form-column-field'>
                                <div className='form-field'>
                                    <div className='form-label'>
                                        <div className='label'>Vehicle</div>
                                        <ErrorBlock field="vehicle"
                                            className="error"
                                            error={errors} />
                                    </div>
                                    <Select className="select-dropdown"
                                        placeholder='Select Vehicle'
                                        value={vehicleList.filter(edge => edge.node.pk === vehicle)}
                                        options={vehicleList}
                                        onChange={this.onVehicleSelect}
                                        getOptionLabel={props => props.node.name}
                                        getOptionValue={props => props.node.pk}
                                    />
                                </div>
                                <div className='form-field'>
                                    <div className='form-label'>
                                        <div className='label'>Driver</div>
                                        <ErrorBlock field="driver"
                                            className="error"
                                            error={errors} />
                                    </div>
                                    <Select className="select-dropdown"
                                        placeholder='Select Driver'
                                        value={driverList.filter(edge => edge.node.pk === driver)}
                                        options={driverList}
                                        onChange={this.onDriverSelect}
                                        getOptionLabel={props => props.node.name}
                                        getOptionValue={props => props.node.pk}
                                    />
                                </div>
                            </div>
                            <div className='form-column-field'>
                                <div className='form-field no-margin-bottom'>
                                    <div className='form-label'>
                                        <div className='label'>Material Description</div>
                                        <ErrorBlock field="material_description"
                                            className="error"
                                            error={errors} />
                                    </div>
                                    <textarea type="text"
                                        className="textbox"
                                        placeholder='Enter Material Description'
                                        name="material_description"
                                        value={material_description}
                                        onChange={this.onTextChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div className="form-submit-btn">
                        {loading ?
                            <div className='btn disable-btn'>Loading...</div>
                            :
                            <div className='btn orange-btn' onClick={this.submit}>
                                {isEdit ? "Update Changes" : "Save Changes"}
                            </div>
                        }
                    </div>
                    <div className="form-submit-btn">
                        {deleting ?
                            <div className='btn disable-btn'>Loading...</div>
                            :
                            <div className='btn secondary-dark-btn' onClick={this.delete_trip}>
                                Delete
                            </div>
                        }
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        )
    }
}

export default connect(store => ({
    startDate: store.schedulePage.startTime,
}))(ScheduleForm)