import { startOfToday, endOfToday } from 'date-fns'
import { findIndex, differenceBy } from 'lodash'

const INIT_SCHEDULES = {
    selectedVehicle: -1, // vehicle id, default : all ( -1 )
    selectedVehicleDriver: -1, // driver id, default : all ( -1 )
    /**
     * 1: Today
     * 2: yesterday
     * 3: Custom
     */
    currentTimeRange: 1,
    startTime: startOfToday(),
    endTime: endOfToday(),
    // initial form id, increament with add new form
    lastFormId: 0,

    scheduleListFetched: false,
    scheduleListError: null,
    scheduleList: [],
}

export const schedulePageReducer = (state = INIT_SCHEDULES, { type, payload }) => {
    switch (type) {

        case "SCHEDULE_LIST_FETCHING": {
            return {
                ...state,
                scheduleListFetched: false,
                scheduleListError: null,
                scheduleList: []
            }
        }

        case "SCHEDULE_LIST_FETCH_SUCCESS": {

            let scheduleList = []
            for (let index = 0; index < payload.length; index++) {
                const currTrip = payload[index];
                // set ISO date time string into picker { hour24, minute shape }
                // and add into current form data
                let picker_start_time = new Date(currTrip.start_time)
                picker_start_time = { hour24: picker_start_time.getHours(), minute: picker_start_time.getMinutes() }

                let picker_end_time = new Date(currTrip.end_time)
                picker_end_time = { hour24: picker_end_time.getHours(), minute: picker_end_time.getMinutes() }
                scheduleList.push({
                    ...currTrip, picker_start_time, picker_end_time
                })
            }

            return {
                ...state,
                scheduleListFetched: true,
                scheduleListError: null,
                scheduleList, lastFormId: 0
            }
        }

        case "SCHEDULE_LIST_FETCH_ERROR": {
            return {
                ...state,
                scheduleListFetched: false,
                scheduleListError: payload,
                scheduleList: []
            }
        }

        case "UPDATE_SCHEDULE_FORM_FIELDS": {
            const { isEdit, findId, data } = payload

            let scheduleList = [...state.scheduleList]
            // find index from list using given id
            // id source are different in add and edit
            const currIndex = findIndex(scheduleList, [isEdit ? 'id' : 'formId', findId])
            scheduleList[currIndex] = { ...scheduleList[currIndex], ...data }

            return { ...state, scheduleList }
        }

        case "UPDATE_SCHEDULE_DATA": {
            return { ...state, ...payload }
        }

        case "SCHEDULE_ADD": {
            const newFormId = state.lastFormId + 1
            const initData = {
                formId: newFormId,
                from_destination: '',
                to_destination: '',
                material_description: '',
                picker_start_time: { hour24: 8, minute: 0 },
                picker_end_time: { hour24: 8, minute: 0 },
                driver: state.selectedVehicleDriver,
                vehicle: state.selectedVehicle,
            }
            // add new empty data for form into first place
            let scheduleList = [initData, ...state.scheduleList]

            return { ...state, scheduleList, lastFormId: newFormId }
        }

        case "SCHEDULE_ADD_SUCCESSFULLY": {
            const { res, isEdit, findId } = payload

            // setup data
            let scheduleList = [...state.scheduleList]
            const currIndex = findIndex(scheduleList, [isEdit ? 'id' : 'formId', findId])

            // after success, convert ISO time to picker format
            let newData = { ...res }
            let picker_start_time = new Date(newData.start_time)
            picker_start_time = { hour24: picker_start_time.getHours(), minute: picker_start_time.getMinutes() }

            let picker_end_time = new Date(newData.end_time)
            picker_end_time = { hour24: picker_end_time.getHours(), minute: picker_end_time.getMinutes() }

            newData.picker_start_time = picker_start_time
            newData.picker_end_time = picker_end_time
            
            // set updated data
            scheduleList[currIndex] = newData

            return { ...state, scheduleList }
        }

        case "SCHEDULE_DELETE": {
            const { isEdit, findId } = payload
            const identifier = isEdit ? 'id' : 'formId'
            const scheduleList = differenceBy(state.scheduleList, [{ [identifier]: findId }], identifier)
            return { ...state, scheduleList }
        }

        case "CLEAR_SCHEDULE_DATA": {
            return { ...INIT_SCHEDULES }
        }

        case "USER_LOGOUT": {
            return { ...INIT_SCHEDULES }
        }

        default: {
            return state
        }
    }
}
