import React from "react"

import { Link } from 'react-router-dom'

import { format } from 'date-fns'
import { get } from 'lodash'

import ListLoader from '../../others/components/ListLoader'
import Urls from "../../UrlConfig"


export default ({ loading, data, error, count }) => {

  /**
   * Parent:
   * 		DashboardPage
   */

  let table;

  if (loading) {
    table = <ListLoader row={count} col={3} />
  }
  else if (error) {
    table = <div className="list-empty-wrapper error">Failed to fetch data!!</div>
  }
  else {
    if (data.length) {
      table = data.map((edge) => {
        const { cursor, node } = edge

        return (
          <Link to={Urls.getExpenseEditPage(node.pk)} className='list-row' key={cursor}>
            <div className='list-col'>
              {format(new Date(node.timestamp), 'do MMM hh:mm a')}
            </div>
            <div className='list-col'>{get(node, 'vehicle.name', '-NA-')}</div>
            <div className='list-col'>{get(node, 'expense_type.name', '-NA-')}</div>
            <div className='list-col'>{node.cost}</div>
          </Link>
        )
      })
    }
    else {
      table = <div className="list-empty-wrapper">No Expenses Found</div>
    }
  }

  return (
    <div className='list-container fix-table'>
      <div className='list-row'>
        <div className='list-col'>Time</div>
        <div className='list-col'>Vehicle</div>
        <div className='list-col'>Expense Type</div>
        <div className='list-col'>Cost</div>
      </div>

      {table}

    </div>
  )
}