import { 
    scaleTime, 
    scaleLinear, 
    scaleBand, 
    scaleQuantize,
    scaleSequential
} from "d3-scale"

import minBy from 'lodash/minBy'
import maxBy from "lodash/maxBy"


/**
 * creats a d3 scale object that can be used for further calculations
 * 
 * @param {array} data list of data points
 * @param {string} label key of data point for this scale
 * @param {array} range list of min and max value as a range of scale
 * @param {array} domain same as range; (optional) if not provided will be calculated from data
 * @param {string} scale_type type of scale to create. e.g. linear | time | band
 * @param {boolean} padding if true will add 10% padding ; used for linear type scales
 * @param {boolean} inverted if true invert the min max clamp values of the domain
 */
export function createScale({data, label, range, domain=[], scale_type, padding=true, inverted=false}) {
    
    switch (scale_type) {
        case "time": 
            const date_max = maxBy(data, label)[label],
                date_min = minBy(data, label)[label];

            return scaleTime().domain([new Date(date_min), new Date(date_max)])
                .range(range).clamp(true)

        case "linear": {
            const value_max = maxBy(data, label)[label],
                value_min = minBy(data, label)[label],
                value_padding = padding ? Math.round((value_max - value_min) * 0.1) : 0;

            return scaleLinear()
                .domain([value_min, value_max + value_padding]).nice()
                .range(range)
        }

        case "linear-series": {
            // data : [ series1, [point1 , [y0, y1], ... ] , ...]
            const last_series = data[data.length - 1],
                first_series = data[0],
                value_max = maxBy(last_series, (point) =>  point[1] )[1],
                value_min = minBy(first_series, (point) => point[0] )[0],
                value_padding = padding ? Math.round((value_max - value_min) * 0.1) : 0;
            
            return scaleLinear()
                .domain([value_min, value_max + value_padding]).nice()
                .range(range)
        }

        case "quantize": {
            const value_max = maxBy(data, label)[label],
                value_min = minBy(data, label)[label]
            
            return scaleQuantize()
                .domain([value_min, value_max])
                .range(range)
        }

        case "color": {
            let c_domain;
            if(domain.length) {
                // domain already has user defined min max values no need to calculate
                c_domain = domain
            } else {
                const value_max = maxBy(data, label)[label],
                    value_min = minBy(data, label)[label]
                
                c_domain = inverted ? [value_max, value_min] : [value_min, value_max]
            }

            return scaleSequential()
                .domain(c_domain)
                .interpolator(range);
        }

        case "band" : {
            return scaleBand().range(range)
                .domain(data.map(d => d[label]))
                .padding(0.1)
        }
    
        default:
            throw `This scale type is not implemented!! : ${scale_type}`
    }
}

export function bisector (compare) {
    if (compare.length === 1) compare = ascendingComparator(compare);
    return {
        left: function (a, x, lo, hi) {
            if (lo == null) lo = 0;
            if (hi == null) hi = a.length;
            while (lo < hi) {
                var mid = lo + hi >>> 1;
                if (compare(a[mid], x) < 0) lo = mid + 1;
                else hi = mid;
            }
            return lo;
        },
        right: function (a, x, lo, hi) {
            if (lo == null) lo = 0;
            if (hi == null) hi = a.length;
            while (lo < hi) {
                var mid = lo + hi >>> 1;
                if (compare(a[mid], x) > 0) hi = mid;
                else lo = mid + 1;
            }
            return lo;
        }
    };
}

function ascendingComparator(f) {
    return function (d, x) {
        return ascending(f(d), x);
    };
}

export function ascending (a, b) {
    return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
}

/**
 * 0 based circular array with given length that has inc and desc func
 * 
 * @param {Number} value current value that needs to increment
 * @param {Number} length Length of the entire circular array
 */
export function incrementCircularData(value, length) {
    return (value + 1) % length
}

export function decrementCircularData(value, length) {
    return (value - 1 + length) % length
}

export const uniq_colors = [
    '#59666C', '#51ADAC', '#CE855A', '#88B14B', "#a6cee3", "#1f78b4", "#b2df8a", "#33a02c", "#fb9a99", "#e31a1c", "#fdbf6f", "#ff7f00", "#cab2d6", "#6a3d9a", "#ffff99", "#b15928", "#1b9e77", "#d95f02", "#7570b3", "#e7298a", "#66a61e", "#e6ab02", "#a6761d", "#666", "#7fc97f", "#beaed4", "#fdc086", "#ffff99", "#386cb0", "#f0027f"
]