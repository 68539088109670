import React from "react"
import {Link} from 'react-router-dom'

import {get} from 'lodash'

import ListLoader from '../../others/components/ListLoader'

import Urls from '../../UrlConfig'

export default ({loading, data, error, count}) => {

	/**
	 * Parent:
	 * 		DriverDetailsPage
	 */

	let table;

	if(loading) {
		table = <ListLoader row={count} col={5}/>
	} 
	else if (error) {
		table = <div className="list-empty-wrapper error">Failed to fetch data!!</div>
	}
	else {
		if(data.length) {
			table = data.map((edge) => {
				const {cursor, node} = edge
	
				return (
					<div className='list-row' key={cursor}>
						<div className='list-col'>{node.name}</div>
						<div className='list-col'>{node.licence_no}</div>
						<div className='list-col'>{node.monthly_salary}</div>
						<div className='list-col'>{get(node, 'vehicle.name', '-NA-')}</div>
						<div className='list-col action-col pd-l-0'>
							<Link to={{ pathname: Urls.getDriverDetails(node.pk), state: { details: node } }}
								className='yellow-btn action-btn'>
								<i className="fa fa-external-link" aria-hidden="true"></i>View
							</Link>
						</div>
	
					</div>
				)
			})
		}
		else {
			table = <div className="list-empty-wrapper">No Driver Found</div>
		}
	}

	return (
		<div className='list-container'>
			<div className='list-row'>
				<div className='list-col'>Name</div>
				<div className='list-col'>Licence No</div>
				<div className='list-col'>Monthly Salary</div>
				<div className='list-col'>Vehicle</div>
				<div className='list-col'></div>
			</div>

			{table}

		</div>
	)
}