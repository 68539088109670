import React from 'react'

/**
 * Here component use for show loading before list render
 * one default row represent header of table
 * count => no of row by pagination
 */
export default ({row, col}) =>  {

	const row_item = new Array(Number(row)).fill(Number(row))
	const col_item = new Array(Number(col)).fill(Number(col))

	return row_item.map((_, index) => {
			return (
				<div className="list-row list-row-loading" key={index}>
					{col_item.map((_, col_ind) => {
						return <div className='list-col' key={col_ind}></div>
					})}
				</div>
			)
		})
}
