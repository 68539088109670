import React from 'react'
import { Switch, Route } from 'react-router-dom'

import TripAddPage from "./pages/TripAddPage"
import TripDetailsPage from "./pages/TripDetailsPage"
import TripListWrapperPage from "./pages/TripListWrapperPage"
import ExpensePage from "../expense/pages/ExpensePage"

import PageNotFound from "../others/pages/PageNotFound"

import Urls from "../UrlConfig"

export default () => {

	/**
	 * Parent
	 * 		App
	 */
	
	return (
		<Switch>
			<Route exact path={Urls.getTripPage()} component={TripListWrapperPage} />
			<Route path={Urls.getTripAddPage()} component={TripAddPage} />
			<Route path={Urls.getTripDetails()} component={TripDetailsPage} />
			<Route path={Urls.getExpenseAddPage()} component={ExpensePage} />
			<Route path="*" component={PageNotFound} />
		</Switch>
	)
}
