import React, { Component } from 'react'

import { get, pick } from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'
import Urls from '../../UrlConfig'
import { getJsonData, postJsonData } from '../../others/utils'
import { connect } from 'react-redux'
import { addNotification } from '../../others/data/appState.actions'

class ClientForm extends Component {

    /**
     * add client
     * delete client
     * view client details in form
     * 
     * Parent:
     *      ClientFormPage
     */

    constructor(props) {
        super(props)

        this.state = {
            pk: get(props, 'details.pk', ''),
            email: get(props, 'details.email', ''),
            contact_no: get(props, 'details.contact_no', ''),
            first_name: get(props, 'details.first_name', ''),
            last_name: get(props, 'details.last_name', ''),
            password: '',
            deleting: false,
            loading: false,
            errors: null
        }
    }

    onFormSubmit = (event) => {
        if(this.props.isEdit) return
		event.preventDefault()

        let data = pick(this.state, ['email', 'contact_no', 'first_name', 'last_name', 'password'])
        this.setState({ loading: true, errors: {} })
		postJsonData(Urls.apiCreateClient(),
			data,
			(res) => {
				this.props.onCancle()
				this.props.dispatch(addNotification(
					{
						type: 'warning',
						icon: 'fa-pencil',
						title: 'Client Create',
						text: 'Client created Successfully.',
					}
				))
			},
			(err) => {
				this.props.dispatch(addNotification(
					{
						type: 'error',
						icon: 'fa-ban',
						title: 'Error',
						text: 'Input Error',
					}
				))
				this.setState({ loading: false, errors: err.error_data })
			}
		)
    }

    handleDelete = (e) => {
		if (e) e.preventDefault()

		this.setState({ deleting: true })
		getJsonData(Urls.apiClientDelete(this.state.pk),
			(res) => {
				this.props.onCancle()
				this.props.dispatch(addNotification(
					{
						type: 'warning',
						icon: 'fa-pencil',
						title: 'Client Delete',
						text: 'Client Deleted Successfully.',
					}
				))
			},
			(err) => {
				this.setState({ deleting: false, errors: err.error_data })
			}
		)
    }

    // input handler
    onTextChange = (e) => {
        if(this.props.isEdit) return
        this.setState({ [e.target.name]: e.target.value })
    }

    render = () => {
        const { isEdit } = this.props
        const { first_name, last_name, contact_no, email, password, errors } = this.state

        return (
            <form className='form-section z-depth-2'>
                <div className='form-field'>
                    <div className='form-label'>
                        <div className='label'>First Name</div>
                        <ErrorBlock field="first_name"
                            className="error"
                            error={errors} />
                    </div>
                    <input type="text"
                        readOnly={isEdit}
                        disabled={isEdit}
                        className="textbox"
                        placeholder='Enter First Name'
                        name="first_name"
                        value={first_name}
                        onChange={this.onTextChange}
                    />
                </div>
                <div className='form-field'>
                    <div className='form-label'>
                        <div className='label'>Last Name</div>
                        <ErrorBlock field="last_name"
                            className="error"
                            error={errors} />
                    </div>
                    <input type="text"
                        readOnly={isEdit}
                        disabled={isEdit}
                        className="textbox"
                        placeholder='Enter Last Name'
                        name="last_name"
                        value={last_name}
                        onChange={this.onTextChange}
                    />
                </div>
                <div className='form-field'>
                    <div className='form-label'>
                        <div className='label'>Contact No</div>
                        <ErrorBlock field="contact_no"
                            className="error"
                            error={errors} />
                    </div>
                    <input type="text"
                        readOnly={isEdit}
                        disabled={isEdit}
                        className="textbox"
                        placeholder='Enter Contact No'
                        name="contact_no"
                        value={contact_no}
                        onChange={this.onTextChange}
                    />
                </div>
                <div className='form-field'>
                    <div className='form-label'>
                        <div className='label'>Email</div>
                        <ErrorBlock field="email"
                            className="error"
                            error={errors} />
                    </div>
                    <input type="text"
                        readOnly={isEdit}
                        disabled={isEdit}
                        className="textbox"
                        placeholder='Enter Email'
                        name="email"
                        value={email}
                        onChange={this.onTextChange}
                    />
                </div>

                {!isEdit &&
                    <div className='form-field'>
                        <div className='form-label'>
                            <div className='label'>Password</div>
                            <ErrorBlock field="password"
                                className="error"
                                error={errors} />
                        </div>
                        <input type="password"
                            className="textbox"
                            placeholder='Enter Password'
                            name="password"
                            value={password}
                            onChange={this.onTextChange}
                        />
                    </div>
                }

                {this.renderActions()}
            </form>
        )
    }

    renderActions = () => {
        const { isEdit, onCancle } = this.props
        const { deleting, loading } = this.state

        let deleteBtn = isEdit && (
            deleting ?
                <div className='btn disable-btn'>Loading...</div>
                :
                <div className='btn red-btn' onClick={this.handleDelete}>
                    Delete Client
				</div>
        )

        let submitBtn = !isEdit && (
            loading ?
                <div className='btn disable-btn'>Loading...</div>
                :
                <div className='btn yellow-btn' onClick={this.onFormSubmit}>
                    Add Client
                </div>
        )

        return (
            <div className='form-actions multi-action'>
                <div className="left">
                    {deleteBtn}
                </div>
                <div className="right">
                    <div className='btn secondary-dark-btn' onClick={onCancle}>Cancel</div>
                    {submitBtn}
                </div>
                <div className="clearfix" />
            </div>
        )
    }
}


export default connect(null)(ClientForm)