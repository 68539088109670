import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import debounce from 'lodash/debounce'

import {
	pageSizeReducer,
	notificationReducer
} from "./others/data/appState.reducers"

import { reportsReducer } from './reports/data/reports.reducer'

import { authReducer } from "./others/data/auth.reducer"
import { schedulePageReducer } from './trip/data/schedulePage.reducer'

const config = {
	key: 'wkb$+o=h59zb-7@bjcp1v*j=v^$ou_w7l2k7z3*!18d70jl=fj',
	whitelist: ['auth'],
	storage,
}

const reducers = {
	auth				: authReducer,
	pageSize			: pageSizeReducer,
	notifications 		: notificationReducer,
	reports 			: reportsReducer,

	schedulePage		: schedulePageReducer,
}

const combinedReducers = persistCombineReducers(config, reducers);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combinedReducers, composeEnhancer(applyMiddleware(thunk)));

export default store;

// update window size state when window resize event fires
const handleWindowResize = debounce(() => {
	store.dispatch({
		type: 'WINDOW_SIZE_CHANGED'
	})
}, 300, { trailing: true });

window.addEventListener('resize', handleWindowResize);