import React from 'react'
import {useSelector} from 'react-redux'

import ChangePassword from '../components/ChangePassword'

import { useActions } from "../../others/redux_utils"
import Urls from '../../UrlConfig'



export default ({ history }) => {
    const email = useSelector(store => store.auth.user.email)

    const logoutUser = useActions(() => ({ type: 'USER_LOGOUT' }))
	// logout user and redirect to login page
	const logoutRedirect = () => {
		logoutUser()
		history.push(Urls.getAuthPage('login'))
	}

    return (
        <div id='profile-page' className='page-container'>

            <div className='page-title'>Profile</div>

            <div className='page-content flex-wrapper'>
                <div className='flex-md secondary-bg scrollable relative'>
                    <div className='profile-section z-depth-2'>

                        <div className="details">
                            <div className="label">Email</div>
                            <div className="value">{email}</div>
                        </div>

                        <div className="separator"/>

                        <ChangePassword />

                        <div className="separator"/>

                        <div className="details">
                            <div className="label">Account</div>
                            <div className="value">
                                <div className="orange-btn logout" onClick={logoutRedirect}>Logout</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
