import React, { Component } from "react";
import { connect } from "react-redux";
import { DateTimeRangePickerInput } from "react-datetime-range-super-picker";
import { useQuery } from "@apollo/react-hooks";

import { format } from "date-fns";
import { get } from "lodash";

import Loader from "../../others/components/Loader";
import LoadingButton from "../../others/components/LoadingButton";
import Checkbox from "../../others/components/Checkbox";

import TripReport from "../components/TripReport";
import VehicleRefuleReport from "../components/VehicleRefuleReport";
import ExpenseReport from "../components/ExpenseReport";

import { addNotification } from "../../others/data/appState.actions";
import {
  getExpenseData,
  getTripData,
  getVehicleRefuleData,
  updateFormField,
} from "../data/reports.action";
import {
  fetchBlobData,
  toggleItemSelect,
  formatSubmitDate,
} from "../../others/utils";
import { GET_REPORT_CHOICES } from "../data/reports.graphql";
import Urls from "../../UrlConfig";

export default () => {
  /**
   * work as data component
   * - manage gql loading
   * - get report data from reducer
   *
   * Parent :
   *      App
   *
   * Renders:
   *      ReportDataPage
   */

  const { loading, data, error } = useQuery(GET_REPORT_CHOICES, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className="page-container">
      <div className="page-title">Reports</div>

      <div className="page-content flex-wrapper">
        <div className="flex-md secondary-bg scrollable relative">
          {loading ? (
            <div className="loading-overlay">
              <Loader />
            </div>
          ) : error ? (
            <div className="loading-overlay">
              <h2>Failed to fetch data</h2>
            </div>
          ) : (
            <ReportDataPageConnect data={data} />
          )}
        </div>
      </div>
    </div>
  );
};

class ReportDataPage extends Component {
  /**
   *
   * manage api hit according to input form data
   *
   * Parent:
   *      ReportPage
   *
   * Renders:
   *      input form
   *      ExpenseReport, report_type === 1
   *      TripReport, report_type === 2
   *      VehicleRefuleReport, report_type === 3
   */

  getReportData = () => {
    const {
      from_date,
      to_date,
      report_type,
      selectedVehicle,
      is_schedule_program,
    } = this.props;

    // get dates from state and format here
    if (!report_type) {
      this.props.addNotification({
        type: "error",
        icon: "fa-ban",
        title: "Required !!",
        text: "Please Select Report Type.",
      });
      return;
    }

    let data = {
      start_date: formatSubmitDate(from_date),
      end_date: formatSubmitDate(to_date),
    };

    if (report_type === 2) {
      data["is_schedule_program"] = is_schedule_program;
      data["vehicle_ids"] = [...selectedVehicle];
      this.props.getTripData(data);
    } else if (report_type === 3) {
      this.props.getVehicleRefuleData(data);
    } else if (report_type === 1) {
      data["report_type"] = "vehicle";
      data["vehicle_ids"] = [...selectedVehicle];
      this.props.getExpenseData(data);
    }
  };

  downloadExcelReport = () => {
    const { from_date, to_date, report_type, selectedVehicle } = this.props;
    const isTripReport = report_type === 2;

    // get dates from state and format here
    if (!report_type) {
      this.props.addNotification({
        type: "error",
        icon: "fa-ban",
        title: "Required !!",
        text: "Please Select Report Type.",
      });
      return;
    }

    if (isTripReport) {
      this.onDownloadTripExcel();
    } else {
      const data = {
        start_date: formatSubmitDate(from_date),
        end_date: formatSubmitDate(to_date),
        report_type: "vehicle",
        vehicle_ids: [...selectedVehicle],
      };
      const report_name = `${format(from_date, "dd-MMM-yy")}-TO-${format(
        to_date,
        "dd-MMM-yy"
      )}`;
      this.downloadExpenseExcel(data, report_name);
    }
  };

  downloadExpenseExcel = (data, report_name) => {
    this.props.updateFormField({ loadingXlsx: true });
    fetchBlobData(
      Urls.apiDailyExpenseExcel(),
      "post",
      data,
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report_name}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.props.updateFormField({ loadingXlsx: false });
      },
      (err) => {
        this.props.updateFormField({ loadingXlsx: false });
      }
    );
  };

  onDownloadTripExcel = () => {
    const { from_date, to_date, is_schedule_program, selectedVehicle } =
      this.props;
    const data = {
      start_date: formatSubmitDate(from_date),
      end_date: formatSubmitDate(to_date),
      file_type: "xlsx",
      is_schedule_program,
      vehicle_ids: [...selectedVehicle],
    };
    const report_name = `${format(from_date, "dd-MMM-yy")}-TO-${format(
      to_date,
      "dd-MMM-yy"
    )}`;

    this.props.updateFormField({ loadingXlsx: true });
    fetchBlobData(
      Urls.apiTripReport(),
      "post",
      data,
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report_name}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.props.updateFormField({ loadingXlsx: false });
      },
      (err) => {
        this.props.updateFormField({ loadingXlsx: false });
      }
    );
  };

  downloadRefulePdf = () => {
    const { from_date, to_date, report_type } = this.props;

    // get dates from state and format here
    if (!report_type) {
      this.props.addNotification({
        type: "error",
        icon: "fa-ban",
        title: "Required !!",
        text: "Please Select Report Type.",
      });
      return;
    }

    const data = {
      start_date: formatSubmitDate(from_date),
      end_date: formatSubmitDate(to_date),
      file_type: "pdf",
    };
    const report_name = `${format(from_date, "dd-MMM-yy")}-TO-${format(
      to_date,
      "dd-MMM-yy"
    )}`;

    this.props.updateFormField({ loadingXlsx: true });
    fetchBlobData(
      Urls.apiVehicleRefuelReport(),
      "post",
      data,
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report_name}.pdf`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.props.updateFormField({ loadingXlsx: false });
      },
      (err) => {
        this.props.updateFormField({ loadingXlsx: false });
      }
    );
  };

  handleFromDateUpdate = ({ date }) => {
    this.props.updateFormField({ from_date: date.date });
  };

  handleToDateUpdate = ({ date }) => {
    this.props.updateFormField({ to_date: date.date });
  };

  handleCheckboxClick = (value) => (event) => {
    let newValue = null;
    if (this.props.report_type !== value) {
      newValue = value;
    }
    this.props.updateFormField({ report_type: newValue });
  };

  handleToggleExpenseReport = () => {
    const newValue = this.props.report_type === 1 ? null : 1;
    this.props.updateFormField({
      report_type: newValue,
      specificVehicleSelected: false,
      selectedVehicle: new Set(),
    });
  };

  handleToggleTripReport = () => {
    const newValue = this.props.report_type === 2 ? null : 2;
    this.props.updateFormField({
      report_type: newValue,
      specificVehicleSelected: !!newValue,
      selectedVehicle: new Set(),
    });
  };

  handleToggleProgramReport = () => {
    const { is_schedule_program } = this.props;
    this.props.updateFormField({
      is_schedule_program: !is_schedule_program,
    });
  };

  handleToggleVehicleRefuleReport = () => {
    const newValue = this.props.report_type === 3 ? null : 3;
    this.props.updateFormField({
      report_type: newValue,
      specificVehicleSelected: false,
      selectedVehicle: new Set(),
    });
  };

  handleToggleSpecificVehicle = () => {
    this.props.updateFormField({
      specificVehicleSelected: !this.props.specificVehicleSelected,
    });
  };

  handleVehicleMultiSelect = (vehicle_pk) => {
    const { selectedVehicle } = this.props;
    const newVehicle = toggleItemSelect(vehicle_pk, selectedVehicle);
    this.props.updateFormField({ selectedVehicle: newVehicle });
  };

  render = () => {
    const {
      from_date,
      to_date,
      report_type,
      loadingXlsx,
      specificVehicleSelected,
      selectedVehicle,
      is_schedule_program,
    } = this.props;

    const vehicle_list = get(this.props, "data.vehicles.edges", []);

    const isExpenseReport = report_type === 1;
    const isTripReport = report_type === 2;
    const isVehicleRefuleReport = report_type === 3;

    return (
      <div id="report-container" className="report-container">
        <div className="page-details-wrapper">
          <div className="report-form-wrapper z-depth-1">
            <div className="row">
              <div className="col s6 offset-s3">
                <div className="checkbox-group">
                  <div className="form-field">
                    <Checkbox
                      checked={isExpenseReport}
                      label="Expense Report"
                      onClick={this.handleToggleExpenseReport}
                    />
                    {isExpenseReport && (
                      <Checkbox
                        checked={specificVehicleSelected}
                        label="By Specific Vehicle"
                        onClick={this.handleToggleSpecificVehicle}
                      />
                    )}
                  </div>
                  <div className="form-field">
                    <Checkbox
                      checked={isTripReport}
                      label="Trip Report"
                      onClick={this.handleToggleTripReport}
                    />
                    {isTripReport && (
                      <Checkbox
                        checked={is_schedule_program}
                        label="Schedule Program"
                        onClick={this.handleToggleProgramReport}
                      />
                    )}
                  </div>
                  <div className="form-field">
                    <Checkbox
                      checked={isVehicleRefuleReport}
                      label="Refule Report"
                      onClick={this.handleToggleVehicleRefuleReport}
                    />
                  </div>
                </div>

                <div className="selection-wrapper">
                  {specificVehicleSelected && (
                    <div className="multi-select-wrapper">
                      {vehicle_list.map((vehicle) => {
                        const vehicle_pk = vehicle.node.pk;
                        const active = selectedVehicle.has(vehicle_pk);

                        return (
                          <div
                            key={vehicle_pk}
                            className={`action-btn ${
                              active ? "yellow-btn" : "disable-btn"
                            }`}
                            onClick={this.handleVehicleMultiSelect.bind(
                              this,
                              vehicle_pk
                            )}
                          >
                            {vehicle.node.registration_no}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="form-field">
                  <div className="label">Select Date</div>
                  <DateTimeRangePickerInput
                    from_date={from_date}
                    to_date={to_date}
                    onFromDateTimeUpdate={this.handleFromDateUpdate}
                    onToDateTimeUpdate={this.handleToDateUpdate}
                    theme="dark"
                    className="range-picker"
                    popupClassName="range-picker-popup"
                  />
                </div>
              </div>
            </div>

            <div className="action-wrapper">
              <LoadingButton
                className="action-btn orange-btn left"
                loadingClass="action-btn disable-btn left"
                onClick={this.getReportData}
                label="Submit"
                loading={false}
              />
              {report_type === 3 ? (
                <LoadingButton
                  className="action-btn primary-btn right"
                  loadingClass="action-btn disable-btn right"
                  onClick={this.downloadRefulePdf}
                  label="Download PDF"
                  loading={loadingXlsx}
                />
              ) : (
                <LoadingButton
                  className="action-btn primary-btn right"
                  loadingClass="action-btn disable-btn right"
                  onClick={this.downloadExcelReport}
                  label="Download XLSX"
                  loading={loadingXlsx}
                />
              )}
            </div>
          </div>

          {this.renderReport()}
        </div>
      </div>
    );
  };

  renderReport = () => {
    const { fetched_report_type, fetching, fetched, error } = this.props;

    const isExpenseReport = fetched_report_type === 1;
    const isTripReport = fetched_report_type === 2;
    const isVehicleRefuleReport = fetched_report_type === 3;
    const expensesTypeList = get(
      this.props,
      "data.expenses_type_list.edges",
      []
    );

    if (fetched) {
      if (isExpenseReport) {
        return <ExpenseReport expensesTypeList={expensesTypeList} />;
      } else if (isTripReport) {
        return <TripReport />;
      } else if (isVehicleRefuleReport) {
        return <VehicleRefuleReport />;
      }
    } else if (error) {
      return (
        <div className="report-data-container z-depth-1">
          <div className="reports-chart-container error-container">
            <h2>Failed to fetch data</h2>
          </div>
        </div>
      );
    } else if (fetching) {
      return <Loader />;
    }
  };
}

const ReportDataPageConnect = connect(
  (store) => ({
    from_date: store.reports.from_date,
    to_date: store.reports.to_date,
    report_type: store.reports.report_type,
    fetched_report_type: store.reports.fetched_report_type,
    is_schedule_program: store.reports.is_schedule_program,
    specificVehicleSelected: store.reports.specificVehicleSelected,
    selectedVehicle: store.reports.selectedVehicle,
    loadingXlsx: store.reports.loadingXlsx,
    fetching: store.reports.fetching,
    fetched: store.reports.fetched,
    error: store.reports.error,
  }),
  {
    getExpenseData,
    getTripData,
    addNotification,
    updateFormField,
    getVehicleRefuleData,
  }
)(ReportDataPage);
