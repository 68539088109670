import gql from 'graphql-tag';

import { startOfToday, endOfToday } from 'date-fns'

const startIsoStr = startOfToday().toISOString()
const endIsoStr = endOfToday().toISOString()

/**
 * Pass default date string in gql query
 */
export const GET_TRIP_LIST = gql`
query trips(
	$after: String, $before: String, $first: Int, $last: Int,
	$toDestination: String, $fromDestination: String,
	$startTime: DateTime = "${startIsoStr}", 
	$endTime: DateTime = "${endIsoStr}"
) {
	trips(
		after: $after, before : $before, first: $first, last : $last,
		to_destination__icontains: $toDestination, 
		from_destination__icontains: $fromDestination,
		start_time__gte: $startTime,
		end_time__lte: $endTime,
	) {
		pageInfo {hasNextPage hasPreviousPage startCursor endCursor}
		edges {
			cursor
			node {
				pk status is_postponed
				from_destination to_destination
				driver { pk name }
				vehicle { pk name model_no }
				start_time end_time
			}
		} 
	}
}
`

export const GET_TRIP_DETAILS = gql`
query trip_details( $pk: Int ) {
	trip_details( pk: $pk ) {
		pk status
		from_destination to_destination
		driver { pk name }
		vehicle { pk name model_no }
		start_time end_time
		start_km_reading material_description
		end_km_reading remarks
		actual_start_time actual_end_time
		total_km challan_number status is_postponed
	}
}
`