import React, { Component } from 'react'
import { connect } from 'react-redux';

import { sumBy, meanBy, isEmpty, isNumber, round } from 'lodash'

import { formatDateTime } from '../../others/utils'

class VehicleRefuleReport extends Component {
  /**
   * Parent:
   *      ReportPage
   */

  render = () => {
    const { vehicleRefules } = this.props

    if (isEmpty(vehicleRefules)) {
      return (
        <div className="report-data-container z-depth-1">
          <div className="reports-no-data">
            No Data
          </div>
        </div>
      )
    }

    let content = []
    for (const vehicle in vehicleRefules) {
      if (Object.hasOwnProperty.call(vehicleRefules, vehicle)) {
        const currVehicleData = vehicleRefules[vehicle];

        content.push(
          <div key={vehicle}>
            <div className="vehicle-refule-heading">Refuels For {vehicle}</div>
            <div className="vehicle-refule-table" key={vehicle}>
              <div className="vehicle-refule-row header">
                <div className="col">Time</div>
                <div className="col">Trip count</div>
                <div className="col">Fuel ( in Litres )</div>
                <div className="col">Cost</div>
                <div className="col">km Traveled</div>
                <div className="col">avg</div>
              </div>
              {currVehicleData.list.map((vehicleData, index) => (
                <div key={index} className="vehicle-refule-row contect">
                  <div className="col">{formatDateTime(vehicleData.date)}</div>
                  <div className="col">{vehicleData.trip_count}</div>
                  <div className="col">{vehicleData.refuel_quantity}</div>
                  <div className="col">{vehicleData.cost}</div>
                  <div className="col">{vehicleData.km_traveled}</div>
                  <div className="col">{vehicleData.avg_estimate}</div>
                </div>
              ))}
              <div className="vehicle-refule-row footer">
                <div className="col">Sum for {vehicle}</div>
                <div className="col">{currVehicleData.total_trip_count}</div>
                <div className="col">{currVehicleData.total_refuel_quantity}</div>
                <div className="col">{currVehicleData.total_cost}</div>
                <div className="col">{currVehicleData.total_km_traveled}</div>
                <div className="col">{currVehicleData.total_avg_estimate}</div>
              </div>
            </div>
          </div>
        )
      }
    }
    return (
      <div className="report-data-container z-depth-1">
        {content}
      </div>
    )
  }
}

export default connect(store => ({
  vehicleRefules: store.reports.vehicleRefules,
}))(VehicleRefuleReport)