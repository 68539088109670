import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import { format } from "date-fns";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

const dateFormatter = (param) => {
  if (param.value) {
    return format(new Date(param.value), "dd-MMM-yy");
  } else {
    return "Total";
  }
};

export default class ReportAGTable extends Component {
  constructor(props) {
    super(props);

    let footerData = {};
    // convert list of obj to single obj
    // [{e_type, amount}, ...] => {[e_type]: amount, ... }
    for (let index = 0; index < this.props.expense_by_type.length; index++) {
      const currData = this.props.expense_by_type[index];
      footerData[currData.e_type] = currData.amount;
    }

    this.state = {
      columnList: [],
      footerData: [footerData], // for footer data list required
    };

    this.columnDefs = [];

    this.columnDefs.push({
      headerName: "Time",
      field: "date",
      valueFormatter: dateFormatter,
    });

    for (let index = 0; index < this.props.expensesTypeList.length; index++) {
      const expensesTypeNode = this.props.expensesTypeList[index];
      this.columnDefs.push({
        headerName: expensesTypeNode.node.name,
        field: expensesTypeNode.node.name,
        pinnedRowCellRenderer: "customPinnedRowRenderer",
      });
    }

    this.columnDefs.push({
      headerName: "Total",
      valueGetter: getTotal,
      cellStyle: {
        fontWeight: "bold",
        fontSize: "1.1em",
        padding: "4px 10px",
      },
    });

    this.columnDefs.push({
      headerName: "DIESEL IN LTR",
      field: "refuel_q",
      pinnedRowCellRenderer: "customPinnedRowRenderer",
    });

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      autoHeight: true,
      cellClass: "fw-column-bg",
      cellStyle: { padding: "4px 10px" },
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // auto fit column to container
    this.gridApi.sizeColumnsToFit();
    this.setState({
      columnList: params.columnApi.getAllColumns(),
    });
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  handleColumnDisplay = (column) => {
    this.gridColumnApi.setColumnVisible(column, !column.visible);
    // auto fit column to container
    this.gridApi.sizeColumnsToFit();

    this.setState({
      columnList: this.gridColumnApi.getAllColumns(),
    });
  };

  render = () => {
    const { data } = this.props;
    const { columnList, footerData } = this.state;

    return (
      <div className="report-table">
        <div className="heading">Data Table</div>

        <div className="report-table-column-btns">
          <div className="label">Arrange Columns</div>
          <div className="action-wrapper">
            {columnList.map((column, index) => {
              return (
                <div
                  key={String(column.colDef.field)}
                  className={`action-btn ${
                    column.visible ? "yellow-btn" : "disable-btn"
                  }`}
                  onClick={this.handleColumnDisplay.bind(this, column)}
                >
                  {column.colDef.headerName}
                </div>
              );
            })}
          </div>
        </div>

        <div className={`ag-theme-material report-ag-grid-style`}>
          <AgGridReact
            key="ReportAGTable"
            columnDefs={this.columnDefs}
            rowData={data}
            defaultColDef={this.defaultColDef}
            headerHeight={40}
            animateRows={true}
            onGridReady={this.onGridReady}
            onFirstDataRendered={this.onFirstDataRendered}
            pinnedBottomRowData={footerData}
            frameworkComponents={{
              customPinnedRowRenderer: CustomPinnedRowRenderer,
            }}
            domLayout="autoHeight"
          />
        </div>
      </div>
    );
  };
}

const getTotal = (param) => {
  let newData = { ...param.data };
  // first remove keys which not include to sum
  delete newData["date"];
  delete newData["refuel_q"];

  // total  of {e_type: amount, ...}
  const sumValues = Object.values(newData).reduce(
    (prev, curr) => prev + curr,
    0
  );

  return sumValues;
};

/**
 * render footer
 */
class CustomPinnedRowRenderer extends Component {
  render = () => {
    return (
      <span style={{ fontSize: "1.1em", fontWeight: "bold" }}>
        {this.props.value}
      </span>
    );
  };
}
