import React from 'react'

import {Vehicle} from '../SvgIcons'


export default () => {

	/**
	 * Parent
	 * 		Layout
	 */

	return (
		<div className='watermark vector absolute'>
			<Vehicle />
		</div>
	)
}
