import React from 'react';
import { Link } from 'react-router-dom'

import { get } from 'lodash'

import ListLoader from '../../others/components/ListLoader';

import { formatDateTime } from '../../others/utils';
import { getStatusName } from '../../reports/data/reports.utils';
import Urls from '../../UrlConfig'


export default ({ data, count, loading, error }) => {

    /**
     * Parent:
     * 		TripListWrapperPage
     */

    let table;

    if (loading) {
        table = <ListLoader row={count} col={5} />
    }
    else if (error) {
        table = <div className="list-empty-wrapper error">Failed to fetch data!!</div>
    }
    else {
        if (data.length) {
            table = data.map((edge) => {
                const { cursor, node } = edge

                return (
                    <Link to={{ 
                      pathname: Urls.getTripDetails(node.pk), state: { details: node } 
                    }} className='list-row' key={cursor}>
                        <div className='list-col'>
                            {node.from_destination}<br />
                                To<br />
                            {node.to_destination}
                        </div>
                        <div className='list-col'>
                            {formatDateTime(node.start_time, 'do MMM hh:mm a')}<br />
                                To<br />
                            {formatDateTime(node.end_time, 'do MMM hh:mm a')}<br />
                        </div>
                        <div className='list-col'>{getStatusName(node.status, node.is_postponed)}</div>
                        <div className='list-col'>{node.driver.name}</div>
                        <div className='list-col'>{get(node, 'vehicle.name', '-NA-')}</div>
                    </Link>
                )
            })
        }
        else {
            table = <div className="list-empty-wrapper">No Trips Found</div>
        }
    }

    return (
        <div className='list-container'>
            <div className='list-row'>
                <div className='list-col'>Trip</div>
                <div className='list-col'>Time</div>
                <div className='list-col'>Status</div>
                <div className='list-col'>Driver</div>
                <div className='list-col'>Vehicle</div>
            </div>

            {table}
        </div>
    )
}