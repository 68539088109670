import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";

import { get } from "lodash";

const useListWithPaginationSearch = (GQL_LIST_QUERY, dataKey) => {
  const [rowCount, setCount] = useState(
    Number(process.env.REACT_APP_PAGE_COUNT)
  );
  const [pageNo, setPageNo] = useState(1);

  const { loading, data, error, variables, refetch } = useQuery(
    GQL_LIST_QUERY,
    { variables: { first: rowCount }, fetchPolicy: "cache-and-network" }
  );
  const { pageInfo } = get(data, dataKey, {});

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  useEffect(() => {
    if (!loading) {
      setHasNextPage(Boolean(variables.first) ? pageInfo.hasNextPage : true);
      setHasPrevPage(
        Boolean(variables.last)
          ? pageInfo.hasPreviousPage
          : Boolean(variables.after)
      );
    }
  }, [loading, pageInfo, variables]);

  const changeRowCount = (newCount) => {
    setCount(newCount);
    setPageNo(1);
    const newVariables = { ...variables };
    if (newVariables.last) {
      newVariables.last = Number(newCount);
    } else {
      newVariables.first = Number(newCount);
    }
    refetch(newVariables);
  };

  const fetchNextData = () => {
    if (hasNextPage) {
      refetch({
        after: pageInfo.endCursor,
        first: Number(rowCount),
        before: undefined,
        last: undefined,
      });
      setPageNo((currCount) => currCount + 1);
    }
  };

  const fetchPrevData = () => {
    if (hasPrevPage) {
      refetch({
        before: pageInfo.startCursor,
        last: rowCount,
        after: undefined,
        first: undefined,
      });
      setPageNo((currCount) => currCount - 1);
    }
  };

  /**
   * Dynamic search implementation
   * @param {Json} searchData : [searchKey] : searchToken
   */
  const handleSearch = (searchData) => {
    refetch({
      before: undefined,
      last: undefined,
      after: undefined,
      first: Number(rowCount),
      ...searchData,
    });
    setPageNo(1);
  };

  return {
    loading,
    data,
    error, // api interaction data
    // pagination params
    hasNextPage,
    hasPrevPage,
    fetchNextData,
    fetchPrevData,
    pageNo,
    rowCount,
    changeRowCount,
    handleSearch,
  };
};

export default useListWithPaginationSearch;
