import React, { Component } from "react";

import { bisector } from "../../data/chart.utils"

import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { format } from "date-fns";

// import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

export default class InteractiveGraphContainer extends Component {

    /**
     * Parent
     *      AreaChart
     *      ScatterPlot
     *      BarChart
     */

    static defaultProps = {
        x_label : 'ts',
        y_label : 'value'
    }

    constructor(props) {
        super(props)

        this.bisectX = bisector(function (d) {
            return d[get(props, 'x_label', 'ts')];
        }).left

        this.state = {
            trackPointer: false,
            // indicate mouse hover events according to x, y
            chart_x: '',
            chart_y: '',
            // dragging states
            dragging : false,
            /// drag rect x, starting point of drag, y is always 0
            drag_x: '',
            // variable mouse move point
            drag_var: '',
            // drag rect width = abs(x1 - x2) , height is always full
        }
        // this.dbsetState = debounce(this.setState,50)
    }

    screenToChart = (clientX, clientY) => {
        const { margin } = this.props,
            $svg = this.refs.svg

        const { left, top } = $svg.getBoundingClientRect(),
            chart_x = clientX - left - $svg.clientLeft - margin.left,
            chart_y = clientY - top - $svg.clientTop - margin.top;

        return {chart_x, chart_y}
    }

    handleMouseMove = ({ clientX, clientY}) => {
        if(this.state.trackPointer) {
            const { chart_x, chart_y } = this.screenToChart(clientX, clientY)
            // handle drag events
            if(this.state.dragging) this.setState({drag_var : chart_x});
            // handle mouse over events
            this.setState({chart_x, chart_y})
        }
    }

    handleMouseLeave = (event) => {
        if(this.state.dragging) {
            this.dragEnd(event)
        }
        this.setState({ trackPointer: false })
    }

    dragStart = ({clientX, clientY}) => {
        const { chart_x } = this.screenToChart(clientX, clientY),
            {height} = this.props;

        this.setState({
            dragging : true,
            drag_x : chart_x, drag_y: 0,
            drag_var : chart_x + 1, drag_h : height
        })
    }

    dragEnd = ({clientX, clientY}) => {
        const { drag_x } = this.state,
            { chart_x } = this.screenToChart(clientX, clientY),
            {xScale, x_type, data, x_label} = this.props;
        
        let [x_lo, x_hi] = drag_x < chart_x ? [drag_x, chart_x] : [chart_x, drag_x]
        // get on scale values
        if(x_type === 'band') {
            const domain = xScale.domain(),
                paddingOuter = xScale(domain[0]),
                eachBand = xScale.step()

            x_lo = Math.floor(((x_lo - paddingOuter) / eachBand))
            x_lo = Math.max(0, Math.min(x_lo, domain.length - 1))

            x_hi = Math.floor(((x_hi - paddingOuter) / eachBand))
            x_hi = Math.max(0, Math.min(x_hi, domain.length - 1))

            this.props.handleZoom({x_lo, x_hi, zoom_type:'band'})

        } else {
            x_lo = xScale.invert(x_lo)
            x_hi = xScale.invert(x_hi)
            // pass values to the parent
            this.props.handleZoom({x_lo, x_hi})
        }
        // remove select rectangle
        this.setState({dragging: false})
    }

    render = () => {
        const {width, height, margin} = this.props,
            {drag_x, drag_var} = this.state,
            rect_x = (drag_x < drag_var) ? drag_x : drag_var,
            rect_width = Math.abs(drag_x - drag_var);

        return (
            <svg ref="svg" width={width + margin.left + margin.right}
                height={height + margin.top + margin.bottom} 
                viewBox={`0 0 ${ width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
                onMouseEnter={() => this.setState({ trackPointer: true }) }
                onMouseLeave={this.handleMouseLeave}
                onMouseMove={this.handleMouseMove}
                onMouseDown={this.dragStart}
                onMouseUp={this.dragEnd}
            >

                <g ref='chart' transform={`translate(${margin.left}, ${margin.top})`}>
                    {this.props.children}

                    {this.renderInteractionPopUp()}

                    {this.state.dragging &&
                        <rect className='select' stroke="#f96159" fill="none"
                            x={rect_x} y={0} 
                            width={rect_width} height={height} />
                    }
                </g>
            </svg> 
        )
    }

    /*///////////////////////////////////////////////////////
    //		    Mouse Over data point calculations         //
    ///////////////////////////////////////////////////////*/
    // get closest data point from mouse pointer x
    // get chart x, y of that data point
    // show tracer point on that data point

    getDFinal = () => {
        if(this.props.x_type === 'band') {
            return this.calcBandClosestData
        } else {
            return this.calcInvertedClosestData
        }
    }

    calcInvertedClosestData = (chart_x) => {
        const { xScale, data, x_label } = this.props,
            // get actual value of x scale at pointer pos
            x_val = xScale.invert(chart_x),
            // index of data on the right side of current mouse pos
            // 1 in last pos tells bisector to start search from pos 1 and not 0
            // that is why d_low will not go out of index
            index = this.bisectX(data, x_val, 1),
            d_low = data[index - 1],
            d_high = data[index],
            // find out if d_low or d_high is the closest to pointer pos
            d_final = (x_val - d_low[x_label] > get(d_high, x_label, Infinity) - x_val) ? d_high : d_low

        return d_final
    }

    calcBandClosestData = (chart_x) => {
        const { xScale, data, x_label, y_label } = this.props,
            domain = xScale.domain(),
            paddingOuter = xScale(domain[0]),
            eachBand = xScale.step()

        let index = Math.floor(((chart_x - paddingOuter) / eachBand))
        index = Math.max(0, Math.min(index, domain.length - 1))
        return data[index]
    }

    renderInteractionPopUp = () => {
        if(this.state.trackPointer) {
            const { xScale, yScale, width, height, y_label, x_label, margin, x_type } = this.props,
                {chart_x, chart_y} = this.state
            let d_final = {}

            // get bisector
            if (chart_x) {
                d_final = this.getDFinal()(chart_x)
            }
            // empty check
            if(isEmpty(d_final)) return;
            let y_coord = yScale(get(d_final, y_label, chart_y)),
                x_coord = xScale(get(d_final, x_label, chart_x)),
                tracer_x_text;

            if (x_type === 'band') {
                x_coord += (xScale.bandwidth() / 2)
                tracer_x_text = d_final[x_label]
            }
            else {
                tracer_x_text = format(d_final[x_label], 'D MMM,YY HH:mm:ss')
            }
            
            return (
                <g className='tracer'>
                    <line x1={x_coord} y1={0} x2={x_coord} y2={height} />
                    <line x1={0} y1={y_coord} x2={width} y2={y_coord} />
                    <circle cx={x_coord} cy={y_coord} r="5" />

                    <g className='details' transform={`translate(${x_coord + 5},${y_coord-15})`}>
                        <text>{d_final[y_label]}</text>
                    </g>
                    {/* <g class='details' transform={`translate(${x_coord}, ${height-5})`}>
                        <text>{tracer_x_text}</text>
                    </g>*/}
                </g>
            )
        }
    }
}

InteractiveGraphContainer.propTypes = {
    // reverse mouse position to scale x and y
    xScale: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    // handle data tracer according to mouse movements
    x_label : PropTypes.string,
    y_label: PropTypes.string,
    data : PropTypes.array,
    // setup graph dimensions
    margin: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    // handle when user selects an area for zoom
    // takes 2 arguments :
    // x_lo - lower value of invert scale
    // x_hi - higher value of invert scale
    handleZoom : PropTypes.func,
    // if type of x scale is different mouse events will need different calcs
    x_type : PropTypes.string,
}