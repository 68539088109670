import React from 'react'

export default ({ checked=false, label, onClick }) => {
    return (
        <div className={`checkbox-container ${checked ? "active" : ""}`} onClick={onClick}>
            <div className="check">
                {checked ?
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                    :
                    <i className="fa fa-square-o" aria-hidden="true"></i>
                }
            </div>
            <div className="checkbox-label">{label}</div>
        </div>
    )
}
