import { transformExpenseData } from './reports.utils'
import { startOfToday, endOfToday } from 'date-fns'

const INIT_EXPENSE = {
    // report form fields
    from_date: startOfToday(),
    to_date: endOfToday(),
    // 1: vehicle expense report, 2: trips report 3: refule report
    report_type: null,
    specificVehicleSelected: false,
    // for trip report
    is_schedule_program: false,
    selectedVehicle: new Set(),
    // report data fields
    loadingXlsx: false,
    fetching: false,
    fetched: false,
    error: false,
    errorMsg: {},
    // expense data
    expense_full_data: [],
    expense_by_type: [],
    expense_by_day: [],
    // trip data
    tripData: [],
    // vehicle refule
    vehicleRefules: null,
    // to show fetched report data
    fetched_report_type: null
}


export const reportsReducer = (state = INIT_EXPENSE, { type, payload }) => {
    switch (type) {

        case 'REPORT_DATA_FETCHING': {
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: false,
                errorMsg: {},
            }
        }

        case 'EXPENSE_FETCH_DATA_SUCCESS': {
            const { expense_by_day, expense_by_type,
                expense_full_data } = payload

            return {
                ...state,
                fetching: false,
                fetched: true,
                expense_full_data: transformExpenseData(expense_full_data),
                expense_by_type,
                expense_by_day,
                fetched_report_type: 1
            }
        }

        case 'TRIP_DATA_FETCH_SUCCESS': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                tripData: payload,
                fetched_report_type: 2
            }
        }

        case 'VEHICLE_REFULE_DATA_FETCH_SUCCESS': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                vehicleRefules: payload,
                fetched_report_type: 3
            }
        }

        case 'REPORT_DATA_FETCH_ERROR': {
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: true,
                errorMsg: payload.error_data
            }
        }

        case "UPDATE_REPORT_FORM_FIELDS": {
            return { ...state, ...payload }
        }

        default: {
            return state
        }
    }
}
