import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux';

import ClientListWrapperPage from "./pages/ClientListWrapperPage"
import ClientFormPage from "./pages/ClientFormPage"

import PageNotFound from "../others/pages/PageNotFound"

import Urls from "../UrlConfig"

export default () => {

	/**
	 * Parent
	 * 		App
	 */

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	if(!isSuperuser) {
		return <Redirect to={Urls.getUnauthorized()}/>
	}

	return (
		<Switch>
			<Route exact path={Urls.getClientPage()} component={ClientListWrapperPage} />
			<Route path={Urls.getClientFormPage()} component={ClientFormPage} />
			<Route path="*" component={PageNotFound} />
		</Switch>
	)
}
