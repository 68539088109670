import React, { useState } from 'react';

export default ({handleSearch}) => {

	/**
	 * Parent:
	 * 		TripListWrapperPage
	 */

	const [toDestination, setToDestination] = useState('')
	const [fromDestination, setFromDestination] = useState('')
	// 1 : From Destination, 2 : To Destination
	const [currTab, setCurrTab] = useState(1)

	
	const clearFromDest = () => {
		setFromDestination('')
		handleSearch({fromDestination: ''})
	}

	const clearToDest = () => {
		setToDestination('')
		handleSearch({toDestination: ''})
	}

	return (
		<div className='search-input-container'>
			<div className='tab-selector-wrapper'>
				<div className={`tab-selector ${currTab === 1 ? 'active' : ''}`} 
					onClick={() => setCurrTab(1)}>
					From Destination
				</div>
				<div className={`tab-selector left-selector ${currTab === 2 ? 'active' : ''}`}
					onClick={() => setCurrTab(2)} >
					To Destination
				</div>
			</div>

			{currTab === 1 ?
				<>
					<input type="text" className="textbox search-input"
						placeholder='Search From Destinations.....'
						name="search" value={fromDestination}
						onChange={(e) => setFromDestination(e.target.value)} />

					<div className={`icn clear-text scale-transition ${fromDestination ? 'scale-in' : 'scale-out'}`} 
						onClick={clearFromDest}>
						<i className="fa fa-times" aria-hidden="true"></i>
					</div>
				</>
				:
				<>
					<input type="text" className="textbox search-input"
						placeholder='Search To Destinations.....'
						name="search" value={toDestination}
						onChange={(e) => setToDestination(e.target.value)} />

					<div className={`icn clear-text scale-transition ${toDestination ? 'scale-in' : 'scale-out'}`} 
						onClick={clearToDest}>
						<i className="fa fa-times" aria-hidden="true"></i>
					</div>
				</>
				
			}

			<div className='icn magnifier scale-transition' 
				onClick={() => handleSearch({fromDestination, toDestination})}>
					<i className="fa fa-search" aria-hidden="true"></i>
			</div>
		</div>
	)
}