import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { get } from 'lodash'

import { TRIP_STATUS } from '../../trip/data/trip.utils'
import { formatDateTime } from '../../others/utils'
import Urls from '../../UrlConfig'


export default class VehicleTripList extends Component {

	/**
	 * Parent:
	 * 		VehicleDetailsPage
	 */

	render = () => {
		const { trips } = this.props

		let table;
		if (trips.length) {
			table = trips.map((trip) => {
				return (
					<div className='list-row' key={trip.pk}>
						<div className='list-col'>
							<div>{formatDateTime(new Date(trip.start_time), 'do MMM hh:mm a')}</div>
							<div>To</div>
							<div>{formatDateTime(new Date(trip.end_time), 'do MMM hh:mm a')}</div>
						</div>
						<div className='list-col'>
							<div>{trip.from_destination}</div>
							<div>To</div>
							<div>{trip.to_destination}</div>
						</div>
						<div className='list-col status-col'>{TRIP_STATUS[trip.status]}</div>
						<div className='list-col'>{get(trip, 'driver.name', '-NA-')}</div>

						<div className='list-col action-col'>
							<Link to={Urls.getTripDetails(trip.pk)}
								className='yellow-btn action-btn' >
								<i className="fa fa-external-link" aria-hidden="true"></i>
								View
							</Link>
						</div>

					</div>
				)
			})
		}
		else {
			table = <div className="list-empty-wrapper">No Upcoming Trips Found</div>
		}

		return (
			<div className='details-trip-list list-container fix-table z-depth-1'>
				<div className='list-row header-col'>
					<div className='list-col'>Time</div>
					<div className='list-col'>Trip</div>
					<div className='list-col status-col'>Status</div>
					<div className='list-col'>Driver</div>
					<div className='list-col action-col'></div>
				</div>
				{table}
			</div>
		)
	}
}
