import React, { Component } from 'react'
import { connect } from 'react-redux'

import { 
    endOfToday, startOfToday, 
    startOfTomorrow, endOfTomorrow,
    startOfDay, endOfDay
} from "date-fns";

import { DatePicker } from 'react-datetime-range-super-picker'

import { changeScheduleFormsTime } from '../../trip/data/schedulePage.actions'

export class TimeSelector extends Component {

    /**
     * common component handle time selection
     */

    state = {
        showCustomPicker: false,
    }

    // Today Handler
    onTodaySelect = () => {
        const { currentTimeRange } = this.props
        if(currentTimeRange === 1) return

        this.props.changeTimeSelection({
            currentTimeRange: 1,
            startTime: startOfToday(),
            endTime: endOfToday()
        })
    }

    // Tomorrow Handler
    onTomorrowSelect = () => {
        const { currentTimeRange } = this.props
        if(currentTimeRange === 2) return

        this.props.changeTimeSelection({
            currentTimeRange: 2,
            startTime: startOfTomorrow(),
            endTime: endOfTomorrow()
        })
    }

    //////////////////////////////
    //   Custom picker Handler  //
    //////////////////////////////

    showCustomDatetimeModel = () => {
        this.setState({ showCustomPicker: true })
    }

    hideCustomModel = () => {
        this.setState({ showCustomPicker: false })
    }

    onFromDateTimeUpdate = ({date}) => {
        this.props.changeTimeSelection({
            currentTimeRange: 3, startTime: startOfDay(date), endTime: endOfDay(date)
        })
    }

    render = () => {
        const { currentTimeRange, startTime } = this.props
        const { showCustomPicker } = this.state

        return (
            <div className="time-selection-wrapper">
                <div className={`time-selection-tabs`}>
                    <div className={`selection-tab no-select ${currentTimeRange === 1 ? 'active' : ''}`}
                        onClick={this.onTodaySelect}>Today</div>
                    <div className={`selection-tab no-select ${currentTimeRange === 2 ? 'active' : ''}`}
                        onClick={this.onTomorrowSelect}>Tomorrow</div>
                    <div className={`selection-tab no-select ${currentTimeRange === 3 ? 'active' : ''}`}
                        onClick={this.showCustomDatetimeModel}>Custom</div>
                </div>
                {showCustomPicker &&
                    <div>
                        <div className="ts-datetime-picker">
                            <div className="ts-datetime-picker-wrapper">
                                <div className="ts-datetime-picker-close" onClick={this.hideCustomModel}>Close</div>
                                <DatePicker date={startTime}
				                    onDateUpdate={this.onFromDateTimeUpdate} />
                            </div>
                        </div>
                        <div className="overlay" onClick={this.hideCustomModel}/>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToPropsSelector = store => ({
    currentTimeRange: store.schedulePage.currentTimeRange,
    startTime: store.schedulePage.startTime,
})

const mapDispatchToPropsSelector = {
	changeTimeSelection : changeScheduleFormsTime,
}

/**
 * Parent:
 *      TripAddPage
 */
export const ScheduleTimeSelector = connect(mapStateToPropsSelector, mapDispatchToPropsSelector)(TimeSelector)