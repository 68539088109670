import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { get } from 'lodash'

import ExpenseList from '../../expense/components/ExpenseList';
import TripList from '../../trip/components/TripList'
import { TripListTimeSelector } from '../../trip/pages/TripListWrapperPage'

import { GET_EXPENSE_LIST } from '../../expense/data/expense.graphql'
import { GET_TRIP_LIST } from '../../trip/data/trip.graphql';
import useListWithPaginationSearch from '../../others/data/search.hook';

import Urls from '../../UrlConfig';

export default () => {

	/**
	 * Parent:
	 * 		App
	 * Renders
	 * 		TripList
	 * 		TripSearch
	 * 		Pagination
	 * 		DashboardDriverSearch
	 * 		DashboardVehicleSearch
	 */

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	const { loading: expenseLoading, data: expenseData,
		rowCount
	} = useListWithPaginationSearch(GET_EXPENSE_LIST, "expenses")

	const { loading: tripLoading, data: tripData,
		error, handleSearch
	} = useListWithPaginationSearch(GET_TRIP_LIST, "trips")

	const tripList = get(tripData, 'trips.edges', [])
	const expenseList = get(expenseData, 'expenses.edges', [])

	return (
		<div id='dashboard' className='page-container'>
			<div className="page-title-wrapper">
				<div className='page-title'>Dashboard</div>
			</div>

			<div className='page-content secondary-bg'>
				<div className="row">
					<div className="col s12 m6">
						<TripListTimeSelector handleSearch={handleSearch} />
					</div>
					<div className="col s12 m6" />
				</div>
				<div className="row">
					<div className="col s12 m6">
						<div className='data-sections'>
							<div className="flex-wrapper">
								<div className="list-heading">Trip List</div>
								{isSuperuser &&
									<div className="list-actions">
										<Link to={Urls.getTripAddPage()} className='yellow-btn'>
											<i className="fa fa-calendar-plus-o" aria-hidden="true"></i>Manage Schedules
										</Link>
									</div>
								}
							</div>
							<div className='list-section'>
								<TripList data={tripList}
									loading={tripLoading}
									count={rowCount}
									error={error} />
							</div>
						</div>
					</div>
					<div className="col s12 m6">
						<div className='data-sections'>
							<div className="flex-wrapper">
								<div className="list-heading">Recent Expenses</div>
								{isSuperuser &&
									<div className="list-actions">
										<Link to={Urls.getExpenseAddPage('vehicle', -1)} className='exp-btn yellow-btn'>
											<i className="fa fa-plus" aria-hidden="true"></i>Add Expense
									</Link>
									</div>
								}
							</div>
							<div className='list-section'>
								<ExpenseList data={expenseList}
									loading={expenseLoading}
									count={rowCount} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}