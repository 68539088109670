import gql from 'graphql-tag';

export const GET_DRIVERS_LIST = gql`
query drivers(
	$after: String, $before: String, $first: Int, $last: Int,
	$name: String
) {
	drivers(
		after: $after, before : $before, first: $first, last : $last,
		name__icontains: $name
	) {
		pageInfo {hasNextPage hasPreviousPage startCursor endCursor}
		edges {
			cursor
			node {
				driver {
					first_name last_name
					contact_no email
				}
				image name pk
				monthly_salary
				licence_no
				description

				vehicle {
					pk name model_no
				}
			}
		} 
	}
}
`

export const GET_DRIVER_TRIPS = gql`
query upcoming_driver_trips($driverId: Int) {
	upcoming_driver_trips(driver_id: $driverId) {
		pk status
		from_destination to_destination
		start_time end_time
		driver { pk name }
		vehicle { pk name registration_no }
	}
}
`