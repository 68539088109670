import React, { Component } from 'react';

export default class VehicleSearch extends Component {

	/**
	 * search by registrationNo or model no
	 * refetch search data
	 * 
	 * Parent
	 * 		VehicleListWrapperPage
	 */

	state = {
		registrationNo: '',
		modelNo: '',
		name: '',
		// 1 : registration No, 2 : model No, 3: Name
		currTab: 1
	}

	handleTextChange = (event) => {
		this.setState({ [event.target.name] : event.target.value })
	}

	handleTabChange = (currTab) => () => {
		this.setState({ currTab })
	}

	handleSearch = () => {
		const { name, registrationNo, modelNo } = this.state
		this.props.handleSearch({ name, registrationNo, modelNo })
	}

	clearModelNo = () => {
		this.setState({ modelNo: ''})
		this.props.handleSearch({ modelNo: '' })
	}

	clearRegNo = () => {
		this.setState({ registrationNo: ''})
		this.props.handleSearch({ registrationNo: '' })
	}

	clearName = () => {
		this.setState({ name: ''})
		this.props.handleSearch({ name: '' })
	}

	render = () => {

		const { currTab } = this.state

		return (
			<div className='search-input-container'>
				<div className='tab-selector-wrapper'>
					<div className={`tab-selector ${currTab === 1 ? 'active' : ''}`} 
						onClick={this.handleTabChange(1)}>
						Registration No
					</div>
					<div className={`tab-selector ${currTab === 2 ? 'active' : ''}`}
						onClick={this.handleTabChange(2)} >
						Model No
					</div>
					<div className={`tab-selector ${currTab === 3 ? 'active' : ''}`}
						onClick={this.handleTabChange(3)} >
						Name
					</div>
				</div>

				{this.renderTab()}

				<div className='icn magnifier scale-transition'
					onClick={this.handleSearch}>
					<i className="fa fa-search" aria-hidden="true"></i>
				</div>
			</div>
		)
	}

	renderTab = () => {
		const { currTab, registrationNo, modelNo, name } = this.state

		if(currTab === 1) {
			return (
				<>
					<input type="text" className="textbox search-input"
						placeholder='Search by Registration No.....'
						name="registrationNo" value={registrationNo}
						onChange={this.handleTextChange} />

					<div className={`icn clear-text scale-transition ${registrationNo ? 'scale-in' : 'scale-out'}`}
						onClick={this.clearRegNo}>
						<i className="fa fa-times" aria-hidden="true"></i>
					</div>
				</>
			)
		}
		else if(currTab === 2) {
			return (
				<>
					<input type="text" className="textbox search-input"
						placeholder='Search by Model No.....'
						name="modelNo" value={modelNo}
						onChange={this.handleTextChange} />

					<div className={`icn clear-text scale-transition ${modelNo ? 'scale-in' : 'scale-out'}`}
						onClick={this.clearModelNo}>
						<i className="fa fa-times" aria-hidden="true"></i>
					</div>
				</>
			)
		}
		else {
			return (
				<>
					<input type="text" className="textbox search-input"
						placeholder='Search by Name.....'
						name="name" value={name}
						onChange={this.handleTextChange} />

					<div className={`icn clear-text scale-transition ${name ? 'scale-in' : 'scale-out'}`}
						onClick={this.clearName}>
						<i className="fa fa-times" aria-hidden="true"></i>
					</div>
				</>
			)
		}
	}
}
