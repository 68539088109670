import React from "react";
import PieChart from "../charts/PieChart";
import { size, isNumber, round, orderBy } from "lodash";

import { uniq_colors } from "../data/chart.utils";

export default class PieChartWrapper extends React.Component {

	render = () => {
		const { data } = this.props
		if(size(data) < 1) {
			return (
				<div className="reports-chart-no-data">
					No data
				</div>
			)
		}

		// sort tag list
        const data_list = orderBy(data, ['amount'], ['desc'])
        // calculate total value
        let total_val = 0
        for (let index = 0; index < data_list.length; index++) {
            const curr_tag = data_list[index];
            if(isNumber(curr_tag.amount)) {
                total_val = total_val + curr_tag.amount
            }
		}
		
		const chartProps = {
			valueKey: 'amount',
			pie: false,
			data: data_list
		}

		return (
			<div className="reports-chart-wrapper">
				<div className='title'>
					{/* {section.title} */}
				</div>
				<div className="pie-content">
					<PieChart {...chartProps} />

					<div className="pie-chart-legend">
						{data_list.map((tag, id) => { // render chart legend

							let percentage_val, tagValue
							if(isNumber(tag.amount)) {
								percentage_val = round((tag.amount / total_val) * 100, 2)
								tagValue = tag.amount
							} else {
								percentage_val = tagValue = '--'
							}
							let color = uniq_colors[id]

							return (
								<div className='pie-legend' key={id}>
									<div className='pie-indicator-wrapper'>
										<div className='pie-indicator' style={{ backgroundColor: `${color}` }} />
									</div>
									<div className='pie-label'>
										<b>{`${percentage_val}%`}</b> {tag.e_type} - <b>{`(${tagValue})`}</b>
									</div>
								</div>
							)
						})}
					</div>

				</div>
			</div>
		)
	}
}