import React, { Component } from 'react'
import { useQuery } from '@apollo/react-hooks';

import Select from 'react-select'
import { DateTimePickerInput } from 'react-datetime-range-super-picker';
import { get } from 'lodash'

import Loader from '../../others/components/Loader';
import ErrorBlock from '../../others/components/ErrorBlock'

import { GET_EXPENSE_DETAILS } from '../data/expense.graphql'

import { formatSubmitDate, postFormData } from '../../others/utils'
import Urls from '../../UrlConfig'


export default (props) => {

  /**
   * general expense edit form,
   * 
   * Parent:
   * 		ExpensePage
   */

  const { loading, data, error } = useQuery(GET_EXPENSE_DETAILS,
    { variables: { expenseId: Number(props.expenseId) }, fetchPolicy: "cache-and-network" }
  )

  if (loading) {
    return (
      <div className="loading-overlay">
        <Loader />
      </div>
    )
  }
  else if (error) {
    return (
      <div className="loading-overlay">
        <h2>Failed to fetch data</h2>
      </div>
    )
  }
  return (
    <ExpenseEditForm details={data} {...props} />
  )
}

class ExpenseEditForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      timestamp: new Date(get(props, 'details.expense_details.timestamp')),
      vehicle: get(props, 'details.expense_details.vehicle.pk'),
      driver: get(props, 'details.expense_details.driver.pk'),
      expenseType: get(props, 'details.expense_details.expense_type'),
      loading: false,
      errors: {},
    }
  }

  onFormSubmit = (event) => {
    event.preventDefault()

    const { timestamp } = this.state
    let form = document.getElementById('add-expense');
    let data = new FormData(form);

    data.append('timestamp', formatSubmitDate(timestamp))

    if (!data.get('vehicle')) {
      this.setState({ errors: { vehicle: ['This field is required.'] } })
      return
    }

    this.setState({ loading: true, errors: {} })
    const expId = this.props.details.expense_details.pk
    postFormData(Urls.apiExpenseEdit(expId),
      data,
      (res) => {
        this.setState({ loading: false })
        this.props.history.goBack()
      },
      (err) => {
        this.setState({ loading: false, errors: err.error_data })
      }
    )
  }

  handleVehicleSelect = (props) => {
    let newState = { vehicle: props.node.pk }
    // check vehicle have driver or not
    const driver = get(props, 'node.driver')
    if (!!driver) {
      newState['driver'] = driver.pk
    }
    this.setState(newState)
  }

  handleDriverSelect = (props) => {
    this.setState({ driver: props.node.pk })
  }

  handleExpenseTypeSelect = (props) => {
    this.setState({ expenseType: props.node })
  }

  handleDateUpdate = (data) => {
    this.setState({ timestamp: data.date.date })
  }

  render = () => {
    const { errors, loading, timestamp,
      vehicle, driver, expenseType
    } = this.state

    const { history } = this.props
    const prime_type=  get(this.props, 'details.expense_details.prime_type')
    const show_vehicle_select = prime_type !== 'D'

    const vehicle_list = get(this.props, 'data.vehicles.edges', [])
    const driver_list = get(this.props, 'data.drivers.edges', [])
    const expenses_type_list = get(this.props, 'data.expenses_type_list.edges', [])

    return (
      <form id='add-expense' className='form-section z-depth-2'>

        {show_vehicle_select &&
          <div className='form-field'>
            <div className='form-label'>
              <div className='label'>Select Vehicle*</div>
              <ErrorBlock field="vehicle"
                className="error"
                error={errors} />
            </div>
            <Select className="select-dropdown"
              name="vehicle"
              placeholder="Select Vehicle"
              options={vehicle_list}
              getOptionLabel={props => props.node.registration_no}
              getOptionValue={props => props.node.pk}
              value={vehicle_list.find(v => v.node.pk === vehicle)}
              onChange={this.handleVehicleSelect}
            />
          </div>
        }

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Select Driver*</div>
            <ErrorBlock field="driver"
              className="error"
              error={errors} />
          </div>
          <Select className="select-dropdown"
            name="driver"
            placeholder="Select Driver"
            options={driver_list}
            getOptionLabel={props => props.node.name}
            getOptionValue={props => props.node.pk}
            value={driver_list.find(d => d.node.pk === driver)}
            onChange={this.handleDriverSelect}
          />
        </div>

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Expense Type*</div>
            <ErrorBlock field="expense_type"
              className="error"
              error={errors} />
          </div>
          <Select className="select-dropdown"
            name="expense_type"
            placeholder="Select Expense Type"
            options={expenses_type_list}
            getOptionLabel={props => props.node.name}
            getOptionValue={props => props.node.pk}
            value={expenses_type_list.find(exp => exp.node.pk === expenseType.pk)}
            onChange={this.handleExpenseTypeSelect}
          />
        </div>

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Expense Time*</div>
            <ErrorBlock field="timestamp"
              className="error"
              error={errors} />
          </div>
          <DateTimePickerInput
            date={timestamp}
            onDateTimeUpdate={this.handleDateUpdate}
            theme="dark"
            className="super-picker"
          />
        </div>

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Amount*</div>
            <ErrorBlock field="cost"
              className="error"
              error={errors} />
          </div>
          <input type="text"
            className="textbox"
            placeholder='Enter amount'
            name="cost"
            defaultValue={get(this.props, 'details.expense_details.cost')}
          />
        </div>

        {get(expenseType, 'name', '') === "DIESEL" &&
          <>
            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>Refuel Quantity</div>
                <ErrorBlock field="refuel_quantity"
                  className="error"
                  error={errors} />
              </div>
              <input type="text"
                className="textbox"
                placeholder='Enter Refuel Quantity'
                name="refuel_quantity"
                defaultValue={get(this.props, 'details.expense_details.refuel_quantity')}
              />
            </div>

            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>km reading</div>
                <ErrorBlock field="km_reading"
                  className="error"
                  error={errors} />
              </div>
              <input type="text"
                className="textbox"
                placeholder='Enter km reading'
                name="km_reading"
                defaultValue={get(this.props, 'details.expense_details.km_reading')}
              />
            </div>
          </>
        }

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Remarks</div>
            <ErrorBlock field="description"
              className="error"
              error={errors} />
          </div>
          <textarea type="text"
            className="textbox"
            placeholder='Enter Remarks'
            name="description"
            rows="3"
            defaultValue={get(this.props, 'details.expense_details.description')}
          />
        </div>

        <ErrorBlock
          className="error-block"
          field="__all__"
          error={errors} />

        <div className='form-actions multi-action'>
          <div className='btn secondary-dark-btn' onClick={history.goBack}>Cancel</div>
          {loading ?
            <div className='btn disable-btn'>Loading...</div>
            :
            <div className='btn yellow-btn'
              onClick={this.onFormSubmit}>Edit Expense</div>
          }
        </div>
      </form>
    )
  }
}