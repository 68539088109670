import gql from "graphql-tag";

export const GET_REPORT_CHOICES = gql`
  query {
    vehicles {
      edges {
        cursor
        node {
          image
          model_no
          pk
          registration_no
          manufacturing_year
          driver {
            name
            pk
          }
        }
      }
    }
    expenses_type_list {
      edges {
        node {
          name
          pk
        }
      }
    }
  }
`;
