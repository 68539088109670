import unionBy from 'lodash/unionBy';
import pullAllBy from 'lodash/pullAllBy';

const INIT_PAGE_SIZE = {
    window_width: window.innerWidth,
    window_height: window.innerHeight
}

export const pageSizeReducer = (state = INIT_PAGE_SIZE, action) => {
    // change store state with new window sizes when ever window size is changed

    switch (action.type) {
        case "WINDOW_SIZE_CHANGED": {
            return {
                ...state,
                window_width: window.innerWidth,
                window_height: window.innerHeight
            }
        }

        default: {
            return state;
        }
    }
}

const initNotiState = {
    noti_count: 0,
    notifications: [],
}

export const notificationReducer = (state = initNotiState, action) => {

    switch (action.type) {
        
        case "ADD_NOTIFICATION": {
            const new_notifications = unionBy(state.notifications, action.payload, 'title');
            return { ...state, notifications: new_notifications, noti_count: new_notifications.length }
        }

        case "REMOVE_NOTIFICATION": {
            const new_notifications = pullAllBy(state.notifications, [{ 'title': action.payload }], 'title');
            return { ...state, notifications: new_notifications, noti_count: new_notifications.length }
        }

        default: {
            return { ...state };
        }
    }

}