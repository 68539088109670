import React from "react"
import {Link} from 'react-router-dom'

import ListLoader from '../../others/components/ListLoader'

import Urls from '../../UrlConfig'

export default ({loading, data, error, count = process.env.REACT_APP_PAGE_COUNT }) => {

	/**
	 * Parent:
	 * 		ClientListWrapperPage
	 */

	let table;

	if(loading) {
		table = <ListLoader row={count} col={5}/>
	} 
	else if (error) {
		table = <div className="list-empty-wrapper error">Failed to fetch data!!</div>
	}
	else {
		if(data.length) {
			table = data.map((client) => {	
				return (
					<div className='list-row' key={client.pk}>
						<div className='list-col'>{client.first_name}</div>
						<div className='list-col'>{client.last_name}</div>
						<div className='list-col'>{client.contact_no}</div>
						<div className='list-col'>{client.email}</div>
						<div className='list-col action-col pd-l-0'>
							<Link to={Urls.getClientFormPage(client.pk)}
								className='yellow-btn action-btn'>
								<i className="fa fa-external-link" aria-hidden="true"></i>View
							</Link>
						</div>
					</div>
				)
			})
		}
		else {
			table = <div className="list-empty-wrapper">No Client Found</div>
		}
	}

	return (
		<div className='list-container'>
			<div className='list-row'>
				<div className='list-col'>First Name</div>
				<div className='list-col'>Last Name</div>
				<div className='list-col'>Contact No</div>
				<div className='list-col'>Email</div>
				<div className='list-col'></div>
			</div>

			{table}

		</div>
	)
}