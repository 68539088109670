import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {get} from 'lodash'

import VehicleList from '../components/VehicleList'
import VehicleSearch from '../components/VehicleSearch'
import Pagination from '../../others/components/Pagination';

import useListWithPaginationSearch from '../../others/data/search.hook';
import { GET_VEHICLE_LIST } from '../data/vehicle.graphql'
import Urls from "../../UrlConfig"



export default () => {

	/**
	 * 
	 * data component - list fetch 
	 * 
	 * Parent:
	 * 		VehicleRoute
	 * 
	 * Renders:
	 * 		VehicleList
	 * 		VehicleSearch
	 * 		Pagination
	 */

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	const {loading, data, error,
		hasNextPage, hasPrevPage, fetchNextData, fetchPrevData,
		pageNo, rowCount, changeRowCount,
		handleSearch
	} = useListWithPaginationSearch(GET_VEHICLE_LIST, "vehicles")

	const edges = get(data ,'vehicles.edges', [] )

	const paginationProps = {
		pageNo,
		hasPrevPage, hasNextPage,
		fetchNextData, fetchPrevData,
		currentCount:  rowCount,
		changeCount: changeRowCount,
	}
	
	return (
		<div id='vehicle-page' className='page-container'>
			
			<div className="page-title-wrapper">
				<div className='page-title'>Vehicle</div>
				
				<div className='search-wrapper'>
					<VehicleSearch handleSearch={handleSearch} />
					{isSuperuser &&
						<div className='btn-container'>
							<Link to={Urls.getVehicleAddPage()} className='yellow-btn'>
								<i className="fa fa-plus" aria-hidden="true"></i>Add Vehicle
							</Link>
						</div>
					}
				</div>
			</div>

			<div className='page-content flex-wrapper'>
				<div className='flex-md secondary-bg scrollable relative'>
					<div className='data-sections'>
						<div className='search-result-section'>
							Showing {edges.length} results
						</div>
						<div className='list-section'>
							<VehicleList data={edges} loading={loading} 
								count={rowCount} error={error}/>

							<Pagination {...paginationProps} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
