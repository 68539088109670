import React, { Component } from 'react'

import {get} from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'

import { postFormData } from '../../others/utils'
import Urls from '../../UrlConfig'

export default class RefuelAddPage extends Component {

	/**
	 * refuel add form,
	 * 
	 * Parent:
	 * 		VehicleRoute
	 */

	state = {
		loading: false,
		errors: {}
	}

	onFormSubmit = (event) => {
		
		event.preventDefault()

		let form = document.getElementById('add-refuel');
		let data = new FormData(form);
		// pk of vehicle
		const vehicleId = get(this.props, 'location.state.vehicleId', null)
		data.append('vehicle', vehicleId)

		this.setState({ loading: true, errors: {} })

		postFormData(Urls.apiRefuelAdd(),
			data,
			(res) => {
				// console.log("res", res)
				this.setState({ loading: false })
				this.props.history.goBack()
			},
			(err) => {
				this.setState({ loading: false, errors: err.error_data })
			}
		)

	}

	render = () => {
		const { errors, loading } = this.state
		const vehicleName = get(this.props, 'location.state.vehicleName', '')

		return (
			<form id='add-refuel' className='page-container'>
	
				<div className='page-title'>Add Refuel</div>
	
				<div className='page-content flex-wrapper'>
					<div className='flex-md secondary-bg scrollable relative'>
						<div className='form-section z-depth-2'>
	
							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Vehicle</div>
									<ErrorBlock field="vehicle" 
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter Vehicle'
									name="vehicle"
									value={vehicleName}
									disabled={true}
									readOnly={true}
								/>
							</div>
	
							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Refuel Quantity</div>
									<ErrorBlock field="refuel_quantity" 
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter Refuel Quantity'
									name="refuel_quantity"
								/>
							</div>

                            <div className='form-field'>
								<div className='form-label'>
									<div className='label'>Cost</div>
									<ErrorBlock field="cost" 
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter cost'
									name="cost"
								/>
							</div>

                            <div className='form-field'>
								<div className='form-label'>
									<div className='label'>km reading</div>
									<ErrorBlock field="km_reading" 
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter km reading'
									name="km_reading"
								/>
							</div>
	

							<ErrorBlock
								className="error-block"
								field="__all__"
								error={errors} />
	
							<div className='form-actions single-action'>
								{loading ?
									<div className='disable-btn'>Loading...</div>
									:
									<div className='yellow-btn'
										onClick={this.onFormSubmit}>Add Refuel</div>
								}
							</div>
						</div>
	
					</div>
				</div>
	
			</form>
		)
	}
}
