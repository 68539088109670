import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { getJsonData } from '../../others/utils'
import Urls from '../../UrlConfig'
import ClientList from '../components/ClientList'

class ClientListWrapperPage extends Component {

    /**
     * 
     * get client list from server
     * manage loading and errors
     * 
     * Parent:
     *      ClientRoute
     * Renders:
     *      ClientList
     */

    state = {
        userList: [],
        loading: false,
        errors: null,
    }

    _mounted = false

    componentDidMount = () => {
        this._mounted = true
        this.getClientList()
    }

    getClientList = () => {
        this.setState({ loading: true, errors: null })
        getJsonData(Urls.apiGetClientList(),
            (res) => {
                if (this._mounted) {
                    this.setState({ loading: false, errors: null, userList: res })
                }
            },
            (err) => {
                if (this._mounted) {
                    this.setState({ loading: false, errors: err.error_data })
                }
            }
        )
    }

    render = () => {
        const { isSuperuser } = this.props
        const { userList, loading, errors  } = this.state
        return (
            <div className='page-container'>

                <div className="page-title-wrapper">
                    <div className='page-title'>Clients</div>

                    <div className='search-wrapper' style={{ justifyContent: 'flex-end' }}>
                        {isSuperuser &&
                            <div className='btn-container'>
                                <Link to={Urls.getClientFormPage('new')} className='yellow-btn'>
                                    <i className="fa fa-plus" aria-hidden="true"></i>Add Client
                                </Link>
                            </div>
                        }
                    </div>
                </div>

                <div className='page-content flex-wrapper'>
                    <div className='flex-md secondary-bg scrollable relative'>
                        <div className='data-sections'>
                            <div className='search-result-section'>
                                Showing {userList.length} results
						</div>
                            <div className='list-section'>
                                <ClientList data={userList} loading={loading} error={errors} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(store => ({
    isSuperuser: store.auth.is_superuser
}))(ClientListWrapperPage)
