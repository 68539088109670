import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { connect } from 'react-redux'

import { get, has } from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'

import { AddDriver, CloseIcon, BackIcon } from '../../others/SvgIcons'

import { postFormData } from '../../others/utils'
import Urls from '../../UrlConfig'


/**
 * Scss:
 * 		global-styles / _layout
 * 		global-styles / forms / _form
 * 		global-styles / others / _loadingButton
 */
class DriverAddPage extends Component {

	constructor(props) {
		super(props)

		this.isEdit = has(props, 'match.params.driverId')

		let initState
		if (this.isEdit) {
			initState = {
				imageUrl: get(props, 'location.state.image', null),
				first_name: get(props, 'location.state.driver.first_name', ''),
				last_name: get(props, 'location.state.driver.last_name', ''),
				contact_no: get(props, 'location.state.driver.contact_no', ''),
				email: get(props, 'location.state.driver.email', ''),
				monthly_salary: get(props, 'location.state.monthly_salary', ''),
				licence_no: get(props, 'location.state.licence_no', ''),
				description: get(props, 'location.state.description', ''),
				pk: get(props, 'location.state.pk', null)
			}
		}
		else {
			initState = {
				imageUrl: null,
				first_name: '',
				last_name: '',
				contact_no: '',
				email: '',
				monthly_salary: '',
				licence_no: '',
				description: '',
			}
		}

		this.state = {
			...initState,
			imageFile: null,
			loading: false,
			errors: {}
		}
	}

	onFormSubmit = (event) => {

		event.preventDefault()
		let form = document.getElementById('add-driver');
		let formData = new FormData(form);

		// check validation in case of add
		let has_errors = false, errors = {}
		if (!this.isEdit) {
			if (!formData.get('contact_no')) {
				has_errors = true
				errors['contact_no'] = ['This field is required.']
			}
			if (!formData.get('password')) {
				has_errors = true
				errors['password'] = ['This field is required.']

			}
		}
		if (has_errors) {
			this.setState({ errors })
			return
		}
		else {
			this.setState({ loading: true, errors: {} })
		}

		if (this.state.imageUrl) {
			if (this.state.imageFile) {
				// 1. new driver create with image
				// 2. edit image of driver, old image exist
				formData.append('image', this.state.imageFile)
			}
		}
		else {
			// case: clear image
			formData.append('image', '')
		}

		// add pk in case of edit
		if (this.isEdit) {
			formData.append('pk', this.state.pk)
		}

		postFormData(Urls.apiDriverAdd(),
			formData,
			(res) => {
				this.props.history.push(Urls.getDriverPage())
			},
			(err) => {
				this.setState({ loading: false, errors: err.error_data })
			}
		)

	}

	loadFile = (event) => {
		const selected_image = event.target.files[0]
		if (selected_image) {
			this.setState({
				imageUrl: URL.createObjectURL(selected_image),
				imageFile: selected_image
			})
		}
	}

	clearImage = () => {
		this.setState({ imageUrl: null, imageFile: null })
	}

	// input handler
	onTextChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}


	render = () => {
		const { history, isSuperuser } = this.props
		const { imageUrl, errors, loading,
			first_name, last_name, contact_no,
			email, monthly_salary, licence_no, description,
		} = this.state

		if(!isSuperuser) {
			return <Redirect to={Urls.getUnauthorized()}/>
		}

		
		return (
			<form id="add-driver" className='page-container'>
				<div className='page-title'>
					<BackIcon onClick={history.goBack} />
					<div className="heading">Add Driver</div>
				</div>

				<div className='page-content flex-wrapper'>
					<div className='flex-md secondary-bg scrollable relative'>

						<div className='form-section z-depth-2'>
							<div className="form-section-title">Basic Info</div>
							<div className='form-column'>
								<div className='form-column-field'>
									<div className="upload-block">
										<input type="file" name="image" onChange={this.loadFile} />
										<div className="upload-wrapper">
											<AddDriver />
											<div className='upload-text'>Upload Driver Image</div>
										</div>
										{imageUrl && (
											<>
												<img className="responsive-img" src={imageUrl} alt="" />
												<div className="remove-image" onClick={this.clearImage}>
													<CloseIcon />
												</div>
											</>
										)}
									</div>
								</div>
								<div className='form-column-field'>
									<div className='form-field'>
										<div className='form-label'>
											<div className='label'>First Name</div>
											<ErrorBlock field="first_name"
												className="error"
												error={errors} />
										</div>
										<input type="text"
											className="textbox"
											placeholder='Enter First Name'
											name="first_name"
											value={first_name}
											onChange={this.onTextChange}
										/>
									</div>
									<div className='form-field'>
										<div className='form-label'>
											<div className='label'>Last Name</div>
											<ErrorBlock field="last_name"
												className="error"
												error={errors} />
										</div>
										<input type="text"
											className="textbox"
											placeholder='Enter Last Name'
											name="last_name"
											value={last_name}
											onChange={this.onTextChange}
										/>
									</div>
								</div>
							</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Contact Number</div>
									<ErrorBlock field="contact_no"
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter Contact Number'
									name="contact_no"
									value={contact_no}
									onChange={this.onTextChange}
								/>
							</div>

							{!(this.isEdit) &&
								<div className='form-field'>
									<div className='form-label'>
										<div className='label'>Password</div>
										<ErrorBlock field="password"
											className="error"
											error={errors} />
									</div>
									<input type="password"
										className="textbox"
										placeholder='Enter Password'
										name="password"
									/>
								</div>
							}

							<div className="form-section-title">Additional Info</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Email</div>
									<ErrorBlock field="email"
										className="error"
										error={errors} />
								</div>
								<input type="email"
									className="textbox"
									placeholder='Enter Email ( Optional )'
									name="email"
									value={email}
									onChange={this.onTextChange}
								/>
							</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Monthly Salary</div>
									<ErrorBlock field="monthly_salary"
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter Monthly Salary'
									name="monthly_salary"
									value={monthly_salary}
									onChange={this.onTextChange}
								/>
							</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Licence No</div>
									<ErrorBlock field="licence_no"
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter Licence No'
									name="licence_no"
									value={licence_no}
									onChange={this.onTextChange}
								/>
							</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>About Driver</div>
								</div>
								<input type="text"
									className="textbox"
									placeholder='About Driver (optional)'
									name="description"
									value={description}
									onChange={this.onTextChange}
								/>
							</div>

							<div className='form-actions single-action'>
								{loading ?
									<div className='disable-btn'>Loading...</div>
									:
									<div className='yellow-btn' onClick={this.onFormSubmit}>
										{this.isEdit ? "Update Driver" : "Add Driver"}
									</div>
								}
							</div>

						</div>

					</div>
				</div>

			</form>
		)
	}
}

export default connect(store => ({
	isSuperuser: store.auth.is_superuser
}))(DriverAddPage)