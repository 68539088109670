import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { ApolloProvider } from 'react-apollo'
// redux
import { Provider } from 'react-redux'
import store from './store'
import client from './apollo_client'
import { PersistGate } from 'redux-persist/es/integration/react'
import { persistStore } from 'redux-persist'

// pages
import DashboardPage from './others/pages/DashboardPage'
import AuthWrapper from './others/pages/AuthWrapper'
import PageNotFound from './others/pages/PageNotFound'
import Unauthorized from './others/pages/Unauthorized'
import PrivacyPolicy from './others/pages/PrivacyPolicy'
import ProfilePage from './profile/pages/ProfilePage'
import Loader from './others/components/Loader'
import ExpensePage from './expense/pages/ExpensePage'

import TripRoute from './trip/TripRoute'
import DriverRoute from './driver/DriverRoute'
import VehicleRoute from './vehicle/VehicleRoute'
import ReportPage from './reports/pages/ReportPage'
import ClientRoute from './clients/ClientRoute'

// libs
import Urls from "./UrlConfig";
import { getJsonData } from './others/utils'

import { PrivateRoute } from './others/Layouts'

import 'react-datetime-range-super-picker/dist/index.css'

const persistor = persistStore(store)

export default class App extends Component {

  state = {
    mount_app: false
  }

  onBeforeLift = () => {
    if (!store.getState().auth.logged) {
      // user not logged no need to check token
      this.setState({ mount_app: true })
    }
    else {
      getJsonData(Urls.apiTokenCheck(),
        (res) => {
          store.dispatch({ type: 'USER_CHECKAUTH_SUCCESS', payload: res })
          this.setState({ mount_app: true })
        },
        (err) => {
          if (err.error_code === 401) {
            store.dispatch({ type: 'USER_LOGOUT' })
          }
          this.setState({ mount_app: true })
        }
      )
    }
  }

  render = () => {
    return (
      <ApolloProvider client={client}>
        <Provider store={store} >
          <PersistGate persistor={persistor}
            loading={
              <div className="loading-overlay">
                <Loader />
              </div>
            }
            onBeforeLift={this.onBeforeLift} >
            {this.state.mount_app ?
              <BrowserRouter>
                <Switch>
                  <PrivateRoute exact path={Urls.getDashboardPage()} component={DashboardPage} />
                  <PrivateRoute exact path={Urls.getProfilePage()} component={ProfilePage} />

                  <PrivateRoute path={Urls.getVehiclePage()} component={VehicleRoute} />
                  <PrivateRoute path={Urls.getDriverPage()} component={DriverRoute} />
                  <PrivateRoute path={Urls.getTripPage()} component={TripRoute} />

                  <PrivateRoute path={Urls.getClientPage()} component={ClientRoute} />
                  <PrivateRoute path={Urls.getReportPage()} component={ReportPage} />
                  <PrivateRoute path={Urls.getExpenseEditPage()} component={ExpensePage} />

                  <Route path={Urls.getAuthPage()} component={AuthWrapper} />

                  <Redirect exact from={Urls.getHomePage()} to={Urls.getDashboardPage()} />

                  <Route exact path={Urls.getPrivacyPage()} component={PrivacyPolicy} />
                  <Route exact path={Urls.getUnauthorized()} component={Unauthorized} />
                  <Route exact path={Urls.get404()} component={PageNotFound} />
                  <Route exact path="*" component={PageNotFound} />
                </Switch>
              </BrowserRouter>
              :
              <div className="loading-overlay">
                <Loader />
              </div>
            }
          </PersistGate>
        </Provider>
      </ApolloProvider>
    )
  }
}