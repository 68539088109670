import React from 'react'

import get from 'lodash/get'

/**
 * 	Field - field name
 * 	error - full error obj -> { field: ["", ""] }
 * 	classname - 2 classes => .error in case of form
 * 							.error-block in case of general error
 */

const ErrorBlock = ({ field, error, className }) => {
	const err_list = get(error, field, false)

	if(err_list) {
		return (
			err_list.map((err, id) => {
				return <div className={className} key={id}>{err}</div>
			})
		)
	} else {
		return <div></div>
	}
}

export default ErrorBlock