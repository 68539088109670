import React, { useState } from 'react';

export default ({handleSearch}) => {

	/**
	 * Parent:
	 * 		DriverDetailsPage
	 */

	const [name, setName] = useState('')

	const clearSearch = () => {
		setName('')
		handleSearch({ name: ''})
	}

	return (
		<div className='search-input-container'>
            <input type="text" className="textbox search-input"
                placeholder='Search by name.....'
                name="search" value={name}
                onChange={(e) => setName(e.target.value)} />

            <div className={`icn clear-text scale-transition ${name ? 'scale-in' : 'scale-out'}`} 
                onClick={clearSearch}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </div>

			<div className='icn magnifier scale-transition' 
				onClick={() => handleSearch({name})}>
				<i className="fa fa-search" aria-hidden="true"></i>
			</div>
		</div>
	)
}