import React, { Component } from 'react'

import { get } from 'lodash'

import Loader from '../../others/components/Loader'
import ClientForm from '../components/ClientForm'

import { BackIcon } from '../../others/SvgIcons'
import { getJsonData } from '../../others/utils'
import Urls from '../../UrlConfig'

export default class ClientFormPage extends Component {

    /**
     * get client data in case of edit
     * decide form is add or edit
     * 
     * Parent:
     *      ClientRoute
     * Renders:
     *      ClientForm
     */

    constructor(props) {
        super(props)

        this.isEdit = get(props, 'match.params.clientId') !== 'new'
        this._mounted = false

        this.state = {
            details: null,
            loading: Boolean(this.isEdit),
            errors: null,
        }
    }

    componentDidMount = () => {
        this._mounted = true
        if (this.isEdit) {
            const clientId = get(this.props, 'match.params.clientId')

            this.setState({ loading: true, errors: null })
            getJsonData(Urls.apiClientDetails(clientId),
                (res) => {
                    if (this._mounted) {
                        this.setState({ loading: false, errors: null, details: res })
                    }
                },
                (err) => {
                    if (this._mounted) {
                        this.setState({ loading: false, errors: err.error_data })
                    }
                }
            )
        }
    }

    render = () => {
        const { history } = this.props
        const { loading, errors, details } = this.state
        return (
            <div className='page-container small-page-title'>
                <div className='page-title'>
                    <BackIcon onClick={history.goBack} />
                    <div className="heading">{this.isEdit ? "Client Details" : "Add Client"}</div>
                </div>
                <div className='page-content flex-wrapper'>
                    <div className='flex-md secondary-bg scrollable relative'>

                        {loading ?
                            <div className="loading-overlay">
                                <Loader />
                            </div>
                            :
                            errors ?
                                <div className="loading-overlay">
                                    <h2>Failed to fetch data</h2>
                                </div>
                                :
                                <ClientForm isEdit={this.isEdit} details={details} onCancle={history.goBack} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
