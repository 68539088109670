import React, { Component } from 'react'
import { connect } from 'react-redux';
import { formatDate } from '../../others/utils';

import { format } from 'date-fns';
import { get } from 'lodash';


class TripReport extends Component {
  /**
   * Parent:
   *      ReportPage
   */

  render = () => {
    const { tripData } = this.props
    if (tripData.is_schedule_program) {
      return (
        <div className="report-data-container z-depth-1">
          <div className="trip-report-heading">VEHICLE TRANSPORT PROGRAM</div>
          {tripData.data.length ?
            tripData.data.map(data => {
              const vehicleName = get(data, 'trip_list.0.vehicle.name')
              const regNo = get(data, 'trip_list.0.vehicle.registration_no')
              return (
                <div key={data.vehicle_id}>
                  <div className="vehicle-refule-heading">Trips For {vehicleName}, {regNo}</div>
                  <div className="vehicle-refule-table last-row-bot">
                    <div className="vehicle-refule-row header">
                      <div className="col">Date</div>
                      <div className="col">From</div>
                      <div className="col">To</div>
                      <div className="col">Material Description</div>
                      <div className="col">Driver</div>
                      <div className="col">Start</div>
                      <div className="col">End</div>
                      <div className="col">Remark</div>
                    </div>
                    {data.trip_list.map((trip) => {
                      const startDate = trip.actual_start_time ?
                        format(new Date(trip.actual_start_time), 'h:mm a') : ''
                      const endDate = trip.actual_end_time ?
                        format(new Date(trip.actual_end_time), 'h:mm a') : ''
                      return (
                        <div key={trip.id} className="vehicle-refule-row contect">
                          <div className="col">{formatDate(trip.start_time)}</div>
                          <div className="col">{trip.from_destination}</div>
                          <div className="col">{trip.to_destination}</div>
                          <div className="col">{trip.material_description}</div>
                          <div className="col">{get(trip, 'driver.name')}</div>
                          <div className="col">{startDate}</div>
                          <div className="col">{endDate}</div>
                          <div className="col">{trip.remarks}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })
            :
            <div className="reports-no-data">
              No Data
            </div>
          }
        </div>
      )
    }
    else {
      return (
        <div className="report-data-container z-depth-1">
          <div className="trip-report-heading">VEHICLE TRANSPORTATION REPORT</div>
          {tripData.data.length ?
            tripData.data.map(data => {
              const vehicleName = get(data, 'trip_list.0.vehicle.name')
              const regNo = get(data, 'trip_list.0.vehicle.registration_no')
              return (
                <div key={data.vehicle_id}>
                  <div className="vehicle-refule-heading">Trips For {vehicleName}, {regNo}</div>
                  <div className="vehicle-refule-table last-row-bot">
                    <div className="vehicle-refule-row header">
                      <div className="col">Date</div>
                      <div className="col">Challan No</div>
                      <div className="col">From</div>
                      <div className="col">To</div>
                      <div className="col">Material Description</div>
                      <div className="col">Driver</div>
                      <div className="col">Start KM Reading</div>
                      <div className="col">End KM Reading</div>
                      <div className="col">Remark</div>
                      <div className="col">Status</div>
                    </div>
                    {data.trip_list.map((trip) => {
                      return (
                        <div key={trip.id} className="vehicle-refule-row contect">
                          <div className="col">{formatDate(trip.start_time)}</div>
                          <div className="col">{trip.challan_number}</div>
                          <div className="col">{trip.from_destination}</div>
                          <div className="col">{trip.to_destination}</div>
                          <div className="col">{trip.material_description}</div>
                          <div className="col">{get(trip, 'driver.name')}</div>
                          <div className="col">{trip.start_km_reading}</div>
                          <div className="col">{trip.end_km_reading}</div>
                          <div className="col">{trip.remarks}</div>
                          <div className="col">{trip.status}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })
            :
            <div className="reports-no-data">
              No Data
            </div>
          }
        </div>
      )
    }
  }
}

export default connect(store => ({
  tripData: store.reports.tripData,
}))(TripReport)