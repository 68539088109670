import React, { useEffect } from 'react'
import { useSelector ,useDispatch, connect } from 'react-redux'
import { Redirect } from 'react-router';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { filter, get, has } from 'lodash'

import ScheduleVehicleList from '../components/ScheduleVehicleList'
import ScheduleForm from '../components/ScheduleForm'
import { ScheduleTimeSelector } from '../../others/components/TimeSelector'
import Loader from '../../others/components/Loader'

import { BackIcon } from '../../others/SvgIcons'

import { addSchedule, getTripScheduleList } from '../data/schedulePage.actions'
import Urls from '../../UrlConfig';


const GET_DRIVERS = gql`
query drivers($after: String, $before: String, $first: Int, $last: Int) {
	drivers(after: $after, before : $before, first: $first, last : $last) {
		edges {
			cursor
			node { name pk }
		} 
	}
}
`

const GET_VEHICLE_LIST = gql`
query vehicles($after: String, $before: String, $first: Int, $last: Int) {
	vehicles(after: $after, before : $before, first: $first, last : $last) {
		edges {
			cursor
			node {
				name model_no pk registration_no
				driver {
					name pk
				}
			}
		} 
	}
}
`

/**
 * Scss
 * 		global-styles / trip / _trips_add
 * 		global-styles / others / _react_super_picker
 * 		global-styles / _layout
 * 		
 */
export default ({ history }) => {

	/**
	 * wrapper for schedule add page
	 * fetch vehicle and driver list from gql
	 * manage loading
	 * redirect user to 404 if user is not admin
	 * 
	 * Parent
	 * 		TripRoute
	 * 
	 * Renders:
	 * 		ScheduleVehicleList
	 * 		ScheduleTimeSelector
	 * 		ScheduleFormsList
	 */

	const dispatch = useDispatch()
	const isSuperuser = useSelector(store => store.auth.is_superuser)

	const {
		loading: loading_vehicle,
		data: vehicle_data,
	} = useQuery(GET_VEHICLE_LIST, { fetchPolicy: "cache-and-network" })


	const {
		loading: loading_driver,
		data: driver_data
	} = useQuery(GET_DRIVERS, { fetchPolicy: "cache-and-network" })


	const vehicle_list = get(vehicle_data, 'vehicles.edges', [])
	const driver_list = get(driver_data, 'drivers.edges', [])

	if(!isSuperuser) {
		return <Redirect to={Urls.getUnauthorized()}/>
	}

	return (
		<div id='trip-add' className='page-container'>

			<div className='page-title'>
				<BackIcon onClick={history.goBack} />
				<div className="heading">Manage Schedules</div>
			</div>
			{(loading_vehicle || loading_driver) ?
				<div className='page-content flex-wrapper'>
					<div className='flex-sm secondary-bg relative'>
						<div className="loading-overlay">
							<Loader />
						</div>
					</div>
				</div>
				:
				<div className='page-content'>

					<div className="header-action-wrapper relative z-depth-1">
						<div className="header-trip-title">Vehicle List</div>
						<div className="header-actions">
							<ScheduleTimeSelector />
							<div className='btn yellow-btn' onClick={() => dispatch(addSchedule())}>
								<i className="fa fa-plus" aria-hidden="true"></i>Add Schedule
							</div>
						</div>
					</div>

					<div className="schedules-page-wrapper">
						<div className="trip-list-col">
							<ScheduleVehicleList vehicleList={vehicle_list} />
						</div>
						<div className="schedules-container-col secondary-bg">
							<ScheduleFormsList driverList={driver_list} vehicleList={vehicle_list} />
						</div>
					</div>
				</div>
			}
		</div>
	)
}


const ScheduleFormsList = connect(store => ({
	startTime: store.schedulePage.startTime,
	endTime: store.schedulePage.endTime,

	scheduleList: store.schedulePage.scheduleList,
	scheduleListFetched: store.schedulePage.scheduleListFetched,
	scheduleListError: store.schedulePage.scheduleListError,
	selectedVehicle: store.schedulePage.selectedVehicle,
}))(props => {

	/**
	 * 
	 * show schedule forms
	 * 
	 * Parent:
	 *      TripAddPage
	 */
	const { scheduleListFetched, scheduleListError,
		scheduleList, selectedVehicle,
		driverList, vehicleList, dispatch
	} = props

	useEffect(() => {
		let startTimeStr = props.startTime
		startTimeStr = startTimeStr.toISOString()

		let endTimeStr = props.endTime
		endTimeStr = endTimeStr.toISOString()

		dispatch(getTripScheduleList(startTimeStr, endTimeStr))
	}, [])

	// filter trip schedules according to selected vehicle
	let filteredScheduleList = selectedVehicle === -1 ?
		scheduleList
		:
		filter(scheduleList, ['vehicle', selectedVehicle])

	if (scheduleListFetched) {
		if (filteredScheduleList.length) {
			return (
				<>
					{filteredScheduleList.map(schedule => {
						const isEdit = has(schedule, 'id')
						return (
							<ScheduleForm key={isEdit ? schedule.id : `${schedule.formId}`}
								driverList={driverList}
								vehicleList={vehicleList}
								isEdit={isEdit} 
								details={schedule}
							/>
						)
					})}
				</>
			)
		}
		else {
			return (
				<div className="message-wrapper">
					<div className="message">Please <span>Add</span> schedule</div>
				</div>
			)
		}
	}
	else if (scheduleListError) {
		return (
			<div className="message-wrapper">
				<h2>Failed to fetch data</h2>
			</div>
		)
	}
	else {
		return (
			<div className="message-wrapper">
				<Loader />
			</div>
		)
	}
})
