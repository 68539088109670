import React from 'react';
import { connect } from 'react-redux';

class NotificationList extends React.Component {

	renderNotification = () => {
		if(this.props.count > 0){
			return(
				this.props.notifications.map((notification, index) => {
					return (
						<NotificationDetail
							key={notification.title}
							type={notification.type}
							icon={notification.icon}
							title={notification.title}
							text={notification.text}
							timeout={notification.timeout}
							dispatch={this.props.dispatch}
						/>
					)
				})
			)
		}
	}

	render = () => {
		return(
			<div className="notification">
				{this.renderNotification()}
			</div>
		)
	}
}

export default connect(store => ({
	notifications : store.notifications.notifications,
	count : store.notifications.noti_count,
}))(NotificationList)

class NotificationDetail extends React.Component {

	constructor(props){
		super(props);

		const timeout = props.timeout ? props.timeout : 5000;
		this.state = {
			timeout,
		}

		this.removeNotification = this.removeNotification.bind(this);
	}

	componentDidMount = () => {
		// remove notification automatically after 5 secs (default)
		const _this = this;
		this.timerFunc = setTimeout(() => {
			// console.log(_(this.props)
			_this.removeNotification();
		}, this.state.timeout);
	}

	/*
	If Timeout is cleared than it causes problems for multiple noti.
	First removed noti clears the last created noti's timeout so the
	last one is never removed automatically
	*/
	// componentWillUnmount(){
	// 	clearTimeout(this.timerFunc);
	// }

	removeNotification = () => {
		this.props.dispatch({
			type: 'REMOVE_NOTIFICATION',
			payload: this.props.title,
		})
	}

	render = () => {
		return(
			<div className={`content ${this.props.type}`}>
				<div className="icon">
					<i className={`fa fa-2x ${this.props.icon}`}></i>
				</div>

				<div className="text">
					<div className="title"><b>{this.props.title}</b></div>
					{this.props.text && <div>{this.props.text}</div>}
				</div>

				<div className="cancel" onClick={this.removeNotification}><i className="fa fa-times"></i></div>
			</div>
		)
	}
}

// NotificationDetail.propTypes = {
// 	// type of notification "success(info) | warning | error"
// 	type : PropTypes.string.isRequired,
// 	// fa icon to show on notification
// 	icon : PropTypes.string.isRequired,

// 	title : PropTypes.string.isRequired,
// 	text : PropTypes.string,
// 	timeout : PropTypes.number,
// };