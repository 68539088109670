import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

import Urls from './UrlConfig';
import store from './store'

const client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
					),
				);
			if (networkError) console.log(`[Network error]: ${networkError}`);
		}),
		(operation, forward) => {
				// Retrieve the authorization token from local storage.
				const token = store.getState().auth.token
				// Use the setContext method to set the HTTP headers.
				operation.setContext(({ headers = {} }) => ({
						headers: {
							...headers,
							Authorization: `Bearer ${token}`,
						}
					}));
				return forward(operation);
		},
		new HttpLink({
			uri: Urls.getGraphqlUrl(),
		})
	]),
	cache: new InMemoryCache()
});

export default client