import { postJsonData } from "../../others/utils"
import Urls from "../../UrlConfig"


export const updateScheduleData = (data) => ({
  type: "UPDATE_SCHEDULE_DATA", payload: data
})

export const addSchedule = () => ({
  type: "SCHEDULE_ADD"
})

export const deleteSchedule = (data) => ({
  type: "SCHEDULE_DELETE", payload: data
})

/**
 * 
 * @param {*} data - shape
 * {
 *  res: update data from server
 *  isEdit: boolean, indicate add or edit
 *  findId: from which id have to find in list, coz add have "formId", edit have "id"
 * }
 */
export const updateAfterSuccess = (data) => ({
  type: "SCHEDULE_ADD_SUCCESSFULLY", payload: data
})

/**
 * 
 * @param {*} data - shape
 * {
 *  isEdit: boolean, indicate add or edit
 *  findId: from which id have to find in list, coz add have "formId", edit have "id"
 *  data: { [key]: value, ... } object, which have to update
 * }
 */
export const updateFormField = (data) => ({
  type: "UPDATE_SCHEDULE_FORM_FIELDS", payload: data
})

export const changeScheduleFormsTime = (data) => {
  return ((dispatch) => {
    // update selected data in store
    dispatch({ type: "UPDATE_SCHEDULE_DATA", payload: data })
    // fetch new data
    let startTimeStr = data.startTime
    startTimeStr = startTimeStr.toISOString()

    let endTimeStr = data.endTime
    endTimeStr = endTimeStr.toISOString()
    dispatch(getTripScheduleList(startTimeStr, endTimeStr))
  })
}

export const getTripScheduleList = (startTimeStr, endTimeStr) => {
  return (dispatch => {

    dispatch({ type: "SCHEDULE_LIST_FETCHING" })
    postJsonData(Urls.apiTripList(),
      {
        "start_date": startTimeStr,
        "end_date": endTimeStr,
      },
      (res) => {
        dispatch({ type: "SCHEDULE_LIST_FETCH_SUCCESS", payload: res })
      },
      (err) => {
        dispatch({ type: "SCHEDULE_LIST_FETCH_ERROR", payload: err })
      }
    )
  })
}