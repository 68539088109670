import React from 'react'
import { Link } from 'react-router-dom'

import LoginPage from './LoginPage'
import PageNotFound from './PageNotFound'

import get from 'lodash/get'
import Urls from '../../UrlConfig'


export default (props) =>  {

	/**
	 * Parent:
	 * 		App
	 * 
	 * Renders:
	 * 		LoginPage
	 * 		PageNotFound
	 */

	const auth_type = get(props ,'match.params.auth_type', 'login')
	const from_path = get(props , 'history.location.state.from.pathname')
	
	let page
	switch (auth_type) {
		case 'login':
			page = <LoginPage from_route={from_path} />;
			break;

		default:
			return <PageNotFound />
	}

	return (
		<div className="auth-wrapper">
			<div className="auth-fixed-bg"></div>    
			<div className="auth-body-wrapper">
				<div className="auth-card">
					{page}
					<div className="privacy-policy-link">
						<Link to={Urls.getPrivacyPage()}>Read Privacy Policy</Link>
					</div>
				</div>
				<div className="store-icons">
					<a className="store-link" href="https://play.google.com/store/apps/details?id=com.chavda.logistics"
						target="_blank" without="true" rel="noopener noreferrer">
						<img className="store-google" src={Urls.getStaticImg('img/google300.png')} alt="Google" />
					</a>
					<a className="store-link" href="https://apps.apple.com/app/id1564546234"
						target="_blank" without="true" rel="noopener noreferrer">
						<img src={Urls.getStaticImg('img/apple512.png')} alt="Apple" />
					</a>
				</div>
			</div>
		</div>
	)
}
