import React, { Component } from 'react'

import Select from 'react-select'
import { DateTimePickerInput } from 'react-datetime-range-super-picker';

import { get, find } from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'

import { formatSubmitDate, postFormData } from '../../others/utils'
import Urls from '../../UrlConfig'

const EXPENSETYPE_MAPPING = {
  driver: 'd',
  vehicle: 'v'
}

export default class ExpenseAddForm extends Component {

  /**
   * general expense add form,
   * 
   * Parent:
   * 		ExpensePage
   */

  state = {
    loading: false,
    errors: {},
    currDate: new Date(),
    vehicle: null,
    driver: null,
    expenseType: null
  }

  componentDidMount = () => {
    const { prime_type, prime_id, data } = this.props
    // vehicle automatically set driver
    if (prime_type === "vehicle") {
      const vehicleList = get(data, 'vehicles.edges', [])
      const vehicleData = find(vehicleList, ['node.pk', Number(prime_id)])

      if (!!vehicleData) {
        // same handler used on select boz prop data is same
        this.handleVehicleSelect(vehicleData)
      }
    }
    else if (prime_type === "driver") {
      const driverList = get(data, 'drivers.edges', [])
      const driverData = find(driverList, ['node.pk', Number(prime_id)])

      if (!!driverData) {
        // same handler used on select boz prop data is same
        this.handleDriverSelect(driverData)
      }
    }
  }

  onFormSubmit = (event) => {
    event.preventDefault()

    const { currDate } = this.state

    let form = document.getElementById('add-expense');
    let data = new FormData(form);

    data.append('timestamp', formatSubmitDate(currDate))
    data.append('prime_type', EXPENSETYPE_MAPPING[this.props.prime_type])

    if (!data.get('vehicle')) {
      this.setState({ errors: { vehicle: ['This field is required.'] } })
      return
    }

    this.setState({ loading: true, errors: {} })

    postFormData(Urls.apiExpenseAdd(),
      data,
      (res) => {
        // console.log("res", res)
        this.setState({ loading: false })
        this.props.history.goBack()
      },
      (err) => {
        this.setState({ loading: false, errors: err.error_data })
      }
    )
  }

  handleVehicleSelect = (props) => {
    let newState = { vehicle: props }
    // check vehicle have driver or not
    const driver = get(props, 'node.driver')
    if (!!driver) {
      newState['driver'] = { node: driver }
    }
    this.setState(newState)
  }

  handleDriverSelect = (props) => {
    this.setState({ driver: props })
  }

  handleExpenseTypeSelect = (props) => {
    this.setState({ expenseType: props })
  }

  handleDateUpdate = (data) => {
    this.setState({ currDate: data.date.date })
  }

  render = () => {
    const { errors, loading, currDate,
      vehicle, driver, expenseType
    } = this.state

    const { prime_type, history } = this.props
    const show_vehicle_select = prime_type !== 'driver'

    const vehicle_list = get(this.props, 'data.vehicles.edges', [])
    const driver_list = get(this.props, 'data.drivers.edges', [])
    const expenses_type_list = get(this.props, 'data.expenses_type_list.edges', [])

    return (
      <form id='add-expense' className='form-section z-depth-2'>

        {show_vehicle_select &&
          <div className='form-field'>
            <div className='form-label'>
              <div className='label'>Select Vehicle*</div>
              <ErrorBlock field="vehicle"
                className="error"
                error={errors} />
            </div>
            <Select className="select-dropdown"
              name="vehicle"
              placeholder="Select Vehicle"
              options={vehicle_list}
              getOptionLabel={props => props.node.registration_no}
              getOptionValue={props => props.node.pk}
              value={vehicle}
              onChange={this.handleVehicleSelect}
            />
          </div>
        }

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Select Driver*</div>
            <ErrorBlock field="driver"
              className="error"
              error={errors} />
          </div>
          <Select className="select-dropdown"
            name="driver"
            placeholder="Select Driver"
            options={driver_list}
            getOptionLabel={props => props.node.name}
            getOptionValue={props => props.node.pk}
            value={driver}
            onChange={this.handleDriverSelect}
          />
        </div>

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Expense Type*</div>
            <ErrorBlock field="expense_type"
              className="error"
              error={errors} />
          </div>
          <Select className="select-dropdown"
            name="expense_type"
            placeholder="Select Expense Type"
            options={expenses_type_list}
            getOptionLabel={props => props.node.name}
            getOptionValue={props => props.node.pk}
            value={expenseType}
            onChange={this.handleExpenseTypeSelect}
          />
        </div>

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Expense Time*</div>
            <ErrorBlock field="timestamp"
              className="error"
              error={errors} />
          </div>
          <DateTimePickerInput
            date={currDate}
            onDateTimeUpdate={this.handleDateUpdate}
            theme="dark"
            className="super-picker"
          />
        </div>

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Amount*</div>
            <ErrorBlock field="cost"
              className="error"
              error={errors} />
          </div>
          <input type="text"
            className="textbox"
            placeholder='Enter amount'
            name="cost"
          />
        </div>

        {get(expenseType, 'node.name', '') === "DIESEL" &&
          <>
            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>Refuel Quantity</div>
                <ErrorBlock field="refuel_quantity"
                  className="error"
                  error={errors} />
              </div>
              <input type="text"
                className="textbox"
                placeholder='Enter Refuel Quantity'
                name="refuel_quantity"
              />
            </div>

            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>km reading</div>
                <ErrorBlock field="km_reading"
                  className="error"
                  error={errors} />
              </div>
              <input type="text"
                className="textbox"
                placeholder='Enter km reading'
                name="km_reading"
              />
            </div>
          </>
        }

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Remarks</div>
            <ErrorBlock field="description"
              className="error"
              error={errors} />
          </div>
          <textarea type="text"
            className="textbox"
            placeholder='Enter Remarks'
            name="description"
            rows="3"
          />
        </div>

        <ErrorBlock
          className="error-block"
          field="__all__"
          error={errors} />

        <div className='form-actions multi-action'>
          <div className='btn secondary-dark-btn' onClick={history.goBack}>Cancel</div>
          {loading ?
            <div className='btn disable-btn'>Loading...</div>
            :
            <div className='btn yellow-btn'
              onClick={this.onFormSubmit}>Add Expense</div>
          }
        </div>
      </form>
    )
  }
}