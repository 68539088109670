import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import { useSelector } from 'react-redux';

import { get } from 'lodash'

import VehicleTripList from '../components/DriverTripList'
import Loader from '../../others/components/Loader'

import { GET_DRIVER_TRIPS } from '../data/driver.graphql'
import { BackIcon, DefaultDriver } from '../../others/SvgIcons'
import Urls from '../../UrlConfig'


export default (props) => {

	/**
	 * 
	 * show driver details page
	 * 	- basic details
	 *  - tab selection 
	 * 	- list according to selected tab
	 * 
	 * Parent:
	 * 		DriverRoute
	 * 
	 * Renders:
	 * 		DriverExpenseList
	 * 		DriverTripList
	 */

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	const details = get(props, 'location.state.details', null)
	const { loading, data, error } = useQuery(
		GET_DRIVER_TRIPS, { variables: { driverId: details.pk }, fetchPolicy: "cache-and-network" }
	)

	return (
		<div className='page-container'>
			<div className='page-title'>
				<BackIcon onClick={props.history.goBack} />
				<div className="heading">Driver Details</div>
			</div>

			<div className='page-content flex-wrapper'>
				<div className='flex-md secondary-bg scrollable relative'>
					{loading ?
						<div className="loading-overlay">
							<Loader />
						</div>
						:
						error ?
							<div className="loading-overlay">
								<h2>Failed to fetch data</h2>
							</div>
							:
							<div className="page-details-wrapper">
								<div className="basic-section flex-wrapper">
									<div className="basic-details-section flex-sm">
										<div className="table-wrapper">
											<div className="table-col image-col">

												<div className="image-wrapper z-depth-1">
													{details.image ?
														<img src={details.image} alt="" />
														:
														<DefaultDriver />
													}
												</div>

											</div>
											<div className="table-col">
												<div className="content">
													<div className="heading text-white">{details.name}</div>
													<div className="title">Salary : ₹ {details.monthly_salary}</div>
													<div className="sub-title">Licence No : {details.licence_no}</div>
													<div className="mid-text">Contact No : {get(details, 'driver.contact_no', '-NA-')}</div>
													<div className="mid-text">{details.description}</div>
												</div>
											</div>
										</div>
									</div>
									
									{isSuperuser &&
										<div className="page-action-section">
											<Link to={{ 
												pathname: Urls.getDriverResetPass(), 
												state: { pk: details.pk, name: details.name } 
											}} className="action-block orange-bg-color mr-bottom z-depth-1">
												<div className="action-text">Reset Password</div>
											</Link>
											<Link to={{
												pathname: Urls.getDriverEditPage(details.pk),
												state: { ...details }
											}}
												className="action-block yellow-bg-color z-depth-1">
												<div className="action-text">
													<i className="fa fa-pencil" aria-hidden="true"></i> Edit
												</div>
											</Link>
										</div>
									}
								</div>
								<DriverDetailsSection trips={data.upcoming_driver_trips} />
							</div>
					}
				</div>
			</div>

		</div>
	)
}



class DriverDetailsSection extends Component {

	/**
	 * 
	 * driver details as props
	 * fetch trip, expense api logic handle here
	 * active tab logic
	 * 
	 * Parent :
	 * 		DriverDetailsPage
	 * 
	 * Renders :
	 * 		DriverExpenseList
	 * 		DriverTripList
	 * 
	 */

	state = {
		activeTab: 1,
	}

	DEFAULT_LIST_COUNT = 5
	_mounted = false

	componentDidMount = () => {
		this._mounted = true
	}

	componentWillUnmount = () => this._mounted = false

	handleTabSelect = (tab) => {
		if (this.state.activeTab === tab) return

		this.setState({ activeTab: tab })
	}


	render = () => {
		const { activeTab } = this.state
		const { trips } = this.props

		return (
			<div className="details-section">

				<div className="details-cards-wrapper">
					<div className={`details-card ${activeTab === 1 ? 'active' : ''}`}
						onClick={this.handleTabSelect.bind(this, 1)}>
						<div className="normal-text">Upcoming Trips</div>
						<div className="value">{trips.length}</div>
						<div className="icon-wrapper">
							<i className="fa fa-calendar" aria-hidden="true"></i>
						</div>
					</div>
				</div>

				<div className='list-section'>
					{this.renderActiveTab()}
				</div>

			</div>
		)
	}

	renderActiveTab = () => {
		const { activeTab } = this.state
		const { trips } = this.props

		switch (activeTab) {

			default: {
				return <VehicleTripList trips={trips} />
			}

		}
	}
}