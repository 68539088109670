import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router';

import { useQuery } from '@apollo/react-hooks';

import { get } from 'lodash'

import ExpenseEdit from '../components/ExpenseEdit';
import ExpenseAdd from '../components/ExpenseAdd'
import Loader from '../../others/components/Loader'
import { BackIcon } from '../../others/SvgIcons'

import { GET_EXPENSE_CHOICES } from '../data/expense.graphql'

import Urls from '../../UrlConfig'


export default (props) => {

  /**
   * expense page add to handle gql,
   * 
   * Parent:
   * 		DriverRoute
   * 		VehicleRoute
   *    App
   * 
   * Renders:
   *    ExpenseAdd
   *    ExpenseEdit
   */

  const expenseId = get(props, 'match.params.expense_id')
  const isSuperuser = useSelector(store => store.auth.is_superuser)

  const { loading, data, error } = useQuery(GET_EXPENSE_CHOICES, { fetchPolicy: "cache-and-network" })

  if (!isSuperuser) {
    return <Redirect to={Urls.getUnauthorized()} />
  }

  return (
    <div className='page-container'>
      <div className='page-title'>
        <BackIcon onClick={props.history.goBack} />
        <div className="heading">{expenseId ? "Edit Expense" : "Add Expense"}</div>
      </div>

      <div className='page-content flex-wrapper'>
        <div className='flex-md secondary-bg scrollable relative'>

          {loading ?
            <div className="loading-overlay">
              <Loader />
            </div>
            :
            error ?
              <div className="loading-overlay">
                <h2>Failed to fetch data</h2>
              </div>
              :
              expenseId ?
              <ExpenseEdit expenseId={expenseId}
                data={data} history={props.history}
              />
              :
              <ExpenseAdd data={data}
                history={props.history}
                prime_type={get(props, 'match.params.entity_type', '')} // can be vehicle, driver
                prime_id={get(props, 'match.params.entity_id', '')} // can be vehicle / driver PK
              />
          }

        </div>
      </div>
    </div>
  )
}
