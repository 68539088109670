import gql from 'graphql-tag';

export const GET_EXPENSE_CHOICES = gql`
query {
  vehicles {
    edges {
      cursor
      node {
        image model_no pk
        registration_no
        manufacturing_year
        driver {
          name pk
        }
      }
    }
  }
  drivers {
    edges {
      cursor
      node {
        image name pk
        monthly_salary
        licence_no
        description

        vehicle {
          pk registration_no
        }
      }
    }
  }
  expenses_type_list {
    edges {
      node {
        name pk
      }
    }
  }
}
`

export const GET_EXPENSE_LIST = gql`
query expenses(
  $after: String, $before: String, $first: Int, $last: Int,
  $cost: Float
) {
  expenses(after: $after, before : $before,
    first: $first, last : $last, cost: $cost
  ) {
    pageInfo { hasNextPage hasPreviousPage startCursor endCursor }
    edges {
      cursor
      node {
        pk cost timestamp
        expense_type { name }
        vehicle { pk name }
      }
    }
  }
}
`

export const GET_EXPENSE_DETAILS = gql`
query expense_details($expenseId: Int) {
  expense_details(pk: $expenseId) {
    pk prime_type
    vehicle {
      pk name
    }
    driver {
      pk name
    }
    cost
    expense_type { pk name }
    description
    refuel_quantity
    km_reading
    timestamp
  }
}
`