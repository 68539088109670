import React from 'react'

export default ({ loading = false, loadingClass="btn disable-btn", label = "Click", onClick, ...restProps }) => {

	if(loading) {
		return (
			<div className={loadingClass}>Loading...</div>
		)
	}

	return (
		<div onClick={onClick}
			{...restProps} >
				<button type="submit" className="prime-submit-btn"></button>
			{label}
		</div>
	)
}
