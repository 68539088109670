import Axios from "axios"
import store from "../store"

import { setHours, setMinutes, format } from 'date-fns'

import {get} from 'lodash'

const CancelToken = Axios.CancelToken;

export function getJsonData(url, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token
	const source = CancelToken.source();

	Axios({
		url,
		method: 'get',
		headers: { "Authorization": `Bearer ${token}` },
		cancelToken: source.token
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err)
			if (Axios.isCancel(err)) {
				// console.log('Request canceled', err.message);
				return
			}
			const error_code = get(err, 'response.status', 500)
			let error_data = {"__all__" : ["Server error. Contact admin"]}

			if(error_code === 400 || error_code === 403 || 
				error_code === 404 || error_code === 401) {
				error_data = err.response.data
				if (errback) errback({ error_data, error_code });

			} else {
				if (errback) errback({ error_data, error_code });
			}
		})

	return source
}

export function postJsonData(url, data, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		url,
		method: 'post',
		headers: { "Authorization": `Bearer ${token}` },
		data
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err.response)

			const error_code = get(err, 'response.status', 500)
			let error_data = {"__all__" : ["Server error. Contact admin"]}

			if(error_code === 400 || error_code === 403 || 
				error_code === 404 || error_code === 401) {
				error_data = err.response.data
				if (errback) errback({ error_data, error_code });

			} else {
				if (errback) errback({ error_data, error_code });
			}
		})
}

export function postFormData(url, data, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		method: 'post',
		url,
		headers: {
			'content-type': 'multipart/form-data',
			"Authorization": `Bearer ${token}`
		},
		data
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err.response)

			const error_code = get(err, 'response.status', 500)
			let error_data = {"__all__" : ["Server error. Contact admin"]}

			if(error_code === 400 || error_code === 403 || 
				error_code === 404 || error_code === 401) {
				error_data = err.response.data
				if (errback) errback({ error_data, error_code });

			} else {
				if (errback) errback({ error_data, error_code });
			}

		})
}


export function fetchBlobData(url, method, data={}, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		url,
		method,
		data,
		headers: { "authorization": token },
		responseType: 'blob',
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err)

			const error_code = get(err, 'response.status', 500)
			const error_data = get(err, 'response.data', {"__all__" : ["Server error. Contact admin"]})

			if (errback) errback({ error_data, error_code });

		})
}


export const toggleItemSelect = (item, item_list) => {
    let new_items = new Set(item_list)

    if(new_items.has(item)) {
        new_items.delete(item)
    } else {
        new_items.add(item)
    }

    return new_items
}

/**
 * 
 * @param {*} date - String date 
 * 
 * retutn readable data Ex - 3rd Aug 2020
 */
export const formatDate = (date) => {
	return format(new Date(date), 'do MMM yyyy')
}

/**
 * 
 * @param {*} date - String date 
 * 
 * retutn readable data Ex - 3rd Aug 2020
 */
export const formatDateTime = (datetime, formatter = 'do MMM yyyy, h:mm a') => {
	return format(new Date(datetime), formatter)
}

/**
 * 
 * @param {*} date - Date object
 * 
 * retutn formated django accepted date in String
 */
export const formatSubmitDate = (date) => {
	return format(date, "yyyy-MM-dd HH:mm:ssxxx")
}

/**
 * set hour and minute in given date
 * format date in django date time format
 * 
 * @param {*} date : selected date obj
 * @param {*} time : { hour24, minute }
 * @returns date in string format "yyyy-MM-dd HH:mm:ssxxx"
 */
export const getDateFromTime = (date, time) => {
    let tempDate = setHours(date, time.hour24)
    tempDate = setMinutes(tempDate, time.minute)
    return formatSubmitDate(tempDate)
}


const checkNumberreg = new RegExp('^[0-9]+$');
// check given param is number or not
export const isNumber = (str) => {
	return checkNumberreg.test(str)
}