import React from 'react'
import PropTypes from 'prop-types'

import { createScale } from "../data/chart.utils"

import Axes from "./components/Axes"
import InteractiveGraphContainer from "./components/InteractiveGraphContainer"

export default class BarChart extends React.Component {

	/**
     * Parent:
     *      PastDataChart
	 * Renders:
	 * 		Axes
	 * 		InteractiveGraphContainer
     */

	static defaultProps = {
		x_label: 'ts',
		x_scale: 'band',
		y_label: 'value',
		y_scale: 'linear',
		drawX: true,
	}

	calcScales = (data) => {
		const { width, height, x_label, x_scale, y_label, y_scale } = this.props

		// const xScale = scaleBand().range([0, width])
		//     .domain(data.map(d => format(d[x_label], tracer_time_format) ))
		//     .padding(0.1)
		const xScale = createScale({
			data,
			label: x_label,
			range: [0, width],
			scale_type: x_scale
		})

		const yScale = createScale({
			data,
			label: y_label,
			range: [height, 0],
			scale_type: y_scale
		})

		return { xScale, yScale }
	}

	render = () => {
		const { margin, width, height, // dimensions
			data, x_label, y_label, drawX, // data related props
			zoom } = this.props,    // UI/UX props
			// calc scales and paths according to zoom or normal data
			{ xScale, yScale } = this.calcScales(data),
			bandwidth = xScale.bandwidth();

		return (
			<div className='bar-chart' >
				<InteractiveGraphContainer margin={margin}
					width={width} height={height}
					data={data} x_label={x_label} y_label={y_label}
					xScale={xScale} yScale={yScale}
					handleZoom={this.props.onZoomIn}
					x_type='band'
				>
					<Axes width={width} height={height}
						xScale={xScale} yScale={yScale} drawX={drawX} />

					<g className='bar-group'>
						{data.map(d => {
							const y_val = yScale(d[y_label]),
								x_val = xScale(d[x_label])
							return (
								<rect key={d[x_label]}
									fill={'rgba(136, 176, 75, 0.5)'} stroke={'rgba(136, 176, 75)'}
									x={x_val} y={y_val}
									width={bandwidth} height={height - y_val} />
							)
						})}
					</g>

				</InteractiveGraphContainer>

				{/* <div class={`btn-cancel ${zoom ? 'active' : ''}`}
					onClick={this.props.onZoomOut} > zoom out </div>
				*/}
			</div>
		)
	}
}

BarChart.propTypes = {
	// dimensions
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	// margin : { top, bottom, left, right,} all are numbers
	margin: PropTypes.object.isRequired,

	// data related props
	// data : list of data [ {ts, value}, ...]
	data: PropTypes.array.isRequired,
	// default : 'ts'
	x_label: PropTypes.string.isRequired,
	// default: 'value'
	y_label: PropTypes.string.isRequired,

	// UI related props
	// draw x axes
	drawX: PropTypes.bool.isRequired,
	// default : 'time'
	x_scale: PropTypes.string.isRequired,
	// default: 'linear'
	y_scale: PropTypes.string.isRequired,
	// dispaly chart zoom out btn and show data related to zoom
	zoom: PropTypes.bool.isRequired,
	// handle zoom in
	onZoomIn: PropTypes.func.isRequired,
	// handle zoom out
	onZoomOut: PropTypes.func.isRequired,
}