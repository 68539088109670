import {groupBy} from 'lodash'

/**
 * Convert data from db into data to be represented in AG grid
 * Output shape : [ {date, [e_type_1] : amount, [e_type_2] : amount, ...} , ... ]
 * 
 * @param {Array} expenseList shape : [ {day, e_type, amount, refuel_quantity}, ...]
 */
export const transformExpenseData = (expenseList) => {
	// new object : { day : [ all objects of same day ] , ... }
	let dayWiseExp = groupBy(expenseList, (data) => {return (data.day)})
	let result = []
	for (const key in dayWiseExp) {
		if (dayWiseExp.hasOwnProperty(key)) {
			const currExp = dayWiseExp[key];
			// add date
			let newDatum = {date : new Date(key)}
			for (let index = 0; index < currExp.length; index++) {
				// convert each e_type : label -> [label] : amount
				const singleDatum = currExp[index];
				newDatum[singleDatum.e_type] = singleDatum.amount
				if(singleDatum.e_type==='DIESEL') {
					newDatum['refuel_q'] = singleDatum.refuel_q
				}
			}
			result.push(newDatum)
		}
	}
	return result
}

export const getStatusName = (status, isPostponed) => {
  if (isPostponed) {
    return "Postponed"
  }
  return {
    U:'Upcoming',
		O:'Ongoing',
		C:'Completed',
  }[status]
}