import React, { Component } from 'react'
import { connect } from 'react-redux'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { DateTimePickerInput } from 'react-datetime-range-super-picker'
import Select from 'react-select'

import { get, pick } from 'lodash'

import Loader from '../../others/components/Loader'
import ErrorBlock from '../../others/components/ErrorBlock'
import Checkbox from '../../others/components/Checkbox';

import { BackIcon } from '../../others/SvgIcons'
import { GET_TRIP_DETAILS } from '../data/trip.graphql'
import { formatSubmitDate, getJsonData, postJsonData } from '../../others/utils';
import Urls from '../../UrlConfig'
import { addNotification } from '../../others/data/appState.actions';
import { getStatusName } from '../../reports/data/reports.utils';
import { isNumber } from '../../others/utils'


const GET_DRIVERS = gql`
query drivers($after: String, $before: String, $first: Int, $last: Int) {
	drivers(after: $after, before : $before, first: $first, last : $last) {
		edges {
			cursor
			node { name pk }
		} 
	}
}
`

const GET_VEHICLE_LIST = gql`
query vehicles($after: String, $before: String, $first: Int, $last: Int) {
	vehicles(after: $after, before : $before, first: $first, last : $last) {
		edges {
			cursor
			node {
				name model_no pk registration_no
				driver {
					name pk
				}
			}
		} 
	}
}
`

export default ({ match, history }) => {

  /**
   * fetch trip details, drivers and vehicles list from server
   * show loading, error 
   * 
   * Parent :
   * 		TripRoute
   * 
   * Renders :
   * 		TripForm
   * 
   * Scss:
   *    trip/_trip_list.scss
   */

  const { tripId } = match.params

  const {
    loading, data, error, refetch
  } = useQuery(GET_TRIP_DETAILS, { variables: { pk: Number(tripId) }, fetchPolicy: "cache-and-network" })
  const {
    loading: loading_vehicle,
    data: vehicle_data,
  } = useQuery(GET_VEHICLE_LIST, { fetchPolicy: "cache-and-network" })

  const {
    loading: loading_driver,
    data: driver_data
  } = useQuery(GET_DRIVERS, { fetchPolicy: "cache-and-network" })

  const vehicle_list = get(vehicle_data, 'vehicles.edges', [])
  const driver_list = get(driver_data, 'drivers.edges', [])
  const details = get(data, 'trip_details', {})

  const loadingData = loading || loading_vehicle || loading_driver

  return (
    <div className='page-container small-page-title'>
      <div className='page-title'>
        <BackIcon onClick={history.goBack} />
        <div className="heading">Trip List</div>
      </div>
      <div className='page-content flex-wrapper'>
        <div className='flex-md secondary-bg scrollable relative'>

          {loadingData ?
            <div className="loading-overlay">
              <Loader />
            </div>
            :
            error ?
              <div className="loading-overlay">
                <h2>Failed to fetch data</h2>
              </div>
              :
              <TripFormConnect details={details}
                vehicles={vehicle_list}
                drivers={driver_list}
                onCancle={history.goBack}
                refetch={refetch}
              />
          }
        </div>
      </div>
    </div>
  )
}


class TripForm extends Component {
  /**
   * set parent data into state
   * submit form data to server
   */
  constructor(props) {
    super(props)

    this.notEditAllow = !props.isSuperuser

    let initData = pick(props.details, ['pk', 'from_destination', 'to_destination',
      'start_km_reading', 'material_description', 'end_km_reading', 'remarks',
      'total_km', 'challan_number', 'status', 'start_time', 'end_time', 'is_postponed',
      'actual_start_time', 'actual_end_time'])

    initData.driver = get(props.details, 'driver.pk', null)
    initData.vehicle = get(props.details, 'vehicle.pk', null)

    const start_time = new Date(initData.start_time)
    const end_time = new Date(initData.end_time)
    const actual_start_time = initData.actual_start_time ? new Date(initData.actual_start_time) : null
    const actual_end_time = initData.actual_end_time ? new Date(initData.actual_end_time) : null

    this.state = {
      ...initData,
      start_time, end_time,
      actual_start_time,
      actual_end_time,
      errors: {},
      loading: false,
      deleting: false
    }
  }

  onFormSubmit = (event) => {
    if (this.notEditAllow) return
    event.preventDefault()

    const { vehicle, driver, pk, start_time, end_time,
      actual_start_time, actual_end_time,
      from_destination, to_destination,
      start_km_reading, material_description,
      end_km_reading, remarks, challan_number,
      is_postponed
    } = this.state

    let data = {
      id: pk, vehicle, driver,
      from_destination, to_destination,
      start_km_reading, material_description,
      end_km_reading, remarks, challan_number,
      start_time: formatSubmitDate(start_time),
      end_time: formatSubmitDate(end_time),
      is_postponed
    }
    if (actual_start_time) {
      data.actual_start_time = formatSubmitDate(actual_start_time)
    }
    if (actual_end_time) {
      data.actual_end_time = formatSubmitDate(actual_end_time)
    }

    this.setState({ loading: true, errors: {} })
    postJsonData(Urls.apiTripAdd(),
      data,
      (res) => {
        this.props.onCancle()
        this.props.dispatch(addNotification(
          {
            type: 'warning',
            icon: 'fa-pencil',
            title: 'Trip Update',
            text: 'Trip Updated Successfully.',
          }
        ))
      },
      (err) => {
        this.props.dispatch(addNotification({
          type: 'error',
          icon: 'fa-ban',
          title: 'Error',
          text: 'Input Error',
        }))
        this.setState({ loading: false, errors: err.error_data })
      }
    )
  }

  handleStartTrip = () => {
    if (this.notEditAllow) return
    const { pk, start_km_reading,
      material_description, challan_number
    } = this.state

    let err = false
    let errPayload = { start_km_reading: [] }

    if (!isNumber(start_km_reading)) {
      errPayload.start_km_reading.push("Km reading must be a number")
      err = true
    }

    if (err) {
      this.setState({ errors: errPayload })
      this.props.dispatch(addNotification({
        type: 'error',
        icon: 'fa-ban',
        title: 'Error',
        text: 'Input Error',
      }))
      return
    }

    const data = {
      km_reading: Number(start_km_reading),
      description: material_description,
      challan_number: challan_number
    }

    postJsonData(Urls.apiTripStatusChange(pk),
      data,
      (res) => {
        this.props.dispatch(addNotification(
          {
            type: 'warning',
            icon: 'fa-pencil',
            title: 'Trip start',
            text: 'Trip started Successfully.',
          }
        ))
        this.props.refetch()
      },
      (err) => {
        this.props.dispatch(addNotification(
          {
            type: 'error',
            icon: 'fa-ban',
            title: 'Error',
            text: 'Input Error',
          }
        ))
        this.setState({ errors: err.error_data })
      }
    )
  }

  handlePostponeTrip = () => {
    this.setState({ is_postponed: !this.state.is_postponed })
  }

  handleCompleteTrip = () => {
    if (this.notEditAllow) return
    const { pk, end_km_reading, start_km_reading, remarks } = this.state

    let err = false
    let errPayload = { end_km_reading: [] }

    if (isNumber(end_km_reading)) {
      if (Number(end_km_reading) <= Number(start_km_reading)) {
        // end km can not be lte start km reading
        errPayload.end_km_reading.push(
          "End of the trip Km reading can not be less than or equal to start of the trip reading"
        )
        err = true
      }
    }
    else {
      errPayload.end_km_reading.push("Km reading must be a number")
      err = true
    }

    if (err) {
      this.setState({ errors: errPayload })
      this.props.dispatch(addNotification({
        type: 'error',
        icon: 'fa-ban',
        title: 'Error',
        text: 'Input Error',
      }))
      return
    }

    const data = {
      km_reading: Number(end_km_reading),
      description: remarks,
    }

    postJsonData(Urls.apiTripStatusChange(pk),
      data,
      (res) => {
        this.props.dispatch(addNotification(
          {
            type: 'warning',
            icon: 'fa-pencil',
            title: 'Trip completed',
            text: 'Trip completed Successfully.',
          }
        ))
        this.props.refetch()
      },
      (err) => {
        this.props.dispatch(addNotification(
          {
            type: 'error',
            icon: 'fa-ban',
            title: 'Error',
            text: 'Input Error',
          }
        ))
        this.setState({ errors: err.error_data })
      }
    )
  }

  delete_trip = (e) => {
    if (this.notEditAllow) return
    if (e) e.preventDefault()

    this.setState({ deleting: true })
    getJsonData(Urls.apiTripDelete(this.state.pk),
      (res) => {
        this.props.onCancle()
        this.props.dispatch(addNotification(
          {
            type: 'warning',
            icon: 'fa-pencil',
            title: 'Trip Delete',
            text: 'Trip Deleted Successfully.',
          }
        ))
      },
      (err) => {
        this.setState({ deleting: false, errors: err.error_data })
      }
    )

  }

  // time handlers
  handleStartDateUpdate = ({ date }) => {
    if (this.notEditAllow) return
    this.setState({ start_time: date.date })
  }

  handleActualStartDateUpdate = ({ date }) => {
    if (this.notEditAllow) return
    this.setState({ actual_start_time: date.date })
  }

  handleEndDateUpdate = ({ date }) => {
    if (this.notEditAllow) return
    this.setState({ end_time: date.date })
  }

  handleActualEndDateUpdate = ({ date }) => {
    if (this.notEditAllow) return
    this.setState({ actual_end_time: date.date })
  }

  // input handler
  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // driver handler
  onDriverSelect = (edge) => {
    if (this.notEditAllow) return
    this.setState({ driver: edge.node.pk })
  }

  // vehicle handler
  onVehicleSelect = (edge) => {
    if (this.notEditAllow) return
    this.setState({
      vehicle: edge.node.pk,
      driver: get(edge, 'node.driver.pk', null)
    })
  }

  render = () => {
    const { vehicles, drivers, onCancle } = this.props
    const { errors, loading, from_destination,
      to_destination, start_time, end_time,
      material_description, remarks,
      start_km_reading, end_km_reading,
      actual_start_time, actual_end_time,
      driver, vehicle, total_km, challan_number,
      status, is_postponed } = this.state

    return (
      <form id='update-trip' className='form-section z-depth-2'>
        <div className="form-section-title">Trip Details
          {this.renderStatus(status, is_postponed)}
        </div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>From Destination</div>
            <ErrorBlock field="from_destination"
              className="error"
              error={errors} />
          </div>
          <input type="text"
            readOnly={this.notEditAllow}
            disabled={this.notEditAllow}
            className="textbox"
            placeholder='Enter From Destionation'
            name="from_destination"
            value={from_destination}
            onChange={this.onTextChange}
          />
        </div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>To Destination</div>
            <ErrorBlock field="to_destination"
              className="error"
              error={errors} />
          </div>
          <input type="text"
            readOnly={this.notEditAllow}
            disabled={this.notEditAllow}
            className="textbox"
            placeholder='Enter To Destionation'
            name="to_destination"
            value={to_destination}
            onChange={this.onTextChange}
          />
        </div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Vehicle</div>
            <ErrorBlock field="vehicle"
              className="error"
              error={errors} />
          </div>
          <Select className="select-dropdown"
            placeholder='Select Vehicle'
            value={vehicles.filter(edge => edge.node.pk === vehicle)}
            options={vehicles}
            onChange={this.onVehicleSelect}
            getOptionLabel={props => props.node.name}
            getOptionValue={props => props.node.pk}
            isDisabled={this.notEditAllow}
          />
        </div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Driver</div>
            <ErrorBlock field="driver"
              className="error"
              error={errors} />
          </div>
          <Select className="select-dropdown"
            placeholder='Select Driver'
            value={drivers.filter(edge => edge.node.pk === driver)}
            options={drivers}
            onChange={this.onDriverSelect}
            getOptionLabel={props => props.node.name}
            getOptionValue={props => props.node.pk}
            isDisabled={this.notEditAllow}
          />
        </div>

        <div className="form-section-title">Trip Start Details</div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Start Time</div>
          </div>
          <DateTimePickerInput className="super-picker-wrapper"
            popupClassName="super-picker-popup"
            date={start_time}
            onDateTimeUpdate={this.handleStartDateUpdate}
            isDisabled={this.notEditAllow}
          />
        </div>
        {actual_start_time &&
          <div className='form-field'>
            <div className='form-label'>
              <div className='label'>Actual Start Time</div>
            </div>
            <DateTimePickerInput className="super-picker-wrapper"
              popupClassName="super-picker-popup"
              date={actual_start_time}
              onDateTimeUpdate={this.handleActualStartDateUpdate}
              isDisabled={this.notEditAllow}
            />
          </div>
        }
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Challan Number</div>
            <ErrorBlock field="challan_number"
              className="error"
              error={errors} />
          </div>
          <input
            readOnly={this.notEditAllow}
            disabled={this.notEditAllow}
            className="textbox"
            placeholder='Enter Challan Number'
            name="challan_number"
            value={challan_number || ''}
            onChange={this.onTextChange}
          />
        </div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Start Km Reading</div>
            <ErrorBlock field="start_km_reading"
              className="error"
              error={errors} />
          </div>
          <input type="number"
            readOnly={this.notEditAllow}
            disabled={this.notEditAllow}
            className="textbox"
            placeholder='Enter Start Km Reading'
            name="start_km_reading"
            value={start_km_reading || ''}
            onChange={this.onTextChange}
          />
        </div>
        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Material Description</div>
            <ErrorBlock field="material_description"
              className="error"
              error={errors} />
          </div>
          <textarea type="text"
            className="textbox"
            placeholder='Enter Material Description'
            name="material_description"
            value={material_description}
            onChange={this.onTextChange}
          />
        </div>

        {status === 'U' &&
          <div className='form-field'>
            <div className="status-options">
              <TripStatusBtn className="option text-orange"
                label="START"
                icon={<i className="fa fa-play" aria-hidden="true" />}
                isLoading={false}
                onClick={this.handleStartTrip} />
            </div>
          </div>
        }

        {status !== 'U' &&
          <>
            <div className="form-section-title">Trip End Details</div>
            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>End Time</div>
              </div>
              <DateTimePickerInput className="super-picker-wrapper"
                popupClassName="super-picker-popup"
                date={end_time}
                onDateTimeUpdate={this.handleEndDateUpdate}
                isDisabled={this.notEditAllow}
              />
            </div>
            {actual_end_time &&
              <div className='form-field'>
                <div className='form-label'>
                  <div className='label'>Actual End Time</div>
                </div>
                <DateTimePickerInput className="super-picker-wrapper"
                  popupClassName="super-picker-popup"
                  date={actual_end_time}
                  onDateTimeUpdate={this.handleActualEndDateUpdate}
                  isDisabled={this.notEditAllow}
                />
              </div>
            }
            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>End Km Reading</div>
                <ErrorBlock field="end_km_reading"
                  className="error"
                  error={errors} />
              </div>
              <input type="number"
                readOnly={this.notEditAllow}
                disabled={this.notEditAllow}
                className="textbox"
                placeholder='Enter End Km Reading'
                name="end_km_reading"
                value={end_km_reading || ''}
                onChange={this.onTextChange}
              />
            </div>
            <div className='form-field'>
              <div className='form-label'>
                <div className='label'>Remarks</div>
                <ErrorBlock field="remarks"
                  className="error"
                  error={errors} />
              </div>
              <textarea type="text"
                className="textbox"
                placeholder='Enter Remarks'
                name="remarks"
                value={remarks || ''}
                onChange={this.onTextChange}
              />
            </div>
            <div className='form-field'>
              <ErrorBlock
                field="trip_postpone"
                className="error-block"
                error={errors}
              />
              <div className="status-options">
                {status !== 'C' &&
                  <TripStatusBtn className="option text-green"
                    label="COMPLETE"
                    icon={<i className="fa fa-check" aria-hidden="true" />}
                    isLoading={false}
                    onClick={this.handleCompleteTrip}
                  />
                }
              </div>
            </div>
          </>
        }

        <div className='form-field'>
          <div className='form-label'>
            <div className='label'>Total Km</div>
          </div>
          <div className="textbox">{total_km || '-NA-'}</div>
        </div>

        <div className='form-field'>
          <Checkbox
            checked={is_postponed}
            label="Postponed"
            onClick={this.handlePostponeTrip}
          />
        </div>

        <div className='form-actions multi-action'>
          <div className="left">
            {this.renderDeleteBtn()}
          </div>
          <div className="right">
            <div className='btn secondary-dark-btn' onClick={onCancle}>Cancel</div>
            {loading ?
              <div className='btn disable-btn'>Loading...</div>
              :
              <div className='btn yellow-btn' onClick={this.onFormSubmit}>
                Update Trip
              </div>
            }
          </div>
          <div className="clearfix" />
        </div>
      </form>
    )
  }

  renderStatus = (status, is_postponed) => {
    let className
    if (is_postponed) {
      className = "sub-title text-red"
    }
    else if (status === "U") {
      className = "sub-title text-yellow"
    }
    else if (status === "O") {
      className = "sub-title text-green"
    }
    else if (status === "C") {
      className = "sub-title text-secondary-dark"
    }
    return <span className={className}> ( {getStatusName(status, is_postponed)} )</span>
  }

  renderDeleteBtn = () => {
    const { deleting } = this.state
    if (!this.notEditAllow) {
      if (deleting) {
        return <div className='btn disable-btn'>Loading...</div>
      }
      else {
        return (
          <div className='btn red-btn' onClick={this.delete_trip}>
            Delete Trip
          </div>
        )
      }
    }
  }
}

const TripFormConnect = connect(store => ({
  isSuperuser: store.auth.is_superuser
}))(TripForm)


const TripStatusBtn = ({ className, label, icon, isLoading, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      {isLoading ? "Loading..." : <>{label} {icon}</>}
    </div>
  )
}
