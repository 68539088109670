import React, { useState } from 'react'


export default ({ currentCount, changeCount, // set page size params
    fetchNextData, fetchPrevData, // on button click handlers
    hasPrevPage, hasNextPage, // handle button enable disable
    pageNo, // show page no
}) => {

    const [count, setCount] = useState(currentCount)

    const updateCount = () => {
        changeCount(count)
    }

    const onCountChange = (e) => {
        const {value} = e.target
        if(isNaN(value)) {
            return
        } else {
            setCount(Number(e.target.value))
        }
    }
    
    return (
        <div className='pagination flex-wrapper'>
            <div className='pagination-section flex-sm flex-wrapper'>
                <div>Showing Up to </div>
                <input type="text"
                    className="textbox"
                    placeholder='No of rows'
                    name="count"
                    onChange={onCountChange}
                    value={count} />
                <div>rows</div>

                {currentCount !== count &&
                    <button className="yellow-btn set-btn" onClick={updateCount}>Set</button>
                }
                
            </div>
            <div className='pagination-section flex-sm flex-wrapper'>
                <div>Page : {pageNo}</div>
                <div className='page-navigation clickable'>
                    <div className={`previous ${hasPrevPage ? "" : "disabled"}`} 
                        onClick={fetchPrevData}>
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </div>

                    <div className={`next ${hasNextPage ? "" : "disabled"}`} onClick={fetchNextData}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}