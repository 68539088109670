import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import LoadingButton from "../components/LoadingButton"
import LOGO from "../components/Logo"

import { useActions } from "../redux_utils"
import Urls from '../../UrlConfig'

export default () => {

	/**
	 * Parent :
	 *      AuthPage
	 * 
	 */
	const history = useHistory()

	const logoutUser = useActions(() => ({ type: 'USER_LOGOUT' }))
	// logout user and redirect to login page
	const logoutRedirect = () => {
		logoutUser()
		history.push(Urls.getAuthPage('login'))
	}
	
	return (
		<div className="auth-wrapper">
			<div className="auth-fixed-bg">
				{/* <img src={BLUR_BG} /> */}
			</div>    
			<div className="auth-body-wrapper">
				<div className="auth-card">
					
					<LOGO />
					{/* <div className="placeholder-img">
						<img src={CHECK_ICON} className="responsive-img" alt=""/>
					</div> */}
					<div className="form-content-margin">
						<div className="title accent-text bold">Unauthorized</div>
						<div className="sub-title accent-text">You do not have required permissions. Please visit Mobile Application if you are driver.</div>

						<div className="form-store-icons">
							<a className="store-link" href="https://play.google.com/store/apps/details?id=com.chavda.logistics"
								target="_blank" without={"true"} rel="noopener noreferrer">
								<img className="store-google" src={Urls.getStaticImg('img/google300.png')} alt="Google" />
							</a>
							<a className="store-link" href="https://apps.apple.com/app/id1564546234"
								target="_blank" without={"true"} rel="noopener noreferrer">
								<img className="store-apple" src={Urls.getStaticImg('img/apple512.png')} alt="Apple" />
							</a>
						</div>

						<div className="btn-wrapper" style={{ paddingTop : '2em'}}>
							<Link to={Urls.getDashboardPage()}>
								<div className="btn primary-btn" >Dashboard</div>
							</Link>
							<div className="btn primary-btn" onClick={history.goBack}>Go Back</div>
							<LoadingButton 
								className="btn primary-btn"
								onClick={logoutRedirect}
								label="Logout"
								loading={false}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}