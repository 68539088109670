import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom'

import Navbar from './components/Navbar';
import WaterMark from './components/WaterMark';
import Notification from './components/Notification';

import store from '../store'
import Urls from '../UrlConfig';

const Layout = ({ component: Component, ...rest }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [rest.path]);

    return (
        <Route {...rest} render={matchProps => (
            <>
                <Navbar history={matchProps.history} />
                <Notification />
                <div id='main-container' className="relative">
                    <Component {...matchProps} />
                    <WaterMark />
                </div>
            </>
        )} />
    )
}
export default Layout


export const PrivateRoute = ({ component: Component, ...rest }) => {

    if (store.getState().auth.logged) {
        // only client : is_staff, and admin : is_superuser have access to pages
        if (store.getState().auth.is_staff) {
            return <Layout {...rest} component={Component} />
        }
        else {
            return <Redirect to={Urls.getUnauthorized()} />
        }
    }
    else {
        return (
            <Redirect to={{
                pathname: Urls.getAuthPage('login'),
                state: { from: rest.location }
            }} />
        )
    }

}