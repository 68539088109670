import React from 'react'
import { connect } from 'react-redux'

import { filter, get } from 'lodash'

import { updateScheduleData } from '../data/schedulePage.actions'


export default connect(store => ({
    selectedVehicle: store.schedulePage.selectedVehicle,
    scheduleList: store.schedulePage.scheduleList,
}))(props => {

    /**
     * show vehicle list
     * 
     * Parent:
     *      TripAddPage
     */

    const { vehicleList, scheduleList,
        selectedVehicle, dispatch } = props

    return (
        <div className="trip-vehicle-container">
            <div className={`trip-vehicle-item ${selectedVehicle === -1 ? 'active' : ''}`}
                onClick={() => dispatch(updateScheduleData({
                    selectedVehicle: -1,
                    selectedVehicleDriver: -1
                }))}>
                <div className="text">
                    <div>All Vehicle</div>
                </div>
                <div className="badge-col">
                    <div className="badge">{scheduleList.length}</div>
                </div>
            </div>
            {vehicleList.map(vehicle => {
                const vehicleId = vehicle.node.pk
                const tripsCount = filter(scheduleList, ['vehicle', vehicleId]).length

                const activeClass = vehicleId === selectedVehicle ? 'active' : ''
                return (
                    <div key={vehicleId}
                        className={`trip-vehicle-item ${activeClass}`}
                        onClick={() => dispatch(updateScheduleData({
                            selectedVehicle: vehicleId,
                            selectedVehicleDriver: get(vehicle, 'node.driver.pk', null)
                        }))}>
                        <div className="text">
                            <div>{get(vehicle, 'node.name', '')}</div>
                            <div className="sub-text">{get(vehicle, 'node.registration_no', '')}</div>
                        </div>
                        <div className="badge-col">
                            <div className="badge">{tripsCount}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
})