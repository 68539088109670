import React from 'react'
import { NavLink } from "react-router-dom"
import { useSelector } from 'react-redux';

import Urls from "../../UrlConfig"

import {
	Dashboard,
	Vehicle,
	Driver,
	Trip,
	ProfileIcon,
	Report,
	ClientUsersIcon
} from '../SvgIcons'


export default () => {

	const isSuperuser = useSelector(store => store.auth.is_superuser)

	return (
		<div id="sidebar">
			<div className='sidebar-menu'>
				<NavLink activeClassName="active" exact to={Urls.getDashboardPage()} className="sidebar-block" >
					<div className='sb-block-icon'><Dashboard /></div>
					<div className='sb-block-title'>Dashboard</div>
				</NavLink>
				<NavLink activeClassName="active" to={Urls.getVehiclePage()} className="sidebar-block" >
					<div className='sb-block-icon'><Vehicle /></div>
					<div className='sb-block-title'>Vehicle</div>
				</NavLink>
				<NavLink activeClassName="active" to={Urls.getDriverPage()} className="sidebar-block" >
					<div className='sb-block-icon'><Driver /></div>
					<div className='sb-block-title'>Driver</div>
				</NavLink>
				<NavLink activeClassName="active" to={Urls.getTripPage()} className="sidebar-block" >
					<div className='sb-block-icon'><Trip /></div>
					<div className='sb-block-title'>Trip</div>
				</NavLink>
				{isSuperuser &&
					<NavLink activeClassName="active" to={Urls.getClientPage()} className="sidebar-block" >
						<div className='sb-block-icon'><ClientUsersIcon /></div>
						<div className='sb-block-title'>Client</div>
					</NavLink>
				}
				<NavLink activeClassName="active" to={Urls.getReportPage()} className="sidebar-block" >
					<div className='sb-block-icon'><Report /></div>
					<div className='sb-block-title'>Reports</div>
				</NavLink>
				<NavLink activeClassName="active" to={Urls.getProfilePage()} className="sidebar-block" >
					<div className='sb-block-icon'><ProfileIcon /></div>
					<div className='sb-block-title'>Profile</div>
				</NavLink>
			</div>
		</div>
	)
}