import React, { Component } from 'react'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import gql from 'graphql-tag';
import { graphql } from 'react-apollo'
import { Redirect } from 'react-router';

import { get, has } from 'lodash'

import ErrorBlock from '../../others/components/ErrorBlock'

import { AddIcon, CloseIcon, BackIcon } from '../../others/SvgIcons'

import { postFormData } from '../../others/utils'
import Urls from '../../UrlConfig'


const GET_DRIVERS = gql`
query drivers($after: String, $before: String, $first: Int, $last: Int) {
	drivers(after: $after, before : $before, first: $first, last : $last) {
		pageInfo {hasNextPage hasPreviousPage startCursor endCursor}
		edges {
			cursor
			node {
				image name pk
				monthly_salary
				licence_no
			}
		} 
	}
}
`
export default (props) => {
	/**
	 * wrapper check user is admin or not
	 * if not admin redirect to 404
	 */
	const isSuperuser = useSelector(store => store.auth.is_superuser)

	if(!isSuperuser) {
		return <Redirect to={Urls.getUnauthorized()}/>
	}

	return <VehicleAddPageWrapper {...props}/>
}

class VehicleAddPage extends Component {

	constructor(props) {
		super(props)

		this.isEdit = has(props, 'match.params.vehicleId')

		let initState
		if (this.isEdit) {
			initState = {
				imageUrl: get(props, 'location.state.image', null),
				driverId: get(props, 'location.state.driver.pk', null),
				name: get(props, 'location.state.name', ''),
				model_no: get(props, 'location.state.model_no', ''),
				manufacturing_year: get(props, 'location.state.manufacturing_year', ''),
				registration_no: get(props, 'location.state.registration_no', ''),
				pk: get(props, 'location.state.pk', null)
			}
		}
		else {
			initState = {
				imageUrl: null,
				driverId: null,
				name: '',
				model_no: '',
				manufacturing_year: '',
				registration_no: '',
			}
		}

		this.state = {
			...initState,
			imageFile: null,
			loading: false,
			errors: {}
		}
	}

	onFormSubmit = (event) => {
		event.preventDefault()

		let form = document.getElementById('add-vehicle');
		let formData = new FormData(form);

		if(this.state.imageUrl) {
			if(this.state.imageFile){
				// 1. new vehicle create with image
				// 2. edit image of vehicle, old image exist
				formData.append('image', this.state.imageFile)
			}
		}
		else {
			// case: clear image
			formData.append('image', '')
		}

		// add pk in case of edit
		if(this.isEdit) {
			formData.append('pk', this.state.pk)
		}

		this.setState({ loading: true, errors: {} })
		postFormData(Urls.apiVehicleAdd(),
			formData,
			(res) => {
				this.props.history.push(Urls.getVehicleDetails(res.id))
			},
			(err) => {
				this.setState({ loading: false, errors: err.error_data })
			}
		)
	}

	loadFile = (event) => {
		const selected_image = event.target.files[0]
		if (selected_image) {
			this.setState({
				imageUrl: URL.createObjectURL(selected_image),
				imageFile: selected_image
			})
		}
	}

	clearImage = () => {
		this.setState({ imageUrl: null, imageFile: null })
	}

	selectDriver = (data) => {
		this.setState({ driverId: get(data, 'node.pk', null) })
	}

	// input handler
	onTextChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	render = () => {
		const { history, data } = this.props
		const { name, model_no, registration_no,
			manufacturing_year, driverId,
			imageUrl, loading, errors
		} = this.state

		const driverList = get(data, 'drivers.edges', [])

		return (
			<form id='add-vehicle' className='page-container'>
				<div className='page-title'>
					<BackIcon onClick={history.goBack} />
					<div className="heading">Add Vehicle</div>
				</div>

				<div className='page-content flex-wrapper'>
					<div className='flex-md secondary-bg scrollable relative'>
						<div className='form-section z-depth-2'>

							<div className='form-column'>
								<div className='form-column-field'>
									<div className="upload-block">
										<input type="file" onChange={this.loadFile} />
										<div className="upload-wrapper">
											<AddIcon />
											<div className='upload-text'>Upload Vehicle Image</div>
										</div>
										{imageUrl && (
											<>
												<img className="responsive-img" src={imageUrl} alt="" />
												<div className="remove-image" onClick={this.clearImage}>
													<CloseIcon />
												</div>
											</>
										)}
									</div>
									<ErrorBlock field="image"
										className="error"
										error={errors} />
								</div>
								<div className='form-column-field'>
									<div className='form-field'>

										<div className='form-label'>
											<div className='label'>Name</div>
											<ErrorBlock field="name"
												className="error"
												error={errors} />
										</div>
										<input type="text"
											className="textbox"
											placeholder='Enter Name'
											name="name"
											value={name}
											onChange={this.onTextChange}
										/>

									</div>

									<div className='form-field'>

										<div className='form-label'>
											<div className='label'>Model No</div>
											<ErrorBlock field="model_no"
												className="error"
												error={errors} />
										</div>
										<input type="text"
											className="textbox"
											placeholder='Enter Model No'
											name="model_no"
											value={model_no}
											onChange={this.onTextChange}
										/>

									</div>

								</div>
							</div>

							<div className='form-field'>
								<div className='form-label'>
									<div className='label'>Select Driver</div>
									<ErrorBlock field="driver"
										className="error"
										error={errors} />
								</div>
								<Select className="select-dropdown"
									name="driver"
									placeholder='Select Driver'
									value={driverList.filter(driver => driver.node.pk === driverId)}
									options={driverList}
									onChange={this.selectDriver}
									getOptionLabel={props => props.node.name}
									getOptionValue={props => props.node.pk}
									isDisabled={this.props.data.loading}
								/>
							</div>

							<div className='form-field'>

								<div className='form-label'>
									<div className='label'>Registration No</div>
									<ErrorBlock field="registration_no"
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Enter Registration No'
									name="registration_no"
									value={registration_no}
									onChange={this.onTextChange}
								/>

							</div>

							<div className='form-field'>

								<div className='form-label'>
									<div className='label'>Manufacturing Year</div>
									<ErrorBlock field="manufacturing_year"
										className="error"
										error={errors} />
								</div>
								<input type="text"
									className="textbox"
									placeholder='Manufacturing Year'
									name="manufacturing_year"
									value={manufacturing_year}
									onChange={this.onTextChange}
								/>

							</div>

							<div className='form-actions single-action'>
								{loading ?
									<div className='disable-btn'>Loading...</div>
									:
									<div className='yellow-btn' onClick={this.onFormSubmit}>
										{this.isEdit ? "Update Vehicle" : "Add Vehicle"}
									</div>
								}
							</div>

						</div>
					</div>
				</div>

			</form>
		)
	}
}

const VehicleAddPageWrapper = graphql(GET_DRIVERS, { fetchPolicy: "cache-and-network" })(VehicleAddPage)